/**
 * Generic tree view components
 * */
import React from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

import CustomPropTypes from './prop-types';

/**
 * Stateless version of the tree view -> you need to provide the open state
 * from outside.
 */
export default function TreeView (props) {
  const {tree, renderHeader, level = 0, ...rest} = props;

  const isOpen = (
    typeof props.expanded === 'function'
    ? props.expanded
    : id => props.expanded.indexOf(id) > -1
  );

  const extraProps = _omit(props, Object.keys(TreeView.propTypes));

  if (Array.isArray(tree)) {
    return (
      <React.Fragment>
        {renderHeader && renderHeader()}
        {props.tree.map(node =>
          <TreeView key={node.id} tree={node} level={level} {...rest}/>
        )}
      </React.Fragment>
    );
  } else if (tree.children && tree.children.length) {
    const expanded = isOpen(tree.id);
    return (
      <React.Fragment>
        {renderHeader && renderHeader()}
        {props.renderNode({...extraProps, level, expanded, id: tree.id, node: tree})}
        { expanded && <TreeView tree={tree.children} level={level + 1} {...rest}/> }
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {renderHeader && renderHeader()}
        {props.renderLeaf({...extraProps, level, id: tree.id, node: tree})}
      </React.Fragment>
    );
  }
}

const NodeShape = PropTypes.shape({
  id: CustomPropTypes.integer.isRequired,
  children: PropTypes.arrayOf(CustomPropTypes.lazy(() => NodeShape)),
});

TreeView.propTypes = {
  tree: PropTypes.oneOfType([
    NodeShape,
    PropTypes.arrayOf(NodeShape),
  ]).isRequired,
  expanded: PropTypes.oneOfType([
    PropTypes.arrayOf(CustomPropTypes.integer),
    PropTypes.func,
  ]).isRequired,
  level: CustomPropTypes.integer,
  renderHeader: PropTypes.func,
  renderNode: PropTypes.func.isRequired,
  renderLeaf: PropTypes.func.isRequired,
};


const getAllIds = node => {
  if (Array.isArray(node)) {
    return node.reduce((c, n) => [...c, n.id, ...getAllIds(n)], []);
  } else {
    return getAllIds(node.children);
  }
};

export class TreeViewWithState extends React.Component {

  constructor(...args) {
    super(...args);
    this.state = {
      expanded: this.props.defaultExpanded || [],
    };
  }

  isOpen = id => this.state.expanded.indexOf(id) > -1;

  toggle = id => {
    if (this.isOpen(id)) {
      this.setState({expanded: this.state.expanded.filter(x => x !== id)});
    } else {
      this.setState({expanded: [...this.state.expanded, id]});
    }
  }

  hideAll = () => this.setState({ expanded: [] });
  showAll = () => this.setState({ expanded: getAllIds(this.props.tree) });

  render() {
    const {tree, renderNode, renderLeaf, renderActions, ...rest} = this.props;
    return (
      <React.Fragment>

        {renderActions && renderActions({
          toggle: this.toggle,
          showAll: this.showAll,
          hideAll: this.hideAll,
         })}

        <TreeView tree={tree} expanded={this.state.expanded}
                  renderNode={renderNode} renderLeaf={renderLeaf}
                  toggle={this.toggle} showAll={this.showAll} hideAll={this.hideAll}
                  {...rest}/>

      </React.Fragment>
    );
  }
}

TreeViewWithState.propTypes = {
  tree: PropTypes.oneOfType([
    NodeShape,
    PropTypes.arrayOf(NodeShape),
  ]).isRequired,
  defaultExpanded: PropTypes.arrayOf(CustomPropTypes.integer),
  renderNode: PropTypes.func.isRequired,
  renderLeaf: PropTypes.func.isRequired,
  renderActions: PropTypes.func,
};
