import React from 'react';
import PropTypes from 'prop-types';

import {debounce} from '../../../utils/func';

export default class Search extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  // Debounce regular functions, not event handlers to
  // correctly handle propagation (standard) and pool reuse
  // of synthetic events (React)
  onChange = debounce(
    (...args) => this.props.onChange(...args),
    200
  );

  handleInput = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.onChange(evt.target.value);
  };

  noopEvtHandler = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  render() {
    return (
      <form onSubmit={this.noopEvtHandler}>
        <label htmlFor="search">
          <i className="icon-search"></i>
          <input
            name="search"
            className="field"
            type="text"
            onChange={this.handleInput}
            placeholder="Search..."
          />
        </label>
      </form>
    );
  }
}
