/** All renderers relevant to CPC contracts. */
import React from 'react';
import PropTypes from 'prop-types';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import _entries from 'lodash/entries';
import * as _date from 'date-fns';

import classname from '../../../utils/classname';
import * as _strings from '../../../utils/strings';
import * as schema from '../../../components/prop-types/schema';
import DateView from '../../../components/Date';

import { isActiveContract, ActiveLabel, cpcsByDeviceTypes } from './utils';

/**
 * Extract the active contract from the historical contracts
 * @param {object[]} contracts
 * @returns {[object|null, object[]]}
 */
export const splitContracts = contracts => {
  const sortedContracts = _sortBy(contracts);
  if (isActiveContract(sortedContracts[0])) {
    return [sortedContracts[0], sortedContracts.slice(1)];
  } else {
    return [null, sortedContracts];
  }
};

export function ContractDetail(props) {
  return (
    <div className={classname(props.className)}>
      <div className="p1">
        <React.Fragment>
          {_entries(_groupBy(props.prices, 'vertical')).filter(([, prices]) => prices.length).map(([vertical, prices]) => {
            return (
              <div key={vertical} className="flex items-center justify-between flex-wrap p1">
                <div className="medium flex-1">{vertical}</div>
                <div key={vertical} className="flex items-center justify-between flex-wrap flex-auto">
                  {cpcsByDeviceTypes(prices).map(([dt, value]) => (
                    <div className="h3 light flex-1 right-align" key={dt}>
                      {_strings.currencySymbol(props.currency)} {value} ({dt})
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </React.Fragment>
      </div>
      <div className="p1 flex justify-between">
        <div className="p1">
          <div className="medium flex-1">Date&nbsp;</div>
          <DateView
            value={_date.addDays(_date.startOfDay(props.valid_from), 1)}
            naturalTime={{max: 'weeks', keepOnly: 1}}
            stripTime/>
        </div>
        <div className="p1">
          <div className="medium flex-1">Flexible Pricing&nbsp;</div>
          <ActiveLabel active={props.flexible_pricing}/>
        </div>
      </div>
  </div>
  );
}

ContractDetail.propTypes = {
  ...schema._CPCContractShape,
  className: PropTypes.string,
};
