import React from 'react';
import PropTypes from 'prop-types';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';

import { memoize } from '../../../utils/func';
import * as schema from '../../../components/prop-types/schema';
import DateView from '../../../components/Date';

export function Label (props) {
  return (
    <div className={`rounded border inline-block px1 ${props.color}`}>
      { props.text }
    </div>
  );
}
Label.green = 'border-color-success color-success';
Label.blue = 'border-color-blue color-blue';
Label.grey = 'border-color-gray-dark color-gray-dark';
Label.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
};

export function ActiveLabel (props) {
  return (props.active
    ? <Label color={Label.green} text={'Active'} />
    : <Label color={Label.grey} text={'Inactive'} />);
}

ActiveLabel.propTypes = {
  active: PropTypes.bool,
};

export function isActiveContract (contract) {
  const contractStart = new Date(contract.valid_from);
  const contractEnd = new Date(contract.valid_to);
  const now = new Date();
  return (contractStart <= now) && (contractEnd > now);
}

export function isPendingContract (contract) {
  const contractStart = new Date(contract.valid_from);
  const now = new Date();
  return contractStart > now;
}

export function deviceCpcs (cpcs, deviceTypes) {
  if (cpcs.length === 1 && cpcs[0].device_type === null) {
    const defaultCpc = cpcs[0].value;
    return deviceTypes.map(deviceType => [deviceType, defaultCpc]);
  }
  return cpcs.map(cpc => [cpc.device_type, cpc.value, cpc.max_value]);
}

/**
 * Build tuple of (device type, value) ensuring there is always one entry
 * per device type. Fallback order is device > Other > Desktop.
 * */
export function cpcsByDeviceTypes (prices) {
  const byDevice = _groupBy(prices, 'device_type');
  return schema._DeviceTypes.slice(0, 3).map(deviceType => {
    const price = (byDevice[deviceType] || byDevice.null || byDevice[''] || byDevice.Desktop)[0];
    return [deviceType, price.value];
  });
}

export function renderValidFrom (contract) {
  return renderDate(contract.valid_from);
}

export function renderValidTo (contract) {
  return renderDate(contract.valid_to);
}

export function renderDate (date) {
  return <DateView value={date} naturalTime={{max: 'weeks', keepOnly: 1}} stripTime/>;
}

export const flattenCategoryFolder = memoize(
  function flattenCategoryFolder ({id, name, categories, subfolders, path = []}) {
    return {
        id,
        name,
        path: [...path, id],
        children: [
          ..._sortBy(categories || [], 'id').map(cat => ({
            id: cat.id,
            name: cat.name,
            path: [...path, id, cat.id],
            children: [],
          })),
          ..._sortBy(subfolders || [], 'id').map(sf => flattenCategoryFolder({...sf, path: [...path, id]})),
        ],
    };
  }
);

export const tagsSearchMap = memoize(
  function tagsSearchMap ({id, name, categories, subfolders}, path = [], map = {}) {
    let nextMap = {};

    (categories || []).forEach(cat => {
      nextMap[cat.id] = { id: cat.id, name: cat.name, path: [cat.id, id, ...path] };
    });

    (subfolders || []).forEach(sf => {
      nextMap = tagsSearchMap(sf, [id, ...path], nextMap);
    });

    return {
      ...map,
      ...nextMap,
      [id]: { id, name, path: [id, ...path] },
    };
  }
);
