import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as Datagrid from '../../../../components/datagrid';
import * as schema from '../../../../components/prop-types/schema';
import * as utils from '../../../../utils';
import * as renderers from '../../../../components/views/partner-report/renderers';

import ErrorRenderer from '../../../../components/views/partner-report/Error';
import { Button, Spinner } from '../../../../components';
import { makeReportQuery, queryVariables } from './query';


export default class Report extends PureComponent {
    static propTypes = {
        selectedGroupBy: PropTypes.arrayOf(schema.ReportGroupingDimension).isRequired,
        availableGroupBy: PropTypes.arrayOf(schema.ReportGroupingDimension).isRequired,
        fromDate: schema.MaybeDate.isRequired,
        toDate: schema.MaybeDate.isRequired,
        targetCurrency: schema.Currency.isRequired,
        resolution: schema.ReportResolution.isRequired,
    };

    currencySymbol = () => (
      utils.strings.CURRENCY_SYMBOLS[this.props.targetCurrency] ||
      this.props.targetCurrency
    );

    isoCurrency = () => this.props.targetCurrency;

    _columns = [{
        key: 'date',
        label: () => `${utils.strings.titleCase(this.props.resolution)}`,
        renderer: row => renderers.renderDate(row.date, this.props.resolution),
    }, {
        key: 'device_type',
        label: 'Device',
        renderer: row => renderers.renderDeviceType(row.device_type),
        exportRenderer: renderers.titleCaseRenderer('device_type'),
    }, {
        key: 'sales_count',
        label: 'Total Sales',
        renderer: renderers.numeralRenderer(),
    }, {
        key: 'total_amount',
        label: 'Total Sales Revenue',
        renderer: renderers.numeralRenderer(this.currencySymbol),
        exportRenderer: renderers.numeralRenderer(this.isoCurrency),
    },
    ].map(
        c => ({
            ...c,
            label: c.label || utils.strings.titleCase(c.key),
            renderer: c.renderer || renderers.identity,
            exportRenderer: c.exportRenderer || c.renderer || renderers.identity,
            sortable: true,
        })
    );

    isColumnMeaningful = (colKey) => (
      this.props.availableGroupBy.indexOf(colKey) === -1 ||
      this.props.selectedGroupBy.length === 0 ||
      this.props.selectedGroupBy.indexOf(colKey) > -1
    );

    // All available columns (only removes unselected dimensions)
    columns = () => this._columns.filter(
      col => {
        if (col.key.slice(0, 2) === '__') {
          return this.isColumnMeaningful(col.key.slice(2));
        } else {
          return this.isColumnMeaningful(col.key);
        }
      }
    );


    renderActions = (props) => {
        return (
            <div className="mxn1">
                {props.loading &&
                    <Button disabled className="mx1">
                        <span>Fetching data <Spinner /></span>
                    </Button>}
            </div>
        );
    }

    render() {
        return (
            <Datagrid.Container
                columns={this.columns()}
                fetch={makeReportQuery}
                defaultPageSize={50}
                disableSearch
                hideContentOnError
                mapParameters={queryVariables}
                actions={this.renderActions}
                {...this.props}
                cellMinWidth={100}
            >
                {(props) => (
                    <div className="flex flex-column flex-auto">
                        <ErrorRenderer
                            error={props.error}
                            loading={props.loading}
                            reload={props.reload}
                        />
                        <Datagrid.Controls {...props} />
                        <Datagrid.FixedTable {...props} />
                    </div>
                )}
            </Datagrid.Container>
        );
    }

}
