/**
 * Create an interpolation function from the domain [0, 1] into a discrete
 * color scale. Stolen from d3-scale.
 * @param {string[]} range - The list of colors making up the discrete color scale.
 * @return {(n: number) => string}
 */
export function colorScaleInterpolator(range) {
  const len = range.length;
  return (index) => range[Math.max(0, Math.min(len - 1, Math.floor(index * len)))];
}

export const TEAL = '#126274';
export const MINT = '#2ea796';
export const PURPLE = '#5824a6';

export const SCALE_TEAL = [
  '#126274', '#186678', '#1d6c7c', '#227180', '#277684', '#2c7b89', '#31808d',
  '#358591', '#3a8b95', '#3e9099', '#42959d', '#469aa1', '#4a9fa5', '#4fa5aa',
  '#53aaae', '#57b0b2', '#5bb6b7', '#5fbbbb', '#64c0bf', '#68c5c3', '#6ccbc8',
  '#70d1cd', '#74d7d1', '#79dcd5', '#7de2da', '#81e8de', '#85eee2', '#8af3e7',
  '#8ef9ec', '#92fff0',
];

export const SCALE_PURPLE_PINK = [
  '#5824a6', '#5f26a5', '#6629a3', '#6d2ba2', '#732ea1', '#7a309f', '#7f329e',
  '#85359d', '#8a379b', '#903a9a', '#943d99', '#9a4097', '#9f4396', '#a44594',
  '#a94893', '#ad4b92', '#b24d90', '#b7508f', '#bb538d', '#c0578b', '#c4598a',
  '#ca5c88', '#cd5f87', '#d16285', '#d76584', '#da6882', '#df6b80', '#e46e7e',
  '#e7717d', '#ec747b',
];

export const SCALE_PURPLE_TEAL = [
  '#5824a6', '#5728a4', '#562ba3', '#542ea1', '#53319f', '#52349d', '#51379c',
  '#4f399a', '#4e3c98', '#4c3e97', '#4b4095', '#494293', '#474491', '#464690',
  '#44488e', '#424a8c', '#404c8b', '#3e4e89', '#3c5087', '#395285', '#375484',
  '#345582', '#315780', '#2e597e', '#2b5a7d', '#275c7b', '#235d79', '#1f5f77',
  '#196076', '#126274',
];

export const SCALE_TEAL_MINT = [
  '#2ea796', '#2da595', '#2da294', '#2ca092', '#2b9d91', '#2a9b90', '#29988f',
  '#29968e', '#28938d', '#27918b', '#268e8a', '#258c89', '#248a88', '#238787',
  '#238586', '#228284', '#218083', '#207e82', '#1f7b81', '#1e7980', '#1d777f',
  '#1c747d', '#1b727c', '#19707b', '#186d7a', '#176b79', '#166978', '#156776',
  '#136475', '#126274',
];
