import React from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import * as _qs from 'query-string';
import {Button, LoadingState, MaintenanceState} from '..';
import * as schema from '../prop-types/schema';

export default function LoginRoute(props) {
  const params = _qs.parse(props.location.search);

  if (props.loading) return <LoadingState/>;
  if (props.is_under_maintenance) return <MaintenanceState/>;
  if (props.user) return <Redirect to={params.redirect || '/'}/>;

  const onLogin = () => props.onLogin(params.redirect || '/');

  return (
    <div className="flex-1 p2 flex flex-column justify-center items-center">
      <div>
        { !!params.error && (
          <div className="error color-red border rounded border-color-red p1 my1">
            {params.error}
          </div>
        )}
        <Button
          onClick={onLogin}
          disabled={props.loading}
          level="primary"
          icon="exit_to_app"
          iconPosition="right"
          large
          block
        >
          Log In
        </Button>
      </div>
    </div>
 );
}

LoginRoute.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Error),
  ]),
  user: schema.User,
  onLogin: PropTypes.func.isRequired,
  location: schema.RouterLocation.isRequired,
  is_under_maintenance: PropTypes.bool,
};
