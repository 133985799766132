import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '../..';

export default function ErrorRenderer(props) {
  if (props.loading || !props.error) return null;
  const msg = (
    process.env.NODE_ENV === 'production' ? (
      <div>
        There was a problem when fetching data. Please retry.
        <br/>
        If the problem persists please contact us.
      </div>
    ) : (props.error instanceof Error && props.error.message) || props.error
  );
  return (
    <div className="color-red border rounded border-color-red p1 my1 flex justify-between">
      <div className="p1">{msg}</div>
      {!!props.reload && (
        <Button small onClick={props.reload}>Reload</Button>
      )}
    </div>
  );
}

ErrorRenderer.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(Error),
    PropTypes.string,
  ]),
  loading: PropTypes.bool,
  reload: PropTypes.func,
};
