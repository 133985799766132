import React from 'react';
import PropTypes from 'prop-types';
import Conveyor from 'react-conveyor';
import RedScreenOfDeath from './RedScreenOfDeath';
import LoadingState from './LoadingState';

/**
 * This is a tiny recursive factory function to write terse, declarative
 * representations of JSX / React trees.
 * Converts nested array notationto React.createElement calls
 * or other compatible pragma (Preact, etc.)
 * Similar to ijk (https://github.com/lukejacksonn/ijk) but with React in mind.
 *
 * For instance:
 *
 * lispify(
 *  ['div', {className: 'foo'},
 *   [Button, {onClick=clickHandler}, 'click me'],
 *   'Bar',]
 * )
 *
 * Is equivalent to:
 *
 * <div className="foo">
 *   <Button onClick={clickHandler}>
 *     Click Me
 *   </Button>
 *   Bar
 * </div>
 */
export function lispify(node = [], pragma = React.createElement) {
  if (Array.isArray(node)) {
    const mapChildren = nodes => nodes.map(n => lispify(n, pragma)).filter(n => n != null);
    if (node.length === 0) return null;
    if ((typeof node[1] === 'object' && !Array.isArray(node[1])) || node[1] == null) {
      return pragma(node[0], node[1] || {}, ...mapChildren(node.slice(2)));
    } else {
      return pragma(node[0], {}, ...mapChildren(node.slice(1)));
    }
  } else {
    return node;  // Strings and other primitives
  }
}

const shouldComponentUpdate = React.PureComponent.prototype.shouldComponentUpdate;

/**
 *  Transform a regular or functionnal component into a pure rendered
 * component. Adapted from
 * https://gist.github.com/developit/b3231344b6b056374bc630fa58308616.
 *
 * See https://facebook.github.io/react/docs/react-api.html#react.purecomponent
 * for information on rationale and caveats of the pure render approach.
 *
 * @param {Function} component
 * @returns {Function}
 */
export function purify(component) {
  if (component.prototype && component.prototype.render) {
    // eslint-disable-next-line no-param-reassign
    component.prototype.shouldComponentUpdate = shouldComponentUpdate;
    return component;
  } else {
    class PureWrapper extends React.PureComponent {
      render() {
        return component(this.props, this.context);
      }
    }
    PureWrapper.displayName = `${component.name || 'ANONYMOUS'}PureWrapper`;
    return PureWrapper;
  }
}

/**
 * Basic wrappers around the Conveyor
 */

const isLoading = props => props.missing || props.inFlight;

/* eslint-disable react/prop-types */
const simpleConveyorChildren = (children, conf) => ({inFlight, missing, errors, ...rest}) => {
  const isLoadingFunc = conf.isLoading || isLoading;
  if (isLoadingFunc({inFlight, missing, ...conf})) {
    return <LoadingState/>;
  } else if (errors) {
    return <RedScreenOfDeath className="flex-auto scroll-y" errors={errors}/>;
  } else {
    return children(rest);
  }
};

export function DataLoader(props) {
  const {children, ...conveyorProps} = props;
  return (
    <Conveyor {...conveyorProps}>
      {simpleConveyorChildren(children)}
    </Conveyor>
  );
}

DataLoader.propTypes = {
  children: PropTypes.func.isRequired,
};

export function withData(conf) {
  return Component => {
    return Conveyor.wrapComponent(
      conf,
      simpleConveyorChildren(props => <Component {...props}/>, conf)
    );
  };
}
