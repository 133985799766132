import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';
import * as _dateFn from 'date-fns';
import {Portal} from 'react-portal';

import Icon from '../Icon';
import KeyboardListener from '../KeyboardListener';
import DateRangePicker from './DateRangePicker';

const noop = () => undefined;

function formatDate (date) {
  return _dateFn.format(date, 'YYYY-MM-DD');
}

export default class DateRangePickerInput extends PureComponent {

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onChange = ({startDate, endDate}) => {
    this.setState({open: false});
    this.props.onChange({startDate, endDate});
  }

  _ref = el => {
    this.node = el;
  }

  onFocus = () => {
    this.setState({open: true});
    this.node.querySelector('input').blur();

  };

  close = () => this.setState({open: false});

  portalStyle = () => {
    if (!this.node) return {};
    const rect = this.node.getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    return {
      position: 'fixed',
      top: rect.top - bodyRect.top,
      left: rect.left - bodyRect.left,
    };
  }

  ICON_STYLE = {
    top: '0.5em',
    right: '0.5em',
  };

  INPUT_STYLES = {
    paddingRight: '2em',
  };

  render() {
    const pickerProps = _omit(this.props, Object.keys(DateRangePickerInput.propTypes));
    return (
      <label ref={this._ref} className="label block fill relative" data-icon="date_range">
        <KeyboardListener handler={this.close} keyName="Escape"/>
        <input
          type="text"
          placeholder="Enter a date range. (eg: YYYY-MM-DD to YYYY-MM-DD)"
          // This will trigger a warning --> ignore it.
          value={`${formatDate(this.props.startDate)} to ${formatDate(this.props.endDate)}`}
          className="field cursor-pointer fill inline-block"
          onFocus={this.onFocus} style={this.INPUT_STYLES} onChange={noop}
        />
        <Icon className="color-gray-dark absolute" style={this.ICON_STYLE} size="small" text="date_range"/>
        { this.state.open && (
          <Portal onClose={this.close}>
            <div className="z4" style={this.portalStyle()}>
              <DateRangePicker
                {...pickerProps}
                defaultStartDate={this.props.startDate}
                defaultEndDate={this.props.endDate}
                onChange={this.onChange}
                onClose={this.close}
                className="layer-8"
              />
              {/* <div className="fixed top-0 right-0 bottom-0 left-0 z3" onClick={this.close}>
                  </div> */}
            </div>
          </Portal>
        )}
      </label>
    );
  }
}
