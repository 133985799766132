import React, {PureComponent} from 'react';
import LoadingState from '../../../components/LoadingState';
import {Alert} from '../../../components/Alert';
import {Button} from '../../../components';
import PropTypes from 'prop-types';
import {FileUploadInput} from '../../../components/FileUploadInput';
import {LUXURY_LOFT_TYPE, PREMIUM_LOFT_TYPE} from '../../../utils/constants';
import * as _sentry from '../../../sentry';


const styles = {
  outerWrapper: {
    margin: '5px auto',
    width: '100%',
    height: '100%',
    maxWidth: '1380px',
    textAlign: 'center',
  },
  mainWrapper: {
    marginTop: '40px',
  },
  topImage: {
    height: '250px',
    position: 'relative',
  },
  horizontalImages: {
    display: 'flex',
    maxWidth: '1380px',
    height: '250px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  leftImage: {
    height: '250px',
    flex: '1',
    position: 'relative',
    marginRight: '4px',
  },
  rightImage: {
    height: '250px',
    flex: '1',
    position: 'relative',
    marginLeft: '4px',
  },
  mobileImage: {
    height: '500px',
    position: 'relative',
  },
  loading: {
    left: '50%',
    top: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
  },
  editIcon: {
    position: 'absolute',
  },
  topImagePlaceholder: {
    backgroundColor: '#E0E0E0',
    height: '250px',
  },
  mobileImagePlaceholder: {
    backgroundColor: '#E0E0E0',
    height: '500px',
  },
  imageTextPlaceholder: {
    position: 'relative',
    top: '35%',
    fontSize: '25px',
  },
  imageTextDescriptionPlaceholder: {
    position: 'relative',
    top: '35%',
    fontSize: '20px',
  },
  horizontalImagePlaceholder: {
    backgroundColor: '#E0E0E0',
    height: '250px',
  },
  horizontalImageSize: {
    width: '690px',
    height: '250px',
  },
  saveButton: {
    margin: '0 0 30px 0',
  },
};

const TOP_IMAGE = 'top_image';
const LEFT_IMAGE = 'left_image';
const RIGHT_IMAGE = 'right_image';
const MOBILE_IMAGE = 'mobile_image';

const TOP_IMAGE_DIMENSIONS = [1380, 250];
const LEFT_IMAGE_DIMENSIONS = [680, 250];
const RIGHT_IMAGE_DIMENSIONS = [680, 250];
const MOBILE_IMAGE_DIMENSIONS = [1380, 500];

export const LOADING_IMAGES_ERROR = 'Error while loading images, please refresh the page.';
export const NO_IMAGE_UPLOAD_ERROR = 'Please upload at least one image.';
export const SAVING_IMAGES_ERROR = 'An error occurred while saving the image.';
export const SUCCESS_MESSAGE = 'Operation completed successfully.';

export const getSizeError = (width, height) =>
  `Please provide an image with a size of ${width} x ${height} pixels.`;

export default class LoftHandler extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      topImageFile: null,
      leftImageFile: null,
      rightImageFile: null,
      mobileImageFile: null,
      error: null,
      success: null,
      isChecked: false,
      isSaving: false,
    };
  }

  componentDidMount() {
    const luxuryEndpoint = '/app/luxury-loft';
    const premiumEndpoint = '/app/premium-loft';

    const {loftType} = this.props;

    if (loftType === LUXURY_LOFT_TYPE) {
      this.setState({
        loftName: 'Luxury',
        apiEndpoint: luxuryEndpoint,
      });
    } else if (loftType === PREMIUM_LOFT_TYPE) {
      this.setState({
        loftName: 'Premium',
        apiEndpoint: premiumEndpoint,
      });
    }
  }

  loadImage = (image, imagePosition) => {
    const reader = new FileReader();

    if (!image) {
      return;
    }

    reader.onloadend = () => {
      const _this = this;

      const uploadedImage = new Image();

      uploadedImage.onload = () => {
        const width = uploadedImage.width;
        const height = uploadedImage.height;

        if (imagePosition === TOP_IMAGE) {
          if (TOP_IMAGE_DIMENSIONS[0] === width && TOP_IMAGE_DIMENSIONS[1] === height) {
            _this.setState({
              topImageFile: {
                loadedImage: image,
                imagePreviewUrl: reader.result,
                imageName: image.name,
              },
              error: null,
            });
          } else {
            _this.setState({
              topImageFile: null,
              error: getSizeError(TOP_IMAGE_DIMENSIONS[0], TOP_IMAGE_DIMENSIONS[1]),
            });
            // scroll to top
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
          }
        } else if (imagePosition === LEFT_IMAGE) {
          if (LEFT_IMAGE_DIMENSIONS[0] === width && LEFT_IMAGE_DIMENSIONS[1] === height) {
            _this.setState({
              leftImageFile: {
                loadedImage: image,
                imagePreviewUrl: reader.result,
                imageName: image.name,
              },
              error: null,
            });
          } else {
            _this.setState({
              leftImageFile: null,
              error: getSizeError(LEFT_IMAGE_DIMENSIONS[0], LEFT_IMAGE_DIMENSIONS[1]),
            });
            // scroll to top
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
          }
        } else if (imagePosition === RIGHT_IMAGE) {
          if (RIGHT_IMAGE_DIMENSIONS[0] === width && RIGHT_IMAGE_DIMENSIONS[1] === height) {
            _this.setState({
              rightImageFile: {
                loadedImage: image,
                imagePreviewUrl: reader.result,
                imageName: image.name,
              },
              error: null,
            });
          } else {
            _this.setState({
              rightImageFile: null,
              error: getSizeError(RIGHT_IMAGE_DIMENSIONS[0], RIGHT_IMAGE_DIMENSIONS[1]),
            });
            // scroll to top
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
          }
        } else if (imagePosition === MOBILE_IMAGE) {
          if (MOBILE_IMAGE_DIMENSIONS[0] === width && MOBILE_IMAGE_DIMENSIONS[1] === height) {
            _this.setState({
              mobileImageFile: {
                loadedImage: image,
                imagePreviewUrl: reader.result,
                imageName: image.name,
              },
              error: null,
            });
          } else {
            _this.setState({
              mobileImageFile: null,
              error: getSizeError(MOBILE_IMAGE_DIMENSIONS[0], MOBILE_IMAGE_DIMENSIONS[1]),
            });
            // scroll to top
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
          }
        }
      };

      uploadedImage.src = reader.result;
    };

    reader.readAsDataURL(image);
  };

  saveChanges = () => {
    const {topImageFile, leftImageFile, rightImageFile, mobileImageFile, apiEndpoint} = this.state;

    this.setState({
      isSaving: true,
    });

    if (topImageFile === null && leftImageFile === null && rightImageFile === null &&
      mobileImageFile === null) {
      this.setState({
        error: NO_IMAGE_UPLOAD_ERROR,
        isSaving: false,
      });
      // scroll to top
      window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }
    else {
      const formData = new FormData();

      if (topImageFile !== null) {
        formData.append('image_top', topImageFile.loadedImage);
      }
      if (leftImageFile !== null) {
        formData.append('image_left', leftImageFile.loadedImage);
      }
      if (rightImageFile !== null) {
        formData.append('image_right', rightImageFile.loadedImage);
      }
      if (mobileImageFile !== null) {
        formData.append('image_top_mobile', mobileImageFile.loadedImage);
      }

      // save the images
      fetch(apiEndpoint, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          // scroll to top
          if(!result.ok) {
            _sentry.captureException(result, {level: 'error'});
          }
          window.scrollTo({top: 0, left: 0, behavior: 'smooth' });

          if (result.data && result.data.error && result.data.error.msg) {
            // display error message
            this.setState({
              error: result.data.error.msg,
              isSaving: false,
              isChecked: false,
            });
          } else {
            // display success message
            this.setState({
              error: null,
              success: true,
              isSaving: false,
              isChecked: false,
            });

            // hide message after 10 seconds
            setTimeout(
              () => this.setState({
                success: null,
              }),
              10 * 1000
            );
          }
        })
        .catch(err => {
          _sentry.captureException(err, {level: 'error'});

          // scroll to top
          window.scrollTo({top: 0, left: 0, behavior: 'smooth' });

          // display error message
          this.setState({
            error: SAVING_IMAGES_ERROR,
            isSaving: false,
            isChecked: false,
          });
        });
    }
  };

  getTopImageSection = (imageFile, fetchImages) => {
    if (imageFile !== null) {
      return (
        <div style={styles.topImage}>
          <FileUploadInput fileName={imageFile.imageName} onChange={this.loadImage}
                           position={TOP_IMAGE}/>
          <img src={imageFile.imagePreviewUrl} alt={'top image'}/>
        </div>
      );
    } else if (fetchImages.value.data.images.image_top_url !== null) {
      return (
        <div style={styles.topImage}>
          <FileUploadInput onChange={this.loadImage} position={TOP_IMAGE}/>
          <img src={fetchImages.value.data.images.image_top_url} alt={'top image'}/>
        </div>
      );
    } else {
      return (
        <div style={styles.topImage}>
          <FileUploadInput onChange={this.loadImage} position={TOP_IMAGE}/>
          <div style={styles.topImagePlaceholder}>
            <div style={styles.imageTextPlaceholder}>1380 x 250</div>
            <div style={styles.imageTextDescriptionPlaceholder}>(Desktop top image)</div>
          </div>
        </div>
      );
    }
  };

  getLeftImageSection = (imageFile, fetchImages) => {
    if (imageFile !== null) {
      return (
        <div style={styles.leftImage}>
          <FileUploadInput fileName={imageFile.imageName} onChange={this.loadImage}
                           position={LEFT_IMAGE}/>
          <img src={imageFile.imagePreviewUrl} alt={'left image'}
               style={styles.horizontalImageSize}/>
        </div>
      );
    } else if (fetchImages.value.data.images.image_left_url !== null) {
      return (
        <div style={styles.leftImage}>
          <FileUploadInput onChange={this.loadImage} position={LEFT_IMAGE}/>
          <img src={fetchImages.value.data.images.image_left_url} alt={'left image'}
               style={styles.horizontalImageSize}/>
        </div>
      );
    } else {
      return (
        <div style={styles.leftImage}>
          <FileUploadInput onChange={this.loadImage} position={LEFT_IMAGE}/>
          <div style={styles.horizontalImagePlaceholder}>
            <div style={styles.imageTextPlaceholder}>680 x 250</div>
            <div style={styles.imageTextDescriptionPlaceholder}>(Desktop left image)</div>
          </div>
        </div>
      );
    }
  };

  getRightImageSection = (imageFile, fetchImages) => {
    if (imageFile !== null) {
      return (
        <div style={styles.rightImage}>
          <FileUploadInput fileName={imageFile.imageName} onChange={this.loadImage}
                           position={RIGHT_IMAGE}/>
          <img src={imageFile.imagePreviewUrl} alt={'right image'}
               style={styles.horizontalImageSize}/>
        </div>
      );
    } else if (fetchImages.value.data.images.image_right_url !== null) {
      return (
        <div style={styles.rightImage}>
          <FileUploadInput onChange={this.loadImage} position={RIGHT_IMAGE}/>
          <img src={fetchImages.value.data.images.image_right_url} alt={'right image'}
               style={styles.horizontalImageSize}/>
        </div>
      );
    } else{
      return (
        <div style={styles.rightImage}>
          <FileUploadInput onChange={this.loadImage} position={RIGHT_IMAGE}/>
          <div style={styles.horizontalImagePlaceholder}>
            <div style={styles.imageTextPlaceholder}>680 x 250</div>
            <div style={styles.imageTextDescriptionPlaceholder}>(Desktop right image)</div>
          </div>
        </div>
      );
    }
  };

  getMobileImageSection = (imageFile, fetchImages) => {
    if (imageFile !== null) {
      return (
        <div style={styles.mobileImage}>
          <FileUploadInput fileName={imageFile.imageName} onChange={this.loadImage}
                           position={MOBILE_IMAGE}/>
          <img src={imageFile.imagePreviewUrl} alt={'mobile image'}/>
        </div>
      );
    } else if (fetchImages.value.data.images.image_top_mobile_url !== null) {
      return (
        <div style={styles.mobileImage}>
          <FileUploadInput onChange={this.loadImage} position={MOBILE_IMAGE}/>
          <img src={fetchImages.value.data.images.image_top_mobile_url}
               alt={'mobile image'}/>
        </div>
      );
    } else {
      return (
        <div style={styles.mobileImage}>
          <FileUploadInput onChange={this.loadImage} position={MOBILE_IMAGE}/>
          <div style={styles.mobileImagePlaceholder}>
            <div style={styles.imageTextPlaceholder}>1380 x 500</div>
            <div style={styles.imageTextDescriptionPlaceholder}>(Mobile top image)</div>
          </div>
        </div>
      );
    }
  };

  render() {
    const {fetchImages} = this.props;
    const {topImageFile, leftImageFile, rightImageFile, mobileImageFile, error,
      success, isChecked, isSaving, loftName} = this.state;
    const isSaveButtonDisabled = !(topImageFile !== null || leftImageFile !== null ||
      rightImageFile !== null || mobileImageFile !== null);

    if (fetchImages.fulfilled) {
      return (
        <div style={styles.outerWrapper}>
          <div className={'my1 left-align h2 bold color-st-blue'}>
            {loftName} Loft - Header Management
          </div>
          <div className={'left-align'}>
            <div>
              This page allows you to directly update the images of your {loftName} Loft header. The
              required dimensions for these images are detailed below.
            </div>
            <div>
              In case you encounter a problem, please contact your account manager who can also
              upload these images at your request.
            </div>
          </div>
          {
            error !== null ? <Alert level="error" show={true} message={error}/> : null
          }
          {
            success !== null ? <Alert level="success" show={true}
                                      message={SUCCESS_MESSAGE}/> : null
          }
          <div style={styles.mainWrapper}>
            <div className={'left-align'}>
              <div className={'h3'}>Desktop images</div>
              <div>Required dimensions: top image 1380x250 pixels - bottom left and right images
                680x250 pixels
              </div>
            </div>
            {
              this.getTopImageSection(topImageFile, fetchImages)
            }
            <div style={styles.horizontalImages}>
              {
                this.getLeftImageSection(leftImageFile, fetchImages)
              }
              {
                this.getRightImageSection(rightImageFile, fetchImages)
              }
            </div>
            <div className={'pt2 left-align'}>
              <div className={'h3'}>Mobile image</div>
              <div>Required dimension: 1380x500 pixels</div>
              <div>
                Please note that the mobile image will only replace the top Desktop image, the
                bottom left and right images are used for both desktop and mobile.
              </div>
            </div>
            {
              this.getMobileImageSection(mobileImageFile, fetchImages)
            }
          </div>
          <div>
            <div className={'pt2 flex left-align'}>
              <input type="checkbox"
                     id="ll-check"
                     name="checkbox"
                     checked={isChecked}
                     onChange={() => this.setState({isChecked: !isChecked})}/>
              <label htmlFor="ll-check" className={'pl1'}>
                By clicking on the ’Save’ button, I am certifying that my images meet the
                requirements of the <a
                href={'https://partner.stylight.com/wp-content/uploads/sites/5/2019/03/Stylight_Terms_and_Conditions_20171205.pdf'}
                rel={'noopener noreferrer'}
                target="_blank">
                General Terms and Conditions of Stylight
              </a>.
                I am aware that Stylight doesn’t proceed to any control over these images
                before they are published and I am therefore fully responsible for the content.
              </label>
            </div>
            <Button icon={'save'}
                    onClick={this.saveChanges}
                    disabled={isSaveButtonDisabled || isSaving || !isChecked}
                    className={'is-primary'}
                    name={'save'}
                    style={styles.saveButton}>
              {
                isSaving ?
                  <span>
                    Saving...
                  </span> :
                  <span>
                    Save
                  </span>
              }
            </Button>
          </div>
        </div>
      );
    } else if (fetchImages.pending) {
      return (
        <div className="absolute" style={styles.loading}>
          <LoadingState />
        </div>
      );
    }
    return (
      <div className="p1">
        <Alert level="error" show={true}
               message={LOADING_IMAGES_ERROR}/>
      </div>
    );
  }
}

LoftHandler.propTypes = {
  fetchImages: PropTypes.object, //eslint-disable-line react/forbid-prop-types
  loftType: PropTypes.oneOf([
    LUXURY_LOFT_TYPE,
    PREMIUM_LOFT_TYPE,
  ]).isRequired,
};
