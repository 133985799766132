import React from 'react';
import PropTypes from 'prop-types';

import * as _func from '../utils/func';

/**
 * Generic try/catch renderer to account for all uncaught rendering errors.
 * */
export default class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error});
    this.props.onError(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return _func.apply(this.props.renderOnError, this.state);
    } else {
      return this.props.children;
    }
  }
}

ErrorBoundary.propTypes = {
  renderOnError: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  onError: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

ErrorBoundary.defaultProps = {
  onError: () => null,
  renderOnError: () => null,
};
