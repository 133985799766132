import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {ScrollSync, ScrollSyncPane} from 'react-scroll-sync';

import {
  classname,
  func as _func,
  dom as _dom,
  strings as _strings,
} from '../../../utils';
import CustomPropTypes from '../../prop-types';
import ResizeListener from '../../ResizeListener';

import DatagridPropTypes from '../prop-types';
import {sortListToMap} from '../utils';
import {Cell, HeaderCell} from './Cell';

// Nothing should ever conflict with this class.
const MAGIC_CELL_CLASS = `__CELL_IDENTIFIER_${_strings.randomHEX()}__`;

/**
 * Table renderer with fixed header and columns.
 *
 * The most important thing here is to maintain the React paradigm of (almost)
 * never touching the DOM directly, which is why this container takes care of
 * the size calculations and pass them explicitely to the children which are
 * never directly.
 */
export default class FixedTable extends PureComponent {

  constructor(props) {
    super(props);
    this._rowHeights = {};
    this.state = {
      rowHeights: this._rowHeights,
    };
  }

  /**
   * Flush `_rowHeights` to the React state `rowHeights` which will trigger
   * a render update.
   * We do this to avoid constant redraw while calculcations are in progress;
   * the debounce configuration is there to still flush in case something
   * blocks so users do not experience too much of a visible lag.
   */
  flushSize = _func.debounce(() => {
    this.setState({rowHeights: this._rowHeights});
  }, 25, {leading: true, trailing: true, maxWait: 100});

  ref = node => { this.node = node; };

    /**
     * Resize handler: check all cells for their size and adjust height of the
     * rows accordingly.
     */
  onResize = () => {
    if (this.node) {
      const cells = this.node.querySelectorAll(`.${MAGIC_CELL_CLASS}`);
      const rowHeights = {};

      const updateRowHeight = cell => {

        const childCss = _dom.getComputedStyle(cell.firstChild);
        const parentCss = _dom.getComputedStyle(cell);
        // Some rounding errors can offset the result, this way we force
        // the size up and avoid the issue.
        const parseCssSpace = x => Math.ceil(parseFloat(x)) + 1;
        const childHeight = (
          cell.firstChild.offsetHeight
          + parseCssSpace(childCss.marginTop)
          + parseCssSpace(childCss.marginBottom)
          + parseCssSpace(parentCss.paddingTop)
          + parseCssSpace(parentCss.paddingBottom)
        );

        const row = cell.getAttribute('data-row');
        rowHeights[row] = Math.max(rowHeights[row] || 0, childHeight);
      };

      // NodeList doesn't have `forEach` in all browsers... looking at old
      // Chrome (<= 49) and Safari.
      [].forEach.call(cells, updateRowHeight);

      const hasDifference = (
        Object.keys(rowHeights)
        .some(key => rowHeights[key] !== this._rowHeights[key])
      );

      if (hasDifference) {
        this._rowHeights = rowHeights;
        this.flushSize();
      }
    }
  }

  componentDidUpdate() {
    // Resize everything after render in case render was not due to
    // window resize
    this.onResize();
  }

  onRequestSort = colname => this.props.onUpdateParameter({
    name: 'sort',
    value: colname,
  });

  render() {
    const {
      items, columns, parameters, fixedColumnCount, loading,
      className, bodyClassName, cellClassName, columnClassName,
      columnHeaderClassName, columnBodyClassName, resizeListenerStyles,
      cellMinWidth,
    } = this.props;

    const sortMap = sortListToMap(parameters.sortList);

    return (
      <ScrollSync horizontal={false}>
        <ResizeListener onResize={this.onResize} style={resizeListenerStyles}>
          <div className={classname(className, {
            'is-loading': loading,
          })} ref={this.ref}>
          { (fixedColumnCount > 0) &&
            <div className={classname(bodyClassName, 'is-fixed')}>
              {
                columns.slice(0, fixedColumnCount).map(column => (
                  <Column
                    key={column.key}
                    items={items}
                    column={column}
                    onResizeCell={this.onResizeCell}
                    rowHeights={this.state.rowHeights}
                    sortDirection={sortMap[column.key]}
                    onSort={this.onRequestSort}
                    className={columnClassName}
                    bodyClassName={columnBodyClassName}
                    headerClassName={columnHeaderClassName}
                    cellClassName={cellClassName}
                    cellMinWidth={cellMinWidth}
                  />
                ))
              }
            </div> }
            { (columns.length - fixedColumnCount) > 0 &&
              <div className={bodyClassName}>
              {
                columns.slice(fixedColumnCount).map(column => (
                  <Column
                    key={column.key}
                    items={items}
                    column={column}
                    onResizeCell={this.onResizeCell}
                    rowHeights={this.state.rowHeights}
                    sortDirection={sortMap[column.key]}
                    onSort={this.onRequestSort}
                    className={columnClassName}
                    bodyClassName={columnBodyClassName}
                    headerClassName={columnHeaderClassName}
                    cellClassName={cellClassName}
                    cellMinWidth={cellMinWidth}
                  />
                ))
              }
            </div> }
          </div>
        </ResizeListener>
      </ScrollSync>
    );
  }
}


FixedTable.propTypes = {
  columns: PropTypes.arrayOf(DatagridPropTypes.Column).isRequired,
  onUpdateParameter: PropTypes.func.isRequired,
  pagination: DatagridPropTypes.Pagination,
  parameters: DatagridPropTypes.Parameters,
  items: PropTypes.arrayOf(Object).isRequired,
  loading: PropTypes.bool,
  fixedColumnCount: CustomPropTypes.integer.isRequired,
  fixedColumnsSide: PropTypes.oneOf(['right', 'left']),
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  columnClassName: PropTypes.string,
  columnBodyClassName: PropTypes.string,
  columnHeaderClassName: PropTypes.string,
  cellClassName: PropTypes.string,
  resizeListenerStyles: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  cellMinWidth: CustomPropTypes.integer,
};

FixedTable.defaultProps = {
  fixedColumnCount: 0,
  className: 'fixed-table',
  bodyClassName: 'fixed-table-body',
  columnClassName: 'fixed-table-column',
  columnBodyClassName: 'fixed-table-column-body',
  columnHeaderClassName: 'fixed-table-header',
  cellClassName: 'fixed-table-cell',
  resizeListenerStyles: {
    display: 'flex',
  },
};


class Column extends PureComponent {

  render() {
    const {
      column, items, rowHeights, onSort, sortDirection,
      cellClassName, bodyClassName, className, headerClassName,
      cellMinWidth,
    } = this.props;

    const headerHeight = rowHeights[-1];

    return (
      <div className={className}>

        <div style={{minHeight: headerHeight}} className={headerClassName}>
          <HeaderCell
            column={column}
            className={classname(MAGIC_CELL_CLASS, cellClassName)}
            onSort={onSort}
            sortDirection={sortDirection}
            data-row="-1"
            style={{minHeight: headerHeight, minWidth: cellMinWidth}}
            _wrapper={cellRenderWrapper}
          />
        </div>

        <ScrollSyncPane>
          <div className={bodyClassName}>
            {
              items.map((item, index) => {
                const itemKey = item.id || (index - 1);
                const rowHeight = rowHeights[index];
                return (
                  <div key={itemKey}>
                    <Cell
                      className={classname(MAGIC_CELL_CLASS, cellClassName)}
                      column={column}
                      item={item}
                      data-row={index}
                      _wrapper={cellRenderWrapper}
                      style={{minHeight: rowHeight, minWidth: cellMinWidth}}
                    />
                  </div>
                );
              })
            }
          </div>
        </ScrollSyncPane>
      </div>
    );
  }
}

Column.propTypes = {
  column: DatagridPropTypes.Column.isRequired,
  items: PropTypes.arrayOf(Object).isRequired,
  rowHeights: PropTypes.objectOf(PropTypes.number).isRequired,
  sortDirection: PropTypes.oneOf([-1, 0, 1]),
  onSort: PropTypes.func,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  cellMinWidth: CustomPropTypes.integer,
};

// Enforce cells always have a first child regardless of the
// renderer so we can accurately calculate the inner size.
function cellRenderWrapper (p) {
  return <div {...p}><div>{p.children}</div></div>;
}
