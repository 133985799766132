import {Switch} from 'react-router-dom';
import React, {PureComponent} from 'react';

import * as schema from '../../../components/prop-types/schema';
import {ExternalPrivateRoute as PrivateRoute, Route} from '../../../components/routing';
import {Tabs as TabsRow} from '../../../components/tabs';
import NoMatchRoute from '../../../components/views/NotFound';

import ReportSection from './ReportSection';
import PerformanceOverviewSection from './PerformanceOverviewSection';
import ProductReportSection from './ProductReportSection';

export default class ReportApp extends PureComponent {

  isActivePath = path => path === this.props.location.pathname;

  render() {
    const tabs = [
      { text: 'Clicks & Commission Report',
        path: '/report',
        active: this.isActivePath('/report') },
      { text: 'Product-based Reports',
        path: '/report/products',
        active: this.isActivePath('/report/products') },
      {
        text: 'Performance Overview',
        path: '/report/performance-overview',
        active: this.isActivePath('/report/performance-overview') },
    ];
    const {user} = this.props;
    return (
      <div className="flex flex-column fill">
        <div>
          <TabsRow className="border-color-gray-lightest border-bottom px2" large tabs={tabs}/>
        </div>
        <div className="flex flex-auto">
          <Switch>
            <PrivateRoute path="/report" exact component={ReportSection} user={user}/>
            <PrivateRoute path="/report/products" component={ProductReportSection} user={user}/>
            <PrivateRoute path="/report/performance-overview" component={PerformanceOverviewSection} user={this.props.user} />
            <Route component={NoMatchRoute} homeRoute="/"/>
          </Switch>
        </div>
      </div>
    );
  }
}

ReportApp.propTypes = {
  user: schema.ExternalUser,
  location: schema.RouterLocation.isRequired,
};
