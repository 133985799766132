import {chainableValidator} from './_utils';

const UUID_RE = /([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}?)/i;

/**
 * Validator to check that value is a valid UUID.
 *
 * @param  {Object} props
 * @param  {string} propName
 * @param  {string} componentName
 * @param  {...Array<any>} rest
 * @return {Error|null}
 */
export default chainableValidator(
  function uuidValidator (props, propName, componentName) {
    const value = props[propName];
    if (typeof value !== 'string') {
      return new Error(
        `Expected prop \`${propName}\` in \`${componentName || 'ANONYMOUS'}\` to be a string but got \`${value}\``
      );
    } else if (!UUID_RE.test(value)) {
      return new Error(
        `Expected prop \`${propName}\` in \`${componentName || 'ANONYMOUS'}\` to be a UUID (\`${UUID_RE.toString()}\`) but got \`${value}\``
      );
    }
    return null;
  }
);
