import {BrowserRouter as Router, Redirect, Switch} from 'react-router-dom';
import React, {PureComponent} from 'react';
import {
  Route,
  ExternalPrivateRoute as PrivateRoute,
  AnalyticsRoute,
  SUPPORTS_HISTORY,
} from '../../components/routing';
import {ExternalAppWrapper} from '../../components/AppWrapper';
import NoMatchRoute from '../../components/views/NotFound';
import LoginRoute from '../../components/views/LoginRoute';
import {AuthConsumer, partnerLogin as login} from '../../auth';
import ReportApp from './report';
import CpcBiddingApp from './cpc-bidding';
import LuxuryLoft from './lofts/LuxuryLoft';
import PremiumLoft from './lofts/PremiumLoft';
import {LUXURY_LOFT_TYPE, PREMIUM_LOFT_TYPE} from '../../utils/constants';


export default class App extends PureComponent {

  navigationLinks = (user) => {
    const tabs = [
      { path: '/report', text: 'Reports' },
      { path: '/cpc-bidding', text: 'CPC Bidding' },
    ];

    if (user && user.features && user.features.luxury_loft) {
      tabs.push({ path: '/luxury-loft', text: 'Luxury Loft' });
    } else if (user && user.features && user.features.premium_loft) {
      tabs.push({ path: '/premium-loft', text: 'Premium Loft' });
    }

    return tabs;
  };

  renderLoft = (auth) => {
    if (auth.user.features.luxury_loft) {
      return <PrivateRoute path="/luxury-loft" component={LuxuryLoft} user={auth.user} loftType={LUXURY_LOFT_TYPE}/>;
    } else if (auth.user.features.premium_loft) {
      return <PrivateRoute path="/premium-loft" component={PremiumLoft} user={auth.user} loftType={PREMIUM_LOFT_TYPE}/>;
    }
    return null;
  }

  render() {
    return (
      <AuthConsumer>
        {(auth) => (
          <Router forceRefresh={!SUPPORTS_HISTORY} basename="/">
            <ExternalAppWrapper user={auth.user} navLinks={this.navigationLinks(auth.user)}>
              <AnalyticsRoute />
              <Switch>
                <PrivateRoute exact path="/" component={Index} user={auth.user} />
                <Route path="/login" component={LoginRoute} {...auth} onLogin={login} />
                <PrivateRoute path="/report" component={ReportApp} user={auth.user} />
                <PrivateRoute path="/cpc-bidding" component={CpcBiddingApp} user={auth.user} />
                  {(auth.user && auth.user.features)
                    ? this.renderLoft(auth)
                    : null
                  }
                <Route component={NoMatchRoute} homeRoute="/" />
              </Switch>
            </ExternalAppWrapper>
          </Router>
        )}
      </AuthConsumer>
    );
  }
}

function Index() {
  return <Redirect from="/" to="/report" />;
}
