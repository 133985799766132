import {chainableValidator} from './_utils';

/**
 * Validator to check that value can be parsed as an integer.
 *
 * @param  {Object} props
 * @param  {string} propName
 * @param  {string} componentName
 * @param  {...Array<any>} rest
 * @return {Error|null}
 */
export default chainableValidator(
  function integerValidator (props, propName, componentName) {
    const value = props[propName];
    const intValue = parseInt(value, 10);
    const floatValue = parseFloat(value);
    if (isNaN(intValue) || intValue !== floatValue) {
      return new Error(
        `Expected prop \`${propName}\` in \`${componentName || 'ANONYMOUS'}\` to be an integer but got \`${value}\` instead.`
      );
    }
    return null;
  }
);
