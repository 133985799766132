import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {Button, Spinner} from '.';

export default class ActionButton extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      err: null,
    };
  }

  onClick = () => {
    this.setState({loading: true, err: null});
    this.props.action().then(
      () => this.setState({loading: false, err: null})
    ).catch(
      (err) => this.setState({loading: false, err: err}),
    );
  }

  label = () => {
    if (this.state.err) {
      return 'Something went wrong. Click to retry.';
    } else if (this.state.loading) {
      return <span>Working <Spinner/></span>;
    } else {
      return this.props.label;
    }
  }

  render() {
    // eslint-disable-next-line
    const {action, level, label, ...rest} = this.props;
    return (
      <Button
        {...rest}
        level={this.state.err ? 'danger' : this.props.level}
        onClick={this.onClick}
        disabled={this.state.loading}
      >
        {this.label()}
      </Button>
    );
  }
}

ActionButton.propTypes = {
  action: PropTypes.func.isRequired,
  level: Button.propTypes.level,
  label: PropTypes.node.isRequired,
};
