/** All renderers relevant to CPC category cpcs. */
import React from 'react';
import PropTypes from 'prop-types';
import _groupBy from 'lodash/groupBy';

import * as schema from '../../../../components/prop-types/schema';
import Modal from '../../../../components/Modal';

import {
  isActiveContract,
  isPendingContract,
  Label,
  renderValidFrom,
  renderValidTo,
} from '../utils';

import CategoryCPCSDetails from './Details';

const countBids = cpcs => Object.keys(_groupBy(cpcs, 'tag_id')).length;

const LABELS = {
  expired: <Label color={Label.grey} text={'Expired'}/>,
  active: <Label color={Label.green} text={'Active'}/>,
  pending: <Label color={Label.blue} text={'Pending'}/>,
};

export default class CategoryCpcHistory extends React.Component {

  state = {
    openEntry: null,
  }

  toggle = evt => {
    if (evt) {
      evt.preventDefault();
      const target = parseInt(evt.currentTarget.getAttribute('data-id'), 10);
      this.setState({ openEntry: target });
    } else {
      this.setState({ openEntry: null });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.history.map(entry => {
          const active = isActiveContract(entry);
          const pending = isPendingContract(entry);
          return (
            <div className="p1 flex justify-between items-center hover-bg-gray-muted" key={entry.id}>
              <Modal onDismiss={this.toggle} open={this.state.openEntry === entry.id} dismissOnEsc>
                <div className="rounded bg-white layer-8 border-color-gray-lighter">
                <CategoryCPCSDetails entry={entry} categories={this.props.categories}/>
                </div>
              </Modal>
              <div style={{minWidth: '17rem'}}>
                {renderValidFrom(entry)}
                {!(active || pending) && <div>Ended: {renderValidTo(entry)}</div>}
              </div>
              <div>
                {countBids(entry.prices)} active bids
              </div>
              <a data-id={entry.id} className="inline-block" href="#" onClick={this.toggle}>Details</a>
              <div className="center" style={{minWidth: '5rem'}}>
                {(active && LABELS.active) || (pending && LABELS.pending) || LABELS.expired}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

CategoryCpcHistory.propTypes = {
  history: PropTypes.arrayOf(schema.CategoryCPCCollection).isRequired,
  categories: PropTypes.arrayOf(schema.CategoryFolder).isRequired,
};
