import React from 'react';
import PropTypes from 'prop-types';

export default function StylightLogo (props) {
  // Original file can be found in /public/logo/stylight_*.png.
  const {small, ...rest} = props;
  if (small) {
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
           x="0px" y="0px" viewBox="105 105 68 105" xmlSpace="preserve" {...rest}>
        <g>
          <polygon points="106.1,208.7 130.5,208.7 106.1,187.9"/>
          <path d="M162.6,158.8l-33.3-31.2l0,0c-0.2-0.2-4.1-3.6-4.1-8.9c0-7,6.2-12.6,13.9-12.6h0.9v-1.7h-4.3 c-17.3,0-31.3,11.6-31.3,26c0,6.2,2.6,11.9,7,16.3v0c0,0,33.3,31.2,33.4,31.4c3,3,4.8,7.4,4.8,12.2c0,9.2-6.8,16.7-15.2,16.7h-1.3 v1.7h5.2c18.7,0,33.9-13.2,33.9-29.4C172.2,171.4,168.5,164.1,162.6,158.8z"/>
          <polygon points="167,104.4 142.7,104.4 167,125.2"/>
        </g>
      </svg>
    );
  } else {
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
           x="0px" y="0px" viewBox="105 101 469 143" xmlSpace="preserve" {...rest}>
        <g>
          <polygon points="301.1,207 322,207 322,100.9 301.1,106.1"/>
          <rect x="335.9" y="137.4" width="20.9" height="69.6"/>
          <circle cx="346.3" cy="116.5" r="12.2"/>
          <polygon points="436.8,146.1 436.8,120 411.9,133.1"/>
          <polygon points="445.6,207.2 466.4,207.2 466.4,101.1 445.6,106.3"/>
          <path d="M492.1,135c-18.7,0-22,11.2-23.1,13.5v2.2c2.7-3.3,7.1-6.2,13.1-6.2c9.7,0,12.2,6.7,12.2,12.2 l0,50.4h20.9l0-48.7C515.1,152.9,514.4,135,492.1,135z"/>
          <path d="M571.9,195.8c0,0-10.8,8-18.2,0.9c-2.8-2.7-3.7-8.3-3.7-13.2V140h20.9l-1.2-2.6l0,0H550v-20.9 l-20.9,5.2v15.7h-9.6l0,0l1.2,2.6h8.4v46.1c0,9,2.3,22.6,21,22.6c18.6,0,23.1-11.6,23.1-11.6L571.9,195.8z"/>
          <path d="M207.3,196.7c-2.8-2.7-3.7-8.3-3.7-13.2V140h18.7l-1.2-2.6h-17.5v-20.9l-20.9,5.2v15.7h-9.5 l1.2,2.6h8.3v46.1c0,9,2.3,22.6,21,22.6c18.6,0,23.1-11.6,23.1-11.6l-1.2-1.3C225.5,195.8,214.7,203.8,207.3,196.7z"/>
          <path d="M398.5,184.4c16.3,0,29.6-10.9,29.6-24.4s-13.2-24.4-29.6-24.4c-16.3,0-29.6,10.9-29.6,24.4 S382.2,184.4,398.5,184.4z M391.6,145.5c0-0.4,1.1-6.3,6.9-6.3c5.8,0,6.9,5.9,6.9,6.3c0.6,3.8,0.7,8.9,0.7,14.6 c0,5.7-0.1,10.8-0.7,14.6c0,0.4-1.1,6.3-6.9,6.3c-5.8,0-6.9-5.9-6.9-6.3c-0.6-3.8-0.7-8.9-0.7-14.6 C390.9,154.4,391,149.2,391.6,145.5z"/>
          <path d="M413.3,194.8c-0.1,0-32.1,0-32.1,0c-3.4,0-6.1-2.7-6.1-6.1c0-3,2.4-5.3,4.8-6.6l-1.4-0.9 c-6.3,2.7-11.2,9.3-11.2,16.7c0,5.5,2.5,10.5,6.5,13.7c-6.1,3.3-9.9,8.2-9.9,13.7c0,9.8,11.4,18.3,35.5,18.3 c27.9,0,37.5-11.5,37.5-25.2C436.8,205.4,426.2,194.8,413.3,194.8z M401,241c-10.6,0-23.4-4.5-23.4-14.9c0-4.1,1.4-7.8,3.8-10.7 c1.2,0.2,2.4,0.4,3.7,0.4h29.1c5.8,0,10.4,4.7,10.4,10.4C424.6,239.9,405.1,241,401,241z"/>
          <polygon points="224.6,137.4 257.6,210.1 271.2,184.2 248.9,137.4"/>
          <polygon points="282.8,162.3 295.9,137.4 269.8,137.4"/>
          <circle cx="240.2" cy="227.9" r="12.2"/>
          <polygon points="106.1,208.7 130.5,208.7 106.1,187.9"/>
          <path d="M162.6,158.8l-33.3-31.2l0,0c-0.2-0.2-4.1-3.6-4.1-8.9c0-7,6.2-12.6,13.9-12.6h0.9v-1.7h-4.3 c-17.3,0-31.3,11.6-31.3,26c0,6.2,2.6,11.9,7,16.3v0c0,0,33.3,31.2,33.4,31.4c3,3,4.8,7.4,4.8,12.2c0,9.2-6.8,16.7-15.2,16.7h-1.3 v1.7h5.2c18.7,0,33.9-13.2,33.9-29.4C172.2,171.4,168.5,164.1,162.6,158.8z"/>
          <polygon points="167,104.4 142.7,104.4 167,125.2"/>
        </g>
      </svg>
    );
  }
}

StylightLogo.propTypes = {
  small: PropTypes.bool,
};
