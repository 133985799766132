/**
 * Run-time type checkers for React components.
 */

import conditional from './conditional';
import constant from './constant';
import email from './email';
import forbidden from './forbidden';
import integer from './integer';
import regex from './regex';
import tuple from './tuple';
import uuid from './uuid';

import {
  requiredIfValidatorFactory as requiredIf,
  requiredUnlessValidatorFactory as requiredUnless,
} from './required-with-predicates';

import {
  chainValidators as chain,
  lazyValidator as lazy,
} from './_utils';

export const _export = {};

if (process.env.NODE_ENV === 'production') {
  // Ensures that this behaves consistently with standard 'prop-types' module
  // and doesn't run type checks in production build.

  const mock = () => null;
  mock.isRequired = () => null;

  const mockDecorator = () => mock;

  _export.conditional = mockDecorator;
  _export.chain = mockDecorator;
  _export.constant = mockDecorator;
  _export.email = mock;
  _export.forbidden = mockDecorator;
  _export.integer = mock;
  _export.lazy = mockDecorator;
  _export.regex = mockDecorator;
  _export.requiredIf = mockDecorator;
  _export.requiredUnless = mockDecorator;
  _export.tuple = mockDecorator;
  _export.uuid = mock;
} else {
  _export.conditional = conditional;
  _export.chain = chain;
  _export.constant = constant;
  _export.email = email;
  _export.forbidden = forbidden;
  _export.integer = integer;
  _export.lazy = lazy;
  _export.regex = regex;
  _export.requiredIf = requiredIf;
  _export.requiredUnless = requiredUnless;
  _export.tuple = tuple;
  _export.uuid = uuid;
}

_export.__propTypesProductionMask = (propTypes) => {
  if (process.env.NODE_ENV === 'production') {
    const mock = () => null;
    mock.isRequired = () => null;
    return Object.keys(propTypes).reduce((mask, key) => {
      return Object.assign(mask, {[key]: mock});
    }, {});
  } else {
    return propTypes;
  }
};

export default _export;
