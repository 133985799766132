import {connect} from 'react-refetch';
import config from '../../../config';
import LoftHandler from './LoftHandler';

// export LoftHandler component to be used in the application. Normally this would have been exported
// inside the file itself. But in this way we separate the logic from `connect` and isolate the
// LoftHandler class to be testable.

export default connect(() => ({
  fetchImages: `${config().apiUrl}/premium-loft`,
}))(LoftHandler);
