/**
 * Date manipulation utilities.
 *
 * [WARN] This was written to avoid pulling a (huge) lib like momentjs
 * by now, we are also using https://github.com/date-fns/date-fns which
 * supports partial imports like lodash and should be preferred so
 * this module can be phased out slowly.
 */

/**
 * Returns a new date with the given time
 *
 * @param {Date | String} date
 * @param {number} hours
 * @param {number} minutes
 * @param {number} seconds
 * @param {Date} ms
 * @return {Date} new Date object
 */
export function setTime(date, hours, minutes, seconds, ms) {
  const d = new Date(date);
  d.setHours(hours);
  d.setMinutes(minutes);
  d.setSeconds(seconds);
  d.setMilliseconds(ms);
  return d;
}

const UNIT_VALUES = {
  seconds: 1,
  minutes: 60,
  hours: 3600,
  days: 86400,
  weeks: 604800,
  months: 2628000,
  years: 31536000,
};

const UNITS = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];

function singularise(value, unit) {
  return value > 1 ? unit : unit.slice(0, -1);
}

/**
 * Convert a timestamp (seconds) to human readable description
 * @param  {number} timestamp
 * @param  {{
 *   max: 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds',
 *   min: 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds',
 * }} opts
 * @return {string}
 */
export function humanise(timestamp, opts = {}) {
  const result = {};
  let remaining = timestamp;

  const toUnit = UNITS.indexOf(opts.max || null);
  const fromUnit = UNITS.indexOf(opts.min || null);
  const keepOnly = opts.keepOnly || UNITS.length;

  UNITS.forEach((unit, index) => {

    if (
      // Before toUnit
      (toUnit > -1 && index < toUnit) ||
      // After fromUnit while something is there to avoid null results
      (fromUnit > -1 && index > fromUnit && remaining !== timestamp)
    ) {
      return;
    }


    const value = UNIT_VALUES[unit];
    if (value <= remaining) {
      result[unit] = Math.floor(remaining / value);
      remaining = remaining % value;
    }
  });

  return (
    UNITS
    .filter(u => result[u])
    .slice(0, keepOnly)
    .map(u => `${result[u]} ${singularise(result[u], u)}`)
    .join(' ')
  );
}


/**
 * @param  {Date} date
 * @param  {Date} reference
 * @param  {Object} opts - options for humanise
 * @return {string}
 */
export function naturalTimeDiff(date, reference, opts) {
  const ts = date.getTime();
  const refTs = reference ? reference.getTime() : Date.now();
  const delta = Math.abs(Math.floor((refTs - ts) / 1000));
  if (delta === 0) {
    return 'now';
  } else if (ts > refTs) {
    return `in ${humanise(delta, opts)}`;
  } else {
    return `${humanise(delta, opts)} ago`;
  }
}

/**
 * `Converts` a date to its utc equivalent ISO string regardless of timezone.
 *
 * [WARN] All dates are tracked as UTC timstamps, so this doesn't really
 * convert a date but rather sets the timestamp to work as expected.
 * So if input is new 'Mon May 08 2017 11:36:37 GMT+0200 (CEST)' (ISO:
 * `2017-05-08T09:36:37.000Z`), `toUTCISOString` will use
 * `Mon May 08 2017 13:36:37 GMT+0200 (CEST)` to convert to string, which is
 * ISO: `2017-05-08T11:36:37.000Z`.
 *
 * This is mostly useful across application boundaries where the expectation
 * is that user interaction is timezone-naive and the server requires UTC.
 *
 * @param {Date}
 * @return {string}
 */
export function toNaiveISOString(date) {
  const d = new Date(date);
  d.setTime(d.getTime() + (-1 * 1000 * 60 * d.getTimezoneOffset()));
  return d.toISOString();
}

