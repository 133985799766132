/**
 * Simple global state management: bypass the limitation of React's context for
 * passing state to deeply nested parts of the tree without the cognitive
 * and/or performance overhead of Redux / Mobx and similar solutions.
 *
 * This is a variation on patterns seen in Elm, Redux, etc. with the
 * following differences:
 *
 * - State is not a single global atom in order to make things more explicit,
 *   this way multiple state stores can cohabit without interfering with each
 *   other. This makes it easier to reason about the update path and get
 *   predictable performance.
 *
 * - Actions are not serialisable by design and are just pure functions, in
 *   essence merging the reducer and action concepts into one.
 *
 * You should be able to re-implement Redux with it and conversely implement
 * this with Redux as well, it really is more to make the use case explicit.
 *
 * This is also similar to https://github.com/ReactTraining/react-broadcast
 * however it provides a mechanism for updating from the Consumers and not from
 * the Provider's parent.
 *
 * See tests for usage examples.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Create a pair of React component that communicate across components
 * boundaries. The `Provider` must be used only once per tree and holds the
 * data that can be consumed by multiple `Consumers` in its sub-tree.
 *
 * @param {string} channel
 *        Unique name for the provider / consumer pair
 * @param {T:any} init
 *        Initial state for the channel
 * @param {{[key: string]: (state: T, ...args: any[]) => T | Promise<T>}} actions
 *        Optionnal set of mutator functions that always receive the current
 *        state as first argument.
 * @return {{Provider: ReactComponent, Consumer: ReactComponent}}
 */
export function createProviderConsumerPair(channel, init, defaultActions = {}) {

  const consumerContextTypes = {
    subscribe: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
    actions: PropTypes.shape(Object.keys(defaultActions).reduce((map, name) => {
      return {...map, [name]: PropTypes.func.isRequired};
    }, {})),
  };

  const childContextTypes = {
    channels: PropTypes.shape({
      [channel]: PropTypes.shape(consumerContextTypes).isRequired,
    }).isRequired,
  };

  /**
   * Provider: the state container to which Consumer's subscribe to.
   *
   * [WARN] Only one can exist per tree for a given channel.
   * [TODO] Implement logging / debugging mode.
   */
  class Provider extends React.PureComponent {

    constructor(...args) {
      super(...args);
      this.observable = createObservableState(init);
      this.actions = bindActions(this.observable, {...defaultActions, ...this.props.actions});
    }

    // Use of shared context idiom to make sure only one provider per channel
    // is present and avoid conflicts.
    getChildContext() {
      const channels = (this.context || {}).channels || {};
      if (channel in channels && process.env.NODE_ENV !== 'production') {
        // [WARN] Throwing in here breaks everything, not super sure as to why
        // but for now warnings is the best I could do.
        // eslint-disable-next-line no-console
        console.warn(`There is already a ${channel} Provider in the tree.`);
      }
      return {
        channels: {
          ...channels,
          [channel]: {
            subscribe: this.observable.subscribe,
            getState: this.observable.getState,
            actions: this.actions,
          },
        },
      };
    }

    render() { return this.props.children; }
  }

  Provider.propTypes = {
    children: PropTypes.node,
    actions: PropTypes.objectOf(PropTypes.func),
  };

  Provider.defaultProps = {
    actions: {},
  };

  Provider.childContextTypes = childContextTypes;

  Provider.contextTypes = {
    channels: PropTypes.objectOf(PropTypes.shape(consumerContextTypes)),
  };

  /**
   * Consumer components can be used as many times as needed in a tree as long
   * as a `Provider` for this channel exists. The context propTypes will catch
   * usage when not in wrapped by a corresponding Provider.
   * Use the `select` prop to create specialised consumers that do not
   * necessarilly update on all state changes.
   *
   * [TODO] Is `PureComponent` ok ? Could an internal check in onChange be used
   * to gain performance / limit wasted cycles.
   * [TODO] Implement logging / debugging mode.
   */
  class Consumer extends React.PureComponent {

    constructor(...args) {
      super(...args);
      this.state = { context: this.channel().getState() };
      this.onChange = this.onChange.bind(this);
    }

    channel() {
      return ((this.context || {}).channels || {})[channel];
    }

    componentDidMount() {
      this.unsubscribe = this.channel().subscribe(this.onChange);
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    onChange(nextState) {
      return this.setState({context: nextState});
    }

    render() { return this.props.children(this.state.context, this.channel().actions); }
  }

  Consumer.contextTypes = childContextTypes;

  Consumer.propTypes = {
    children: PropTypes.func.isRequired,
    select: PropTypes.func,
  };

  Consumer.defaultProps = {
    select: x => x,
  };

  return {Provider, Consumer};
}

/**
 * Create an observable state container similar to a Redux store.
 * `dispatch` supports async flows by default in the form of thunks (callback
 * that receives `dispatch` as an argument) or Promise that resolve to the next
 * state.
 *
 * @param {T:any} initialState
 * @return {{
 *   getState: () => T,
     subscribe: (consumer: (state: T) => void) => () => void,
     dispatch: (payload: T |
                         Promise<T> |
                         ((state: T, dispatch: (nextState: T) => void) => void)) => void
 * }}
 */
export function createObservableState(initialState) {
  let currentState = initialState;

  const consumers = {};
  let consumersCount = 0;

  const getState = () => currentState;

  const subscribe = consumer => {
    const id = consumersCount++;
    consumers[id] = consumer;
    setTimeout(() => consumer(currentState), 0);
    return () => delete consumers[id];
  };

  const notify = () => Object.keys(consumers).forEach(
    id => setTimeout(() => consumers[id](currentState), 0)
  );

  const dispatch = payload => {
    if (payload instanceof Promise) { // Who likes callbacks ?
      payload.then(data => dispatch(data));
    } else if (typeof payload === 'function') { // Like redux-thunk
      payload(dispatch);
    } else {
      currentState = payload;
      notify();
    }
  };

  return { getState, subscribe, dispatch };
}

function bindActions(store, actions) {
  return Object.keys(actions).reduce((map, name) => {
    const original = actions[name];
    return {
      ...map,
      [name]: (...args) => store.dispatch(original(store.getState(), ...args)),
    };
  }, {});
}
