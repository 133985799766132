import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import _setWith from 'lodash/setWith';
import _isPlainObject from 'lodash/isPlainObject';

export const omit = _omit;
export const pick = _pick;
export const deepEqual = _isEqual;
export const setNestedProperty = _setWith;
export const isObject = _isPlainObject;

/**
 * Shallow equality check.
 *
 * @param {object} obj1
 * @param {object} obj2
 * @returns {boolean}
 */
export function shallowEqual(obj1, obj2) {
  if (obj1 === obj2) return true;
  if (obj1 == null || obj2 == null) return false;
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

  // eslint-disable-next-line no-restricted-syntax
  for (const i in obj1) {
    if (!(i in obj2)) {
      return false;
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const i in obj2) {
    if (obj1[i] !== obj2[i]) {
      return false;
    }
  }

  return true;
}

/**
 * Returns the value for a nested in an object.
 * Silently returns undefined on bad nesting.
 *
 * @param  {?Object} obj
 * @param  {?string} key
 * @return {any}
 */
export function getNestedProperty(obj, key) {
  if (!key || !obj || typeof obj !== 'object') {
    return undefined;
  }

  let head = key;
  let tail = [];

  if (key.indexOf('.') > -1) {
    [head, ...tail] = key.split('.');
  }

  if (!obj.hasOwnProperty(head)) {
    return undefined;
  }

  if (tail.length > 0) {
    return getNestedProperty(obj[head], tail.join('.'));
  } else {
    return obj[head];
  }
}

/**
 * Returns whether or not a nested key exist in an object.
 * Silently returns false on bad nesting.
 *
 * @param  {?Object} obj
 * @param  {?string} key
 * @return {boolean}
 */
export function hasNestedProperty(obj, key) {
  return getNestedProperty(obj, key) !== undefined;
}
