import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import classname from '../utils/classname';

export default class Collapse extends PureComponent {

  static propTypes = {
    open: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func,
    ]).isRequired,
    className: PropTypes.string, // Classname for the wrapper div
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object, // Styles for the wrapper div.
  }

  constructor(props) {
    super(props);
    this.state = {
      // Track whether or not the children were rendered once or not for
      // lazy render (children don't touch DOM if starts closed) and DOM reuse
      // (children are not trashed when closed after open) which helps when
      // children is DOM heavy.
      openOnce: !!this.props.open,
    };
  }

  render() {
    const cls = classname(
      'collapse',
      {'is-open': this.props.open},
      this.props.className
    );

    return (
      <div className={cls} style={this.props.style}>
        <div className={classname({'display-none': !this.props.open})}>
          {(this.props.open || this.state.openOnce) && this.props.children}
        </div>
      </div>
    );
  }
}
