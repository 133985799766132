import React from 'react';

import config from '../../config';

export default function EnvBanner() {
  if (process.env.NODE_ENV === 'production') return null;
  return (
    <div className="px1 py1 color-white bg-warning" style={{
      fontSize: '.8em',
      pointerEvents: 'none',
    }}>
      <div className="inline-block mx1">
        <span className="medium">BUILD</span>: {process.env.NODE_ENV}
      </div>
      <div className="inline-block mx1">
        <span className="medium">ENV</span>: {config().environment}
      </div>
      <div className="inline-block mx1">
        <span className="medium">SHA</span>: {process.env.GIT_SHA}
      </div>
      <div className="inline-block mx1">
        <span className="medium">VERSION</span>: {process.env.VERSION}
      </div>
      <div className="inline-block mx1">
        <span className="medium">TIMESTAMP</span>: {new Date(parseInt(process.env.BUILD_TIMESTAMP, 10)).toISOString()}
      </div>
    </div>
  );
}
