import React from 'react';
import PropTypes from 'prop-types';

import * as utils from '../utils';

export default function Radio (props) {
  const id = props.id || `radio#${utils.strings.uuid4()}`;

  const cls = utils.classname('custom-radio', {
    'is-disabled': props.disabled,
    'is-read-only': props.readOnly,
    'is-inline': props.inline,
  }, props.className);

  return (
    <div className={cls} style={props.style}>
      <input
        checked={props.checked}
        type="radio"
        name={props.name || id}
        id={id}
        disabled={props.disabled}
        readOnly={props.readOnly}
        onChange={props.onChange}
        {...utils.objects.omit(props, Object.keys(Radio.propTypes))}
      />
      <label htmlFor={id} className={cls}>{props.label}</label>
    </div>
  );
}

Radio.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  style: PropTypes.object,  // eslint-disable-line react/forbid-prop-types
};

