import React from 'react';
import PropTypes from 'prop-types';

import {omit} from '../utils/objects';
import classname from '../utils/classname';

export default function Icon(props) {
  return (
    <i
      className={classname(
        'icon', {
          [`icon-${props.size}`]: props.size,
        },
        props.className
      )}
      {...omit(props, Object.keys(Icon.propTypes))}
    >
      {props.text}
    </i>
  );
}

Icon.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'mini']),
};
