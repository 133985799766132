import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import _omit from 'lodash/omit';

import createDetectElementResize from '../utils/element-resize';
import {dom as _dom, classname} from '../utils';

let ELEMENT_RESIZE;
const RESIZE_LISTENER_WRAPPER_CLS = `__RESIZE_LISTENER_WRAPPER__`;

export default class ResizeListener extends PureComponent {

  node = null;
  parent = null;

  componentDidMount() {
    if (!ELEMENT_RESIZE) ELEMENT_RESIZE = createDetectElementResize();
    this.parent = this.node.parentNode;
    ELEMENT_RESIZE.addResizeListener(this.parent, this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    ELEMENT_RESIZE.removeResizeListener(this.parent, this.onResize);
  }

  ref = node => { this.node = node; }

  onResize = () => {
    this.props.onResize(this.node);
  };

  render() {
    const {className, children, style} = this.props;
    const cls = classname(RESIZE_LISTENER_WRAPPER_CLS, className);
    return (
      <div ref={this.ref} className={cls} style={style}>
        {children}
      </div>
    );
  }
}

ResizeListener.propTypes = {
  children: PropTypes.node,
  /** Callback on resize, signature: (HtmlElement) => void */
  onResize: PropTypes.func.isRequired,
  /* Use `style` and `className` to control resize wrapper behaviour. This may
     interact with the resize event and node's dimensions. */
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
};


/**
 * Component extracting the size of it's children (a wrapper div to be exact)
 * and reacting to resize event.
 *
 * Based on `react-virtualized.AutoSizer`, using a port of the nice
 * https://github.com/sdecima/javascript-detect-element-resize for resize
 * detection.
 *
 * [NOTE] The root div of this component should be free flowing, hence we do not
 * so the caller need to be careful with CSS and its effect on this element
 * dimensions. (tldr; use with care). Use the `style` / `className` props to
 * control the flow-behaviour of the measured div.
 */
export class Measurer extends PureComponent {

  rawOnResize = node => this.props.onResize(_dom.outerSize(node));

  // Getting the size can be expensive, hence a slight throttling helps keeping
  // things smooth.
  onResize = _debounce(
    this.rawOnResize, 10, {leading: true, trailing: true, maxWait: 50}
  );

  render() {
    const {children, disableThrottling} = this.props;
    const rest = _omit(this.props, ['children', 'onResize', 'disableThrottling']);
    const onResize = disableThrottling ? this.rawOnResize : this.onResize;
    return (
      <ResizeListener onResize={onResize} {...rest}>
        {children}
      </ResizeListener>
    );
  }
}

Measurer.propTypes = {
  children: PropTypes.node,
  /** Callback for extracting width and height of element.
  Will be called on initial mount as well.
  Signature: ({height: number, width: number}) => void */
  onResize: PropTypes.func.isRequired,
  disableThrottling: PropTypes.bool,
};
