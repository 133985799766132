/**
 * Helpers to work with ReactRouter
 */
import React from 'react';
import ReactGA from 'react-ga';
import * as ReactRouter from 'react-router-dom';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

import * as schema from './prop-types/schema';
import {purify} from './helpers';

const REACT_ROUTER_ROUTE_PROP_TYPES = Object.keys(ReactRouter.Route.propTypes);

export const SUPPORTS_HISTORY = 'history' in window && 'pushState' in window.history;

/**
 * Wrapper on the standard `ReactRouter.Route` that propagtes the props passed
 * to it to the `component` prop in order to be able to define routes
 * without nested functions.
 *
 * Force `render` prop of `ReactRouter.Route` to avoid unnecessary re-renders.
 * See https://reacttraining.com/react-router/web/api/Route/render-func.
 *
 * Replaces:
 *
 *  function (props) {
 *    return <Route component={(routeProps) => <Component {...routeProps} {...props}/>};
 *  }
 *
 * With:
 *
 *  function (props) {
 *    return <Route component={Component} {...props}/>;
 *  }
 *
 */
export const Route = purify(function Route(props) {
  const {component: Component, ...rest} = props;
  return (
    <ReactRouter.Route {...rest} render={(matchProps) => {
      const passThrough = _omit(rest, REACT_ROUTER_ROUTE_PROP_TYPES);
      return <Component {...passThrough} {...matchProps}/>;
    }}/>
  );
});

Route.propTypes = {
  component: PropTypes.func.isRequired,
};

/**
 * Same as `Route` but with added exit path in case user is not logged in or
 * of the wrong account type.
 */
export const PrivateRoute = purify(function PrivateRoute(props) {
  const {user, component: Component, ...rest} = props;
  return (
    <ReactRouter.Route {...rest} render={(matchProps) => {
      if (!user) {
        const {pathname, search} = matchProps.location;
        const redirect = encodeURIComponent(`/${pathname}${search}`.replace(/\/\//g, '/'));
        const login = `/${props.loginPath}?redirect=${redirect}`.replace(/\/\//g, '/');
        return <ReactRouter.Redirect to={login}/>;
      } else if (props.wrongUserTypeRedirect && user.type !== props.allowedUserType) {
        // Hard exit, don't worry about rendering errors
        window.location = props.wrongUserTypeRedirect;
        return null;
      } else {
        const passThrough = _omit(rest, REACT_ROUTER_ROUTE_PROP_TYPES);
        return <Component user={user} {...passThrough} {...matchProps}/>;
      }
    }}/>
  );
});

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  user: schema.User,
  allowedUserType: PropTypes.oneOf(schema.USER_TYPES).isRequired,
  wrongUserTypeRedirect: PropTypes.string,
  loginPath: PropTypes.string.isRequired,
};

PrivateRoute.InternalDefaults = {
  allowedUserType: 'stylight_employee',
  wrongUserTypeRedirect: '/',
  loginPath: '/login',
};

PrivateRoute.ExternalDefaults = {
  allowedUserType: 'stylight_customer',
  wrongUserTypeRedirect: '/internal',
  loginPath: '/login',
};

export const InternalPrivateRoute =
  props => <PrivateRoute {...props} {...PrivateRoute.InternalDefaults}/>;

export const ExternalPrivateRoute =
  props => <PrivateRoute {...props} {...PrivateRoute.ExternalDefaults}/>;

/** ReactGA doesn't expose anything like this... */
export function isGALoaded() {
  return window.ga !== undefined;
}

/**
 * Simple non rendered component used to track pageviews to GA.
 */
export class AnalyticsRoute extends React.Component {
  render() {
    // Will render everytime the router context changes
    return <ReactRouter.Route path="/" component={() => {
      if (isGALoaded()) {
        this.props.ga.set({page: window.location.pathname + window.location.search});
        this.props.ga.pageview(window.location.pathname + window.location.search);
      }
      return null;
    }}/>;
  }
}

AnalyticsRoute.propTypes = {
  ga: PropTypes.shape({
    set: PropTypes.func.isRequired,
    pageview: PropTypes.func.isRequired,
  }).isRequired,
};

AnalyticsRoute.defaultProps = {
  ga: ReactGA,
};
