import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import * as schema from '../../../prop-types/schema';
import * as _func from '../../../../utils/func';

import {TreeSelector, filterByName} from './TreeSelector';

const formatCampaignFolders = _func.memoize(folders => (folders || []).map(
  folder => ({
    ...folder,
    items: folder.campaigns || [],
    subfolders: formatCampaignFolders(folder.subfolders || []),
  })
));

const itemLabel = campaign => `${campaign.name} (${campaign.id})`;
const folderLabel = folder => `${folder.name} (${folder.id})`;

/**
 * Full campaign filter.
 */
export default class CampaignSelector extends PureComponent {

  render() {
    return (
      <TreeSelector
        folders={formatCampaignFolders(this.props.campaignFolders)}
        selected={this.props.selected}
        onUpdateSelection={this.props.onUpdateSelection}
        renderItemLabel={itemLabel}
        renderFolderLabel={folderLabel}
        filterItems={filterByName}
        placeholder="Filter campaigns by name..."
        noEntryPlaceholder="There is no campaign matching your search."
      />
    );
  }
}

CampaignSelector.propTypes = {
  campaignFolders: PropTypes.arrayOf(schema.CampaignFolder).isRequired,
  onUpdateSelection: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(schema.Int).isRequired,
};
