import React from 'react';
import PropTypes from 'prop-types';
import {Route, Link} from 'react-router-dom';

import classname from '../../utils/classname';
import StylightLogo from '../StylightLogo';

export function MainNav(props) {
  return (
    <nav className="flex flex-auto">
      {props.links.map(link => <NavLink key={link.path} {...link}/>)}
      <ExternalLink path="/help-center/" text="Help Center"/>
    </nav>
  );
}

MainNav.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape(NavLink.propTypes)).isRequired,
};

export function ExternalLink(props) {
  return (
    <a
      className="text-decoration-none px2 flex items-center color-white transition-colors"
      href={props.path}
    >
      {props.text}
    </a>
  );
}

ExternalLink.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export function NavLink(props) {
  return (
    <Route path={props.path}>
      {({match}) => (
        <Link className={classname(
          'text-decoration-none px2 flex color-white items-center transition-colors',
          {
            'color-white medium disable-pointer-events regular': match,
          }
        )} to={props.path}>
          <div>
            {props.text}
          </div>
        </Link>
      )}
    </Route>
  );
}

NavLink.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export const LogoLink = (props) => {
  return (
    <a className="p2 color-white hover-color-white text-decoration-none flex items-center" href={props.homeRoute}>
        <StylightLogo className="inline-block" style={{
          fill: '#fff',
          height: '1.7rem',
          marginTop: '.1em',
          marginRight: '.5em',
        }}/>
        <div className="inline-block light h3">Partner Center</div>
      </a>
  );
};

LogoLink.propTypes = {
  homeRoute: PropTypes.string.isRequired,
};
