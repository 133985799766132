import React from 'react';
import PropTypes from 'prop-types';

import * as schema from '../../../components/prop-types/schema';
import {Alert} from '../../../components/Alert';

const PropertyIds = ({tableData, showNotification}) => {
    const isDataMissing = tableData.some((value) => {
        return (value.property_id === undefined || value.locale === undefined);
    });
    const numRows = tableData.length;

    const copy = (e, toBeCopied) => {
        if (document.queryCommandSupported('copy')) {
            e.stopPropagation();
            const text = toBeCopied;
            const textarea = document.createElement('textarea');
            const body = document.querySelector('body');

            textarea.value = text;
            textarea.style.opacity = 0;
            textarea.style.position = 'fixed';
            textarea.classList.add('to-be-copied');

            body.appendChild(textarea);
            textarea.focus();
            textarea.select();

            if (document.queryCommandEnabled('copy')) {
                document.execCommand('copy');
                showNotification();
            }
            body.removeChild(textarea);
        }
    };

    if (tableData.length > 0 && !isDataMissing) {
        return (
            <div>
                <div className="h4 max-width-3 pt2 mb3">
                    {
                        numRows === 1 &&
                        (<span>Here is your personal <span className="bold color-st-blue">Property ID </span>
                            to configure your tracking. More information can be found  <a href="https://partner.stylight.net/help-center/tutorials/how-to-implement-stylight-sales-tracking/"
                            target="_blank"
                            rel="noopener noreferrer">in the Help Center</a>
                        </span>)
                    }
                    {
                        numRows > 1 &&
                        (<span>Here is your list of personal <span className="bold color-st-blue">Property IDs </span>
                            distributed per country to configure your tracking.
                            More information can be found <a href="https://partner.stylight.net/help-center/tutorials/how-to-implement-stylight-sales-tracking/"
                            target="_blank"
                            rel="noopener noreferrer">in the Help Center</a>
                        </span>)
                    }
                </div>
                <table className="table min-width-2 fill">
                    <thead>
                        <tr>
                            <th scope="col">
                                Country
                            </th>
                            <th scope="col">
                                Property ID
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {row.locale}
                                        </td>
                                        <td onClick={() => copy(event, row.property_id)} className="cursor-pointer">
                                            {row.property_id}
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <Alert level="error" message="An error occured when fetching your Property IDs, please refresh this page or contact your Stylight Partner." show={true}/>
    );
};

PropertyIds.propTypes = {
    tableData: PropTypes.arrayOf(schema.PropertyIdTable).isRequired,
    showNotification: PropTypes.func,
};

export default PropertyIds;
