import React, {PureComponent} from 'react';
import Conveyor from 'react-conveyor';
import * as _dateFns from 'date-fns';

import * as schema from '../../../../components/prop-types/schema';
import * as staticData from '../../../../static-data';
import StateBuffer from '../../../../components/StateBuffer';
import QueryStringSync from '../../../../components/QueryStringSync';
import RedScreenOfDeath from '../../../../components/RedScreenOfDeath';
import LoadingState from '../../../../components/LoadingState';
import {serialize, deserialize} from '../../../../components/views/configuration-serializer';
import Report from './Report';
import Filters from './Filters';

const DEFAULT_CONFIGURATION = {
  toDate: _dateFns.startOfYesterday(),
  fromDate: _dateFns.startOfMonth(new Date()),
  selectedFilters: {
    shops: [],
    reportTypes: [],
  },
};
const CONVEYOR_CONF = staticData.conveyorConfiguration('enums');

export default class ProductReportSection extends PureComponent {

  render() {
    const shopId = this.props.user.meta.shop_id;
    return (
      <QueryStringSync
        defaultState={DEFAULT_CONFIGURATION}
        queryString={this.props.location.search}
        serialize={serialize}
        deserialize={deserialize}
      >
        { ({update: updateConfiguration, reset: _, ...currentConfiguration}) => (  // eslint-disable-line  no-unused-vars
          <Conveyor {...CONVEYOR_CONF}>
          { ({inFlight, missing, errors, shops, enums}) => {
            if (inFlight || missing) {
              return <LoadingState/>;
            } else if (errors) {
              return <RedScreenOfDeath className="flex-auto scroll-y" errors={errors}/>;
            } else {
              return (
                <div className="flex flex-1 flex-row fill">
                  <div className="p1 flex-1 scroll-y" style={{minWidth: 242}}>
                    <StateBuffer
                      defaultState={currentConfiguration}
                      onApply={updateConfiguration}
                    >
                      { ({state: configuration, update, apply}) => (
                        <Filters
                          onChange={update}
                          onApply={apply}
                          {...configuration}
                          shops={shops}
                          availableReportTypes={enums.productsReportReportTypes}
                        />
                      ) }
                    </StateBuffer>
                  </div>
                  <div className="p1 flex flex-column flex-4 scroll-x">
                    <Report {...currentConfiguration} shopId={shopId}/>
                  </div>
                </div>
              );
            }
          } }
        </Conveyor>
        ) }
      </QueryStringSync>
    );
  }
}

ProductReportSection.propTypes = {
  user: schema.ExternalUser.isRequired,
  location: schema.RouterLocation.isRequired,
};
