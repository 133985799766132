import * as _sentry from './sentry';
import config from './config';
import * as _http from './utils/http';


const CACHE = {};

const cached = (key, func) => () => {
  if (key in CACHE) {
    return Promise.resolve(CACHE[key]);
  } else {
    return func().then(data => {
      CACHE[key] = data;
      return data;
    }).catch(err => {
      _sentry.captureException(err, {level: 'error'});
      throw err;
    });
  }
};

const listToMap = (list, key = 'id') => {
  const map = {};
  // Extra function calls kill perf on large-ish lists
  for (const item of list) map[item[key]] = item;  // eslint-disable-line no-restricted-syntax
  return map;
};

export const fetchEnums = cached('ENUMS', function fetchEnums() {
  return _http.get(`${config().apiUrl}/data/enums`).then(_http.parseJSONAPI).then(({data}) => {
    return {
      affiliates: data.affiliate,
      verticals: data.vertical,
      deviceTypes: data.device_type,
      sites: data.site,
      currencies: data.currency,
      partnerReportResolutions: data.resolution,
      partnerReportGroupBy: data.grouping_dimension,
      productsReportReportTypes: data.click_report_type,
    };
  });
});

export const fetchShops = cached('SHOPS', function fetchShops() {
  return _http.get(`${config().apiUrl}/data/shops`)
  .then(_http.parseJSONAPI)
  .then(data => listToMap(data.data.shops));
});

export const fetchCompanies = cached('COMPANIES', function fetchCompanies() {
  return _http.get(`${config().apiUrl}/data/companies`)
  .then(_http.parseJSONAPI)
  .then(data => listToMap(data.data.companies));
});

export const fetchCampaignFolders = cached('CAMPAIGNFOLDERS', function fetchCampaignFolders() {
  return _http.get(`${config().apiUrl}/data/campaign-folders`)
  .then(_http.parseJSONAPI)
  .then(data => data.data.CampaignFolders);
});

export const fetchCategories = cached('CATEGORIES', function fetchCategories() {
  return _http.get(`${config().apiUrl}/data/categories`)
  .then(_http.parseJSONAPI)
  .then(data => listToMap(data.data.categories));
});

export const fetchCategoryFolders = cached('CATEGORYFOLDERS', function fetchCategoryFolders() {
  return _http.get(`${config().apiUrl}/data/category-folders`)
  .then(_http.parseJSONAPI)
  .then(data => data.data.CategoryFolders);
});

export const fetchBudgetsReasons = cached('BUDGETREASONS', function fetchBudgetsReasons() {
  return _http.get(`${config().apiUrl}/budgets/reasons`)
  .then(_http.parseJSONAPI)
  .then(data => data);
});

export const conveyorConfiguration = (...keys) => keys.reduce(
  (conf, key) => ({
    fields: {
      ...conf.fields,
      [key]: {
        enums: fetchEnums,
        shops: fetchShops,
        companies: fetchCompanies,
        campaignFolders: fetchCampaignFolders,
        categories: fetchCategories,
        categoryFolders: fetchCategoryFolders,
      }[key],
    },
    mapPropsToArgs: {
      ...conf.mapPropsToArgs,
      [key]: () => {},
    },
  }),
  {fields: {}, mapPropsToArgs: {}}
);
