/**
 * Loop between sorting direction represented as -1, 0, 1.
 *
 * @param  {-1 | 0 | 1} dir
 * @return {-1 | 0 | 1}
 */
export function nextSortDirection(dir) {
  const cycle = [0, -1, 1, 0];
  // Works as indexOf returns first one.
  return cycle[cycle.indexOf(dir || 0) + 1];
}

/**
 * Sort map needs to be a list for ordering of sort terms,
 * this function updates that list immutably.
 *
 * @param {Array<[string, -1 | 1]>} sortList
 * @param {string} key
 * @param {-1|0|1} ?dir
 * @param {bool} allowMultiple
 * @return {Array<[string, -1 | 1]>}
 */
export function updateSortList(sortList, key, dir, allowMultiple = true) {
  const map = (allowMultiple && sortList && [...sortList]) || [];

  if (!dir) {
    return map.filter(([k]) => k !== key);
  } else if (dir !== -1 && dir !== 1) {
    throw new Error(`Invalid direction ${dir}`);
  } else {
    let found = -1;

    map.forEach(([k], i) => {
      if (k === key) {
        found = i;
      }
    });

    if (found > -1) {
      map[found] = [key, dir];
    } else {
      map.push([key, dir]);
    }
    return map;
  }
}

/**
 * @param  {Array<[string, -1 | 1]>} sortList
 * @return {{[key: string]: -1 | 1}}
 */
export function sortListToMap(sortList) {
  return (sortList || []).reduce((map, [key, dir]) => {
    return {
      ...map,
      [key]: dir,
    };
  }, {});
}

/**
 * Build a JSON-API compliant sort string from a map of directions.
 *
 * Direction is represented by -1 (desc) or 1 (asc), anything else is
 * ignored silently.
 *
 * @param  {Array<[string, -1 | 1]>} sortList
 * @return { {string}
 */
export function buildSortString(sortList) {
  return sortList.reduce((items, [key, dir]) => {
    if (dir === -1) {
      return [...items, `-${key}`];
    } else if (dir === 1) {
      return [...items, key];
    } else if (dir === 0) {
      return items;
    } else {
      throw new Error(`Invalid direction ${dir}`);
    }
  }, []).join(',');
}

/**
 * Parse a sort string into a SortList, no validation
 *
 * @param  {string}
 * @return {Array<[string, -1 | 1]>}
 */
export function parseSortString(sortString) {
  if (!sortString) {
    return [];
  }

  return sortString.split(',').map((item) => {
    if (item[0] === '-') {
      return [item.slice(1), -1];
    } else if (item[0] === '+') {
      return [item.slice(1), 1];
    } else if (/[a-zA-Z_]/.test(item[0])) {
      return [item, 1];
    } else {
      throw new Error(`Invalid leading character ${item[0]}`);
    }
  });
}

export const apply = value => (typeof value === 'function' ? value() : value);
