import React from 'react';

import {getISOWeek, getISOYear} from 'date-fns';

import * as _utils from '../../../utils';
import {Button, Flag, Icon} from '../../../components';

const LOCALE = _utils.strings.getUserLocale();

/**
 * Render a date in a more readable format.
 *
 * **WARNING**: This assumes the input string is UTC. ALWAYS!
 *
 * @export
 * @param {Date|string} date
 * @param {'day'|'calendar_week'|'year'|'month'} resolution
 * @param {string} locale - User locale used for day rendering.
 * @returns string
 */
export function renderDate (date, resolution, locale) {
  if (date == null) {
    return 'N/A';
  }

  function _parseDate(_date) {
    if (_date instanceof Date) return _date;

    const [rawYear, rawMonth, rawDay] = _date.split('-');
    const year = parseInt(rawYear, 10);
    const month = parseInt(rawMonth, 10) - 1; // Months are zero index
    const day = parseInt(rawDay.slice(0, 2), 10); // Remove any time attached to date
    return new Date(year, month, day);
  }

  const _date = _parseDate(date);

  switch (resolution) {
    case 'day':
      return _date.toLocaleDateString(locale || LOCALE);
    case 'calendar_week':
      return `${getISOYear(_date)}/${getISOWeek(_date)}`;
    case 'month':
      const month = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December',
      ][_date.getMonth()];
      return `${_date.getFullYear()}/${month}`;
    case 'year':
      return _date.getFullYear();
    default:
      throw new Error(`Unhandled date resolution ${resolution}`);
  }
}

/**
 * Render nice, localized numbers
 *
 * @export
 * @param {string|number} value
 * @param {string} prefix
 * @param {string} locale - User locale
 * @returns string
 */
export function renderNumber (value, prefix, locale) {
  if (value == null) {
    return 'N/A';
  }

  const _value = typeof value !== 'number' ? parseFloat(value) : value;

  if (Number.isNaN(_value)) {
    return 'Invalid number';
  }

  const localized = (
    parseFloat(_value.toFixed(2))
    .toLocaleString(locale || LOCALE)
  );

  if (prefix) {
    return `${_utils.func.apply(prefix)} ${localized}`;
  } else {
    return localized;
  }
}

export const numeralRenderer = prefix =>
  (row, key) => renderNumber(row[key], prefix);

export const percentageRenderer =
  (row, key) => renderNumber(100 * row[key], '%');

export const titleCaseRenderer = propName =>
  row => _utils.strings.titleCase(row[propName]) || 'N/A';


export const reportTypeRenderer = propName =>
  row => row[propName].charAt(0).toUpperCase() +
    row[propName].substr(1).toLowerCase() || 'N/A';

export const identity = (row, key) => row[key] || 'N/A';

export const renderLink = (row, key) => {
  return (
    <a href={row[key]}>
      {row[key]}
    </a>
  );
};

export const renderShop = (shops, shopId) => {
  const shop = shops[shopId];
  if (shop === undefined) {
    return `N/A`;
  }
  return `${shop.name} ${_utils.strings.SITE_TO_LOCALE[shop.site]}`;
};

export const renderCompany = (companies, companyId) => {
  const company = companies[companyId];
  if (company === undefined) {
    return `N/A`;
  }
  return company.name;
};

export const renderCategory = (categoryTags, categoryTagId) => {
  const categoryTag = categoryTags[categoryTagId];

  if (categoryTag === undefined) {
    return `N/A`;
  }
  return categoryTag.name;
};

export const renderSite = site => {
  if (!site) return 'N/A';
  const locale = _utils.strings.SITE_TO_LOCALE[site];
  return (
    <span>
      <Flag code={locale} className="inline-block mr1"/>
      {locale}
    </span>
  );
};

export const renderLocale = site => {
  if (!site) return 'N/A';
  const locale = site.slice(3);
  return (
    <span>
      <Flag code={locale} className="inline-block mr1"/>
      {locale}
    </span>
  );
};

/**
 * A utility to convert site(locale) to country code.
 *
 * @param {string} site - 5 letter site(locale) for a shop e.g., en_US de_DE nl_NL de_CH
 * @return {string} 'NA' if site is null else country code e.g., US DE NL CH
 */
export const siteToWeb = site => (
  site
  ? site.slice(3)
  : 'N/A'
);

export const renderCampaign = (campaigns, campaignId) => {
  const campaign = campaigns[campaignId];
  if (campaign === undefined) {
    return `N/A`;
  }
  return campaign.name;
};


const DEVICE_ICONS = {
  desktop: 'desktop_windows',
  tablet: 'tablet',
  mobile: 'phone_iphone',
};

export function renderDeviceType(deviceType) {
  if (deviceType == null) return 'N/A';
  const icon = DEVICE_ICONS[deviceType.toLowerCase()];
  if (icon != null) {
    return (
      <div className="inline-block">
        <Icon text={icon} size="mini"/> {_utils.strings.titleCase(deviceType)}
      </div>
    );
  } else {
    return _utils.strings.titleCase(deviceType);
  }
}

export function renderDownloadButton(entry) {
  if (entry.report_url == null) return '';
  return (
   <Button
      type="link"
      key={entry.report_url}
      level="primary"
      small
      className="mx1"
      target="_blank" rel="noopener noreferrer" download
      href={entry.report_url}
      icon="file_download"
    >Download
    </Button>
  );

}
