import React, { PureComponent } from 'react';
import Conveyor from 'react-conveyor';
import * as _dateFns from 'date-fns';
import * as staticData from '../../../../static-data';
import LoadingState from '../../../../components/LoadingState';
import RedScreenOfDeath from '../../../../components/RedScreenOfDeath';
import StateBuffer from '../../../../components/StateBuffer';
import Filters from './Filters';
import Report from './Report';
import Modal from '../../../../components/Modal';
import Tracking from '../../tracking';


const DEFAULT_CONFIGURATION = {
    toDate: _dateFns.subDays(_dateFns.startOfYesterday(), 1),
    fromDate: _dateFns.subDays(_dateFns.startOfYesterday(), 8),
    resolution: 'day',
    selectedGroupBy: ['date'],
    targetCurrency: 'EUR',
};

const CONVEYOR_CONF = staticData.conveyorConfiguration('enums');

export default class PerformanceOverviewSection extends PureComponent {

    constructor(...args) {
        super(...args);
        this.state = {
            configuration: { ...DEFAULT_CONFIGURATION },
            isModalOpen: false,
        };
    }

    updateConfiguration = (nextConfiguration) => {
        this.setState({ configuration: nextConfiguration });
    }

    showModal = () => {
        this.setState({isModalOpen: true});
    }

    hideModal = () => {
        this.setState({isModalOpen: false});
    }

    render() {
        return (
            <Conveyor {...CONVEYOR_CONF}>
                {({ inFlight, missing, errors, ...rest }) => {
                    if (inFlight || missing) {
                        return <LoadingState />;
                    } else if (errors) {
                        return <RedScreenOfDeath className="flex-auto scroll-y" errors={errors} />;
                    } else {
                        const { enums } = rest;
                        const filteredGroupBy = enums.partnerReportGroupBy.filter(
                            groupBy => groupBy === 'date' || groupBy === 'device_type'
                        );

                        return (
                            <div className="flex flex-1 flex-row fill">
                                <div className="p1 flex-1 scroll-y" style={{ minWidth: 242 }}>
                                    <StateBuffer defaultState={this.state.configuration}
                                        onApply={this.updateConfiguration}
                                    >
                                        {({ state: configuration, update, apply, dirty }) => (
                                            <Filters
                                                onChange={update}
                                                onApply={apply}
                                                dirty={dirty}
                                                {...configuration}
                                                availableGroupBy={filteredGroupBy}
                                                availableCurrencies={enums.currencies}
                                                resolutions={enums.partnerReportResolutions}
                                            />
                                        )}
                                    </StateBuffer>
                                    <div className="flex justify-between mt1">
                                        <span className="mx1 cursor-pointer underline property-id-trx" onClick={this.showModal}>
                                          Property ID for Stylight Sales Tracking
                                        </span>
                                    </div>
                                    {
                                        this.state.isModalOpen &&
                                        <Modal onDismiss={this.hideModal} open={this.state.isModalOpen} dismissOnEsc>
                                            <Tracking/>
                                        </Modal>
                                    }
                                </div>
                                <div className="p1 flex flex-column flex-4 scroll-x">
                                    <Report
                                        {...this.state.configuration}
                                        availableGroupBy={filteredGroupBy}
                                    />
                                </div>
                            </div>
                        );
                    }
                }

                }
            </Conveyor>
        );
    }
}
