import React, {Component} from 'react';
import PropTypes from 'prop-types';

import * as schema from '../prop-types/schema';
import * as _qs from 'query-string';

import {Button} from '..';

import {employeeLogin} from '../../auth';

import {VerticalViewport} from '../layout';
import EnvBanner from './EnvBanner';
import {MainNav, LogoLink} from './Nav';
import {InternalUserMenu, ExternalUserMenu} from './UserMenu';


const YEAR = ((new Date()).getFullYear());

const COPYRIGHT = (
  <div>
    © 2012-{YEAR} <a href="https://www.stylight.de">Stylight - part of Inspiration Commerce Group GmbH</a>. All rights reserved.
  </div>
);

const withRedirect = () => {
  const params = _qs.parse(window.location.search);
  return params.redirect;
};


export class InternalAppWrapper extends Component {
  static propTypes = {
    children: PropTypes.node,
    user: schema.InternalUser,
    navLinks: MainNav.propTypes.links,
  }

  render() {
    return (
      <VerticalViewport>
        <EnvBanner/>
        <header className="bg-black color-white flex flex-wrap justify-between no-flex-shrink">
          {/* Keep this as an href to give a reload escape hatch. */}
          <LogoLink homeRoute="/internal"/>
          { !!this.props.user && (
            <React.Fragment>
              <MainNav links={this.props.navLinks}/>
              <InternalUserMenu user={this.props.user}/>
            </React.Fragment>
          )}
        </header>

        <div className="flex-1 flex">
          {this.props.children}
        </div>

        <footer className="center p2 color-gray-dark bg-white no-flex-shrink">
          {COPYRIGHT}
        </footer>
      </VerticalViewport>
    );
  }
}

export class ExternalAppWrapper extends Component {
  static propTypes = {
    children: PropTypes.node,
    user: schema.ExternalUser,
    navLinks: MainNav.propTypes.links,
  }

  render() {
    return (
      <VerticalViewport>
        <EnvBanner/>
        <header className="bg-black color-white flex flex-wrap justify-between no-flex-shrink">
          {/* Keep this as an href to give a reload escape hatch. */}
          <LogoLink homeRoute="/"/>
          { !!this.props.user && (
            <React.Fragment>
              <MainNav links={this.props.navLinks}/>
              <ExternalUserMenu user={this.props.user}/>
            </React.Fragment>
          )}
        </header>

        <div className="flex-1 flex">
          {this.props.children}
        </div>

        { (!this.props.user &&
          <footer className="center p2 color-gray-dark bg-white no-flex-shrink flex justify-between items-baseline">
            {COPYRIGHT}
            <div>
              <Button onClick={() => employeeLogin(withRedirect())}>Stylight employee login</Button>
            </div>
          </footer>
        ) || (
          <footer className="center p2 color-gray-dark bg-white no-flex-shrink">
            {COPYRIGHT}
          </footer>
        )}
      </VerticalViewport>
    );
  }
}
