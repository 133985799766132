/* istanbul ignore file */
import request from 'superagent';

/**
 * Add a script to the DOM and execute callback once it has been loaded.
 * @param {string} src
 * @param {() => void)} cb
 */
function insertScriptIntoDOM(src, cb) {
  const script = document.createElement('script');
  // Webpack build can add double leading slashes which will break the script
  // loading, this nesures they are treated as root-local all the time.
  script.src = src.replace('//', '/');

  script.onload = () => {
    cb();
  };

  script.onerror = () => {
    throw new Error(`Failed to load script ${src}`);
  };

  document.head.appendChild(script);
}

/**
 * Simple polyfill loader. Will search a subset of markers and then load
 * the full polyfill buindle if any marker is not present.
 *
 * [WARN] Code executed as part of this routine must NOT use anything that
 * could be polyfilled.
 *
 * @param {() => void} callback
 */
export default function withPolyfills(callback) {
  const hasAllFeatures = [
    [window, 'Promise', 'Symbol', 'Map', 'Set'],
    [Object, 'assign', 'keys', 'entries', 'values'],
    [String.prototype, 'includes'],
    [Array.prototype, 'includes'],
    [Date, 'now'],
    [Number.prototype, 'toFixed'],
  ].every((source, ...targets) => targets.every(target => target in source));

  if (hasAllFeatures) {
    callback();
  } else {
    // We need to figure out where the polyfill chunk ended up as
    // filenames generated by webpack are hashed.
    // We should need the polyfill rarely enough that the perf drawback
    // is not super important.
    request.get('/build-manifest.json').end((err, resp) =>
      insertScriptIntoDOM(`/${JSON.parse(resp.text)['polyfill.js']}`, callback)
    );
  }
}
