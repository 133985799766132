import {chainableValidator} from './_utils';

// Use the HTML5 spec email validator:
// https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
const EMAIL_RE = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

/**
 * Validator to check that value is a valid email.
 *
 * @param  {Object} props
 * @param  {string} propName
 * @param  {string} componentName
 * @param  {...Array<any>} rest
 * @return {Error|null}
 */
export default chainableValidator(
  function emailValidator (props, propName, componentName) {
    const value = props[propName];
    if (typeof value !== 'string') {
      return new Error(
        `Expected prop \`${propName}\` in \`${componentName || 'ANONYMOUS'}\` to be a string but got \`${value}\``
      );
    } else if (!EMAIL_RE.test(value)) {
      return new Error(
        `Expected prop \`${propName}\` in \`${componentName || 'ANONYMOUS'}\` to be a valid email but got \`${value}\``
      );
    }
    return null;
  }
);
