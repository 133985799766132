/** All renderers relevant to CPC category cpcs. */
import React from 'react';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';
import * as _date from 'date-fns';

import * as staticData from '../../../../static-data';
import {withData} from '../../../../components/helpers';
import * as schema from '../../../../components/prop-types/schema';
import {
  Filler,
  Card,
} from '../../../../components/layout';

import * as customerAPI from '../../customer-api';
import {ContractDetail} from '../contracts';

import CategoryCpcHistory from './History';
import CategoryCpcsForm from './Form';


function splitCpcs(entries) {
  let history = _sortBy(entries, e => new Date(e.valid_from)).reverse();
  let next = null;
  let current = null;

  if (history.length) {
    if (new Date(history[0].valid_from) > _date.startOfToday()) {
      next = history[0];
      history = history.slice(1);
    }
  }

  if (history.length) {
    if (new Date(history[0].valid_to) > _date.startOfYesterday()) {
      current = history[0];
      history = history.slice(1);
    }
  }

  return [next, current, history];
}

function CategoryCpcsSection (props) {
  const [next, current, past] = splitCpcs(props.history);
  const history = [next, current, ...past].filter(x => x);
  return (
    <Filler vertical scrollY>
      <Filler reverse wrap>
        <Filler className="myn1 p2 max-width-3 min-width-2" scrollY vertical>
          <Card className="my1" title="Current Contract">
            { (props.contract && props.contract.current_contract)
              ? <ContractDetail {...props.contract.current_contract}/>
              : 'No active contract' }
          </Card>
          <Card className="my1" title="Next Contract">
            { (props.nextContract)
              ? <ContractDetail {...props.nextContract}/>
              : 'No future contract' }
          </Card>
          <Card className="my1" title="Category Pricing History">
          { (history.length)
            ? <CategoryCpcHistory history={history}
                                  categories={props.categories}/>
            : 'No past entry' }
          </Card>
        </Filler>
        <Filler className="p2" vertical scrollY>
          { (props.contract && props.contract.current_contract)
            ? <CategoryCpcsForm saved={next || current}
                                contract={props.contract.current_contract}
                                categories={props.categories}
                                submitAction={props.updateHistory}
                                stopBidding={props.stopBidding}
                                categoryBidImportEnabled={props.categoryBidImportEnabled}/>
            : 'No active contract' }
        </Filler>
      </Filler>
    </Filler>
  );
}

CategoryCpcsSection.propTypes = {
  contract: schema.CurrentCPCContract,
  nextContract: schema.CPCContract,
  history: PropTypes.arrayOf(schema.CategoryCPCCollection),
  categories: PropTypes.arrayOf(schema.CategoryFolder).isRequired,
  updateHistory: PropTypes.func.isRequired,
  stopBidding: PropTypes.func.isRequired,
  categoryBidImportEnabled: PropTypes.bool.isRequired,
};

export default withData({
  fields: {
    contract: customerAPI.currentContract,
    nextContract: customerAPI.nextContract,
    history: customerAPI.categoryCpcs,
    categories: staticData.fetchCategoryFolders,
  },
  mapPropsToArgs: {
    contract: () => null,
    history: () => null,
    categories: () => null,
    nextContract: () => null,
  },
  mutations: {
    updateHistory: data => customerAPI.saveCategoryCpcs(data)
      .then(customerAPI.categoryCpcs)
      .then(history => ({history})),
    stopBidding: () => customerAPI.stopCategoryCpcs()
      .then(customerAPI.categoryCpcs)
      .then(history => ({history})),
  },
  isLoading: props => props.missing || (props.inFlight || []).some(
    element => Object.keys(props.fields).includes(element)
  ),
})(CategoryCpcsSection);
