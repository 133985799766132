import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  classname,
  func as _func,
  strings as _strings,
} from '../../../utils';
import DatagridPropTypes from '../prop-types';

export class Cell extends PureComponent {
  render() {
    const { column, item, className, _wrapper, ...rest} = this.props;
    const content = (column.renderer
                     ? column.renderer(item, column.key, item[column.key])
                     : item[column.key]);
    if (_wrapper) {
      return <_wrapper className={className} {...rest}>{content}</_wrapper>;
    } else {
      return <div className={className} {...rest}>{content}</div>;
    }
  }
}

Cell.propTypes = {
  className: PropTypes.string,
  column: DatagridPropTypes.Column.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  _wrapper: PropTypes.func,
};


export class HeaderCell extends PureComponent {
  render() {
    const {
      className, onSort, sortDirection, column, _wrapper,
      ...rest
    } = this.props;

    const sortable = !!(onSort && column.sortable);
    const intSortDirection = sortDirection ? parseInt(sortDirection, 10) : 0;
    const hasColumnDescription = !!column.description;

    const cls = classname(className, {
      'is-sortable': sortable,
      'is-sorted-asc': sortable && intSortDirection === 1,
      'is-sorted-desc': sortable && intSortDirection === -1,
    });

    const onClick = (evt) => {
      if (!onSort) return;
      evt.preventDefault();
      evt.stopPropagation();
      onSort(evt.currentTarget.getAttribute('data-key'));
    };

    const props = sortable ? {
      'className': cls,
      'onClick': onClick,
      'data-key': column.key,
    } : {
      className: cls,
    };

    const label = (_func.apply(column.label, this.props) ||
                   _strings.titleCase(column.key));
    const icon = sortable ? <div className="sort-icon"></div> : null;
    const infoIcon = hasColumnDescription ? <div className="info-icon"></div> : null;

    if (_wrapper) {
      return <_wrapper {...rest} {...props} title={column.description}><div>{label}</div>{infoIcon}{icon}</_wrapper>;
    } else {
      return <div {...props} title={column.description}><div>{label}</div>{infoIcon}{icon}</div>;
    }
  }
}

HeaderCell.propTypes = {
  column: DatagridPropTypes.Column.isRequired,
  sortDirection: PropTypes.oneOf([-1, 0, 1, '1', '0', '-1']),
  onSort: PropTypes.func,
  className: PropTypes.string,
  _wrapper: PropTypes.func,
};
