import ReactGA from 'react-ga';

import * as _sentry from './sentry';
import config from './config';
import * as _http from './utils/http';
import { getNestedProperty } from './utils/objects';
import { createProviderConsumerPair } from './components/context';

const INITIAL_STATE = {
  loading: false,
  error: null,
  user: null,
  is_under_maintenance: false,
};

/** Call the logout handler which will reset the cookie. */
export const logout = () => _http.post(`${config().apiUrl}/auth/logout`);

/** Redirect user to SPC login page (internal). */
export function employeeLogin(redirect) {
  const redirectTarget = encodeURIComponent(redirect || '/internal');
  window.location.assign(`${config().apiUrl}/auth/employee/login?redirect=${redirectTarget}`);
}

/** Logout and redirect user to SPC login page (internal). */
export function employeeLogout() {
  window.location.assign(`${config().apiUrl}/auth/logout/`);
}

/** Redirect user to SPC login page (external). */
export function partnerLogin(redirect) {
  const redirectTarget = encodeURIComponent(redirect || '/');
  window.location.assign(`${config().apiUrl}/auth/partner/login?redirect=${redirectTarget}`);
}

/** Logout and redirect user to SPC login page (external). */
export function partnerLogout() {
  window.location.assign(`${config().apiUrl}/auth/logout/`);
}

export const fetchUser = () => dispatch => {
  dispatch({...INITIAL_STATE, loading: true});
  _http.get(`${config().apiUrl}/auth/user`).then(_http.parseJSONAPI).then(
    res => {
      dispatch({ user: res.data, loading: false, error: null});
      _sentry.setUserContext({ account: res.data.account });
      if (window.ga !== undefined) {
        ReactGA.set({ userId: res.data.account });
        if (res.data.meta && res.data.meta.shop_id) {
          ReactGA.set({dimension1: res.data.account});
          ReactGA.set({dimension2: res.data.meta.shop_id});
        }
      }
    },
    error => {
      const status = getNestedProperty(error, 'response.status');
      const location = getNestedProperty(error, 'response.header.location');

      if (status === 503 && location === '/maintenance') {
        dispatch({...INITIAL_STATE, is_under_maintenance: true});
      } else if (status === 401 || status === 403) {
        dispatch(INITIAL_STATE);
      } else {
        dispatch({...INITIAL_STATE, error: error});
      }
    }
  );
};

/**
 * Check whether a given user has a certain role.
 *
 * @param {{roles: Array<string>}} user
 * @param {string} roleName
 * @returns {boolean}
 */
export function hasRole(user, roleName) {
  return user.roles.some(role => role === roleName);
}

export function requestSudo(realm = 'external') {
  window.location.assign(`${config().apiUrl}/auth/sudo?realm=${realm}`);
}

export function dropSudo() {
  logout().then(() => employeeLogin());
}

const {
  Provider: AuthProvider,
  Consumer: _AuthConsumer,
} = createProviderConsumerPair('auth', INITIAL_STATE, { fetchUser });

class AuthConsumer extends _AuthConsumer {
  componentDidMount() {
    super.componentDidMount();
    if (!this.state.context.user) this.channel().actions.fetchUser();
  }
}

export { AuthProvider, AuthConsumer };
