import Raven from 'raven-js';

function setup (config, app) {
  if (config.sentryDsn) {
    // Default sentry setup, will handle uncaught exceptions outside of the
    // console and extensions.
    // See: https://docs.sentry.io/clients/javascript/config/.

    const release = (
      process.env.NODE_ENV === 'production'
        ? `circle:${process.env.CIRCLE_BUILD_NUM},git:${process.env.GIT_SHA}`
        : undefined
    );

    Raven.config(config.sentryDsn, {
      logger: process.env.SENTRY_LOGGER || `dagobert_client_${app}`,
      environment: process.env.NODE_ENV,
      release: release,
      tags: {
        app: app,
        version: process.env.VERSION,
        git_sha: process.env.GIT_SHA,
        circle_branch: process.env.CIRCLE_BRANCH,
        circle_build_num: process.env.CIRCLE_BUILD_NUM,
        runtime_env: config.environment,
        build_env: process.env.NODE_ENV,
      },
      autoBreadcrumbs: {
        xhr: true,
        console: false,
        dom: true,
        location: true,
      },
    }).install();

    // We manually set the reporter to handle unhandled promise rejections.
    window.onunhandledrejection = function onunhandledrejection (evt) {
      Raven.captureException(evt.reason);
    };
  }
}

function guardedSentryCaller (funcName) {
  return function wrapped (...args) {
    if (Raven.isSetup()) {
      Raven[funcName](...args);
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Would call Raven.${funcName}(`, ...args, ')');
    }
  };
}

const captureBreadcrumb = guardedSentryCaller('captureBreadcrumb');
const captureException = guardedSentryCaller('captureException');
const captureMessage = guardedSentryCaller('captureMessage');
const setUserContext = guardedSentryCaller('setUserContext');

function context (...args) {
  const func = args.pop();
  if (Raven.isSetup()) {
    return Raven.context(...args, func);
  } else {
    return func();
  }
}

function wrap (...args) {
  const func = args.pop();
  if (Raven.isSetup()) {
    return Raven.wrap(...args, func);
  } else {
    return func;
  }
}

export {
  captureBreadcrumb,
  captureException,
  captureMessage,
  context,
  Raven,
  setup,
  setUserContext,
  wrap,
};
