import React from 'react';
import PropTypes from 'prop-types';


// Ported from: https://github.com/lipis/flag-icon-css
const SVG_FLAGS = [
  'fr',
  'de',
  'nl',
  'us',
  'at',
  'ch',
  'gb',
  'it',
  'se',
  'es',
  'be',
  'br',
  'mx',
  'ca',
  'au',
  'no',
  'ie',
];

export default function Flag (props) {
  const code = props.code.toLowerCase();
  if (code == null) return null;
  const src = `/flags/${code}.svg`;
  return (
    <img src={src} className={props.className} style={{width: props.width}}/>
  );
}

Flag.propTypes = {
  code: PropTypes.oneOf(
    SVG_FLAGS
    .concat(SVG_FLAGS).map(x => x.toUpperCase())
  ),
  width: PropTypes.string,
  className: PropTypes.string,
};

Flag.defaultProps = {
  width: '1rem',
  className: '',
};
