import {chainableValidatorFactory} from './_utils';

/**
 * Validator factory to check strict equality of the prop with
 * a given constant.
 *
 * @param {Array<(Object, string, ...Array<any>) => Error|null>} validators
 * @return {(Object, string, ...Array<any>) => Error|null}
 */
export default chainableValidatorFactory(
  function tupleValidatorFactory (validators) {

    if (!Array.isArray(validators)) {
      throw new TypeError(
        `\`tupleValidatorFactory\` expects and array of validators as input.`
      );
    }

    if (!validators.length) {
      throw new TypeError(
        `\`tupleValidatorFactory\` expects at least one validator.`
      );
    }

    if (validators.some(validator => typeof validator !== 'function')) {
      throw new TypeError(
        `\`tupleValidatorFactory\` expects at validators to be functions.`
      );
    }

    const tupleLength = validators.length;
    const _validators = [...validators]; // Protect against external modifications.

    return function tupleValidator (props, propName, componentName, ...rest) {
      const value = props[propName];

      if (!Array.isArray(value) || value.length < tupleLength) {
        return new Error(
          `Expected prop \`${propName}\` in \`${componentName || 'ANONYMOUS'}\` to be an array of length ${tupleLength} but got \`${value}\` instead.`
        );
      }

      for (let i = 0; i < tupleLength; i++) {
        const error = _validators[i](value, i, `${componentName || 'ANONYMOUS'}.${propName}`, ...rest);
        if (error) {
          return error;
        }
      }

      return null;
    };
  }
);
