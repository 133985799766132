import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import * as schema from '../../../../components/prop-types/schema';
import {Button} from '../../../../components';

import {
  CheckboxSelector,
  DateRangeSelector,
} from '../../../../components/views/partner-report/filters';

export default class ProductFilters extends PureComponent {

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    fromDate: schema.MaybeDate.isRequired,
    toDate: schema.MaybeDate.isRequired,
    selectedFilters: PropTypes.shape({
      reportTypes: PropTypes.arrayOf(schema.ProductReportType).isRequired,
    }).isRequired,
    availableReportTypes: PropTypes.arrayOf(schema.ProductReportType).isRequired,
  }

  onUpdateDateRange = ({startDate: fromDate, endDate: toDate}) => {
    this.props.onChange({fromDate, toDate}, () => this.props.onApply());
  };

  filterUpdater = key => value => this.props.onChange(configuration => {
    return {
      ...configuration,
      selectedFilters: {
        ...configuration.selectedFilters,
        [key]: value,
      },
    };
  }, () => this.props.onApply());

  updateFilters = {
    reportTypes: this.filterUpdater('reportTypes'),
  };

  resetAllFilters = () => {
    this.props.onChange(configuration => {
      return {
        ...configuration,
        selectedFilters: Object.keys(configuration.selectedFilters).reduce(
          (map, key) => ({...map, [key]: []}),
          {}
        ),
      };
    }, () => this.props.onApply());
  }

  render() {
    const {fromDate, toDate, selectedFilters, availableReportTypes} = this.props;

    const hasActiveFilters = (
      Object
      .keys(this.props.selectedFilters)
      .some(key => this.props.selectedFilters[key].length > 0)
    );

    return (
      <div className="flex flex-column">

        <div className="flex justify-between">
          { hasActiveFilters &&
            <Button className="mx1" level="danger" outline block
                    disabled={!hasActiveFilters}
                    onClick={this.resetAllFilters}>
              Reset All Filters
            </Button> }
        </div>

        <div className="m1 p1 border rounded border-color-gray-lighter">
          <div className="medium my1">
            Date range
          </div>
          <DateRangeSelector
            fromDate={fromDate}
            toDate={toDate}
            onChange={this.onUpdateDateRange}
          />
          <div className="flex justify-between items-center flex-wrap my1">
            <div>
              <div className="medium py1">Report Type</div>
                <CheckboxSelector
                  selected={selectedFilters.reportTypes}
                  options={availableReportTypes}
                  onUpdateSelection={this.updateFilters.reportTypes}
                />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
