import React, {PureComponent} from 'react';
import _uniq from 'lodash/uniq';
import _values from 'lodash/values';
import PropTypes from 'prop-types';

import * as schema from '../../../../components/prop-types/schema';
import * as _func from '../../../../utils/func';
import * as _strings from '../../../../utils/strings';
import {Checkbox} from '../../..';

const listValues = _func.memoize(map => _values(map));

/**
 * Create a function to match a shop to a search term.
 * Both function creation and the function itself are memoized.
 *
 * @param {string} term
 * @return {({id: number, name: string, site: string}) => boolean}
 */
const makeShopMatcher = _func.memoize(
  term => _func.memoize(
    shop => (
      _strings.fuzzyMatch(term, shop.name) ||
      _strings.fuzzyMatch(term, shop.site) ||
      _strings.fuzzyMatch(term, _strings.SITE_TO_LOCALE[shop.site], {matchType: 'exact'}) ||
      _strings.fuzzyMatch(term, `${shop.id}`, {matchType: 'start'})
    )
  )
);

/**
 * Create a function to match a company to a search term.
 * Both function creation and the function itself are memoized.
 *
 * @param {string} term
 * @return {({id: number, name: string, site: string}) => boolean}
 */
const makeCompanyMatcher = _func.memoize(
  term => _func.memoize(
    company => (
      _strings.fuzzyMatch(term, company.name) ||
      _strings.fuzzyMatch(term, `${company.id}`, {matchType: 'start'})
    )
  )
);

class SearchSelector extends PureComponent {

  static propTypes = {
    selected: PropTypes.arrayOf(schema.Int).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.objectOf(PropTypes.object).isRequired,
    onUpdateSelection: PropTypes.func.isRequired,
    itemLabel: PropTypes.func.isRequired,
    itemMatcher: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      filtered: [],
      filterValue: '',
    };
  }

  // Use memoized `Object.values` to not have to play with caching in
  // `componentWillReceiveProps`.
  list = () => listValues(this.props.items);

  /**
   * Filter the list of items by search terms separated by whitespace.
   * An item should match all search terms to be returned.
   *
   * @param {string} search
   * @return {Array}
   */
  filterItems = _func.memoize(
    (search) => {
      const matchers = search.split(/\s+/g).map(this.props.itemMatcher);
      let result = this.list();
      for (const matcher of matchers) { // eslint-disable-line no-restricted-syntax
        const next = [];
        for (const item of result) { // eslint-disable-line no-restricted-syntax
          if (matcher(item)) {
            next.push(item);
          }
        }
        result = next;
      }
      return result;
    }
  );

  /**
   * Update state according to search query.
   * @param {string | null} search
   */
  suggest = _func.debounce(
    search => {
      if (
        search &&
        (search.trim().length > 1 || !Number.isNaN(parseInt(search, 10)))
      ) {
        this.setState({
          filtered: this.filterItems(search),
          filterValue: search.trim(),
        });
      } else {
        this.setState({filtered: [], filterValue: ''});
      }
    },
    50
  );

  handleSearchInputChange = evt => this.suggest(evt.currentTarget.value);

  isSelected = itemId => this.props.selected.indexOf(itemId) > -1;

  onSelectOne = (evt) => {
    evt.stopPropagation();
    const node = evt.currentTarget;
    const checked = node.checked;
    const itemId = parseInt(node.getAttribute('data-item-id'), 10);
    if (checked) {
      this.props.onUpdateSelection(_uniq([...this.props.selected, itemId]));
    } else {
      this.props.onUpdateSelection(
        this.props.selected.filter(id => id !== itemId)
      );
    }
  };

  selectAll = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.props.onUpdateSelection(
      _uniq([
        ...this.props.selected,
        ...this.state.filtered.map(item => item.id),
      ])
    );
  };

  deselectAll = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const filtered = this.state.filtered.map(item => item.id);
    this.props.onUpdateSelection(
      this.props.selected.filter(id => filtered.indexOf(id) === -1)
    );
  };

  render() {
    const {props, state} = this;
    const selectedCount = state.filtered.filter(x => this.isSelected(x.id)).length;
    const allSelected = selectedCount === state.filtered.length;
    const allUnselected = selectedCount === 0;
    return (
      <div>
        <input
          type="text"
          className="field"
          onChange={this.handleSearchInputChange}
          placeholder="Filter items (enter 2 characters min.)"
        />
        <div className="py1">
          { !state.filterValue && (
            <div>
              <div>Showing {props.selected.length} selected items.</div>
              <ItemList
                items={this.list().filter(item => this.isSelected(item.id))}
                onCheckboxChange={this.onSelectOne}
                isSelected={this.isSelected}
                itemLabel={props.itemLabel}
              />
            </div>
          )}

          { state.filterValue && state.filtered.length === 0 && (
            <div className="mt1">
              No items matching your search.
            </div>
          )}

          { state.filterValue && state.filtered.length > 0 && (
            <div>
              <div className="flex justify-between">
                <div>
                  {state.filtered.length} of {this.list().length} items.
                </div>
                <div className="mxn1">
                  { !allSelected && <a className="mx1 inline-block" href="#" onClick={this.selectAll}>Select All</a>}
                  { !allUnselected && <a className="mx1 inline-block" href="#" onClick={this.deselectAll}>Deselect All</a>}
                </div>
              </div>
              <ItemList
                items={state.filtered}
                onCheckboxChange={this.onSelectOne}
                isSelected={this.isSelected}
                itemLabel={props.itemLabel}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

}

function ItemList (props) {
  return (
    <div className="mt1" style={{maxHeight: 320, overflow: 'auto'}}>
      {props.items.map(item => (
        <div key={item.id} className="">
          <Checkbox
            data-item-id={item.id}
            checked={props.isSelected(item.id)}
            label={props.itemLabel(item)}
            onChange={props.onCheckboxChange}
          />
        </div>
      ))}
    </div>
  );
}

ItemList.propTypes = {
  isSelected: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.object),
  itemLabel: PropTypes.func.isRequired,
};


export class ShopSelector extends PureComponent {

  static propTypes = {
    selected: PropTypes.arrayOf(schema.Int).isRequired,
    items: PropTypes.objectOf(schema.Shop).isRequired,
    onUpdateSelection: PropTypes.func.isRequired,
  };

  shopLabel = shop => `${shop.name} ${_strings.SITE_TO_LOCALE[shop.site]} (${shop.id})`;

  render() {
    return (
        <SearchSelector
          {...this.props}
          itemLabel={this.shopLabel}
          itemMatcher={makeShopMatcher}
        />
    );
  }

}


export class CompanySelector extends PureComponent {

  static propTypes = {
    selected: PropTypes.arrayOf(schema.Int).isRequired,
    items: PropTypes.objectOf(schema.Company).isRequired,
    onUpdateSelection: PropTypes.func.isRequired,
  };

  companyLabel = company => `${company.name} (${company.id})`;

  render() {
    return (
        <SearchSelector
          {...this.props}
          itemLabel={this.companyLabel}
          itemMatcher={makeCompanyMatcher}
        />
    );
  }

}
