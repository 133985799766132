/**
 * Portal components.
 */
import React from 'react';
import PropTypes from 'prop-types';

import {apply} from '../utils/func';
import classname from '../utils/classname';

import KeyboardListener from './KeyboardListener';


export default class Modal extends React.PureComponent {

  dismiss = () => this.props.open && this.props.onDismiss && this.props.onDismiss();

  render() {
    const { children, open, dismissOnEsc, ...rest } = this.props;

    const cls = {
      root: classname('fixed z2 top-0 left-0 bottom-0 right-0', {
        'display-block': open,
        'display-none': !open,
      }),
      underlay: classname('fixed z1 top-0 left-0 bottom-0 right-0 layer-2 opacity-75 transition-fade bg-black', {
        'transition-fade': open,
      }),
      body: classname('absolute z3 centered', {
        // 'transition-fade transition-fade-end': open,
      }),
    };

    return (
      <div className={cls.root}>
        { dismissOnEsc &&
          <KeyboardListener keyName="Escape" handler={this.dismiss}/> }
        <div onClick={this.dismiss} className={cls.underlay}/>
        <div className={cls.body}>
          {apply(children, rest)}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  open: PropTypes.bool,
  dismissOnEsc: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  onDismiss: PropTypes.func,
};
