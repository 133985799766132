import React from 'react';
import PropTypes from 'prop-types';

class Notification extends React.Component {
    constructor(props) {
        super(props);

        this.intervals = {
            slow: 5000,
            normal: 2000,
            fast: 1000,
        };

        this.state = {
            firstTime: true,
        };
    }

    componentDidUpdate() {
        if (this.state.firstTime) {
            this.setState({firstTime: false});
        }

        if (this.props.show) {
            const interval = this.props.interval ?
                            this.intervals[this.props.interval]
                            : this.intervals.normal;

            setTimeout((hideNotification) => {
                hideNotification();
            }, interval, this.props.hideNotification);
        }
    }

    render() {

        if (this.props.show) {
            return (
                <div className="notification notification-show">{this.props.text}</div>
            );
        }

        else if (!this.props.show && !this.state.firstTime) {
            return (
                <div className="notification notification-hide">{this.props.text}</div>
            );
        }

        else if (this.state.firstTime) {
            return (
                <div className="notification"></div>
            );
        }

        return(null);
    }
}

Notification.propTypes = {
    text: PropTypes.string,
    show: PropTypes.bool,
    hideNotification: PropTypes.func,
    interval: PropTypes.string,
};

export default Notification;
