import React from 'react';
import PropTypes from 'prop-types';
import _mapValues from 'lodash/mapValues';
import _groupBy from 'lodash/groupBy';
import _merge from 'lodash/merge';

import { fuzzyEqual, omitEmptyBids } from './utils';
import classname from '../../../../utils/classname';
import { memoize } from '../../../../utils/func';
import { parseMonetaryValue } from '../../../../utils/number';
import { currencySymbol } from '../../../../utils/strings';
import * as Forms from '../../../../components/forms';
import * as schema from '../../../../components/prop-types/schema';
import TreeView from '../../../../components/TreeView';
import ActionButton from '../../../../components/ActionButton';

import { deviceCpcs, flattenCategoryFolder, tagsSearchMap } from '../utils';

const firstCategoryLevel = { 1: 'Fashion', 2: 'HomeAndLiving', 3: 'Beauty' };

const DEVICE_TYPES = schema._DeviceTypes.slice(0, 3);

const pricesPerTag = memoize(coll =>
  _mapValues(
    _groupBy((coll || {}).prices || [], 'tag_id'),
    prices => deviceCpcs(prices, DEVICE_TYPES).reduce((acc, [deviceType, cpcValue, ...args]) => (
      { ...acc, [deviceType]: cpcValue }
    ), {}),
  ));

const pricesPerVertical = memoize(coll =>
  {
    return _mapValues(
      _groupBy((coll || {}).prices || [], 'vertical'),
      prices => deviceCpcs(prices, DEVICE_TYPES).reduce((acc, [deviceType, cpcValue, cpcMaxValue]) => (
        { ...acc, [deviceType]: {value: cpcValue, maxValue: cpcMaxValue} }
      ), {}),
    );
  }

);

export default class CategoryCpcsForm extends React.Component {

  defaultData = () => pricesPerTag(this.props.saved);

  allowedCategories = () => {
    const verticals = new Set();
    this.props.contract.prices.forEach(cpc => {
      verticals.add(schema._Verticals.indexOf(cpc.vertical) + 1);
    });
    return this.props.categories.filter(c => verticals.has(c.id));
  };

  tagsMap = () => _merge(...this.allowedCategories().map(tagsSearchMap));

  isExpanded = () => true;

  getEffectivePrice = (tagId, deviceType, currentInput) => {
    const path = this.tagsMap()[tagId].path;
    const pricingPath = path.map(tag => this.getPriceFor(tag, deviceType, currentInput));
    return pricingPath.filter(price => (price != null) && (price !== ''))[0];
  }

  getPriceFor = (tagId, deviceType, bids) => {
    const contractCpcs = pricesPerVertical(this.props.contract);

    if (tagId in firstCategoryLevel) {
      return contractCpcs[firstCategoryLevel[tagId]][deviceType].value;
    }

    if (tagId in bids
      && deviceType in bids[tagId]) {
      const bid = parseMonetaryValue(bids[tagId][deviceType]);
      if (!isNaN(bid)) return bid;
    }

    return undefined;
  }

  getSavedPrice = (tagId, deviceType) => {
    const savedPrices = pricesPerTag(this.props.saved);
    if (tagId in savedPrices) {
      return savedPrices[tagId][deviceType];
    } else {
      return null;
    }
  }

  renderHeader = () => {
    return (
      <div className="flex justify-between pr1 mt2">
        <div></div>
        <div className="mxn1 flex justify-between min-width-3 bold">
          <div className="inline-block color-transparent cursor-disable">
            {currencySymbol(this.props.contract.currency)}
          </div>
          {DEVICE_TYPES.map(dt => (
            <div key={dt} className="mx1 px1 flex-1">{dt}</div>
          ))}
        </div>
      </div>
    );
  }

  row = ({ node, level, getValue, onChange, currentInput, errors }) => {
    const value = (dt) => getValue(`${node.id}.${dt}`);

    const isTopLevelCategory = (level === 1);
    return (!(node.id in firstCategoryLevel) &&
      <div className={
        classname(
          'flex justify-between items-baseline py1 pr1 hover-bg-gray-muted',
          { 'pl2 mt2': isTopLevelCategory, 'pl3': !isTopLevelCategory }
        )
      }>
        <div className={classname({ 'bold underline': isTopLevelCategory })}>{node.name}</div>
        <div className="mxn1 flex justify-between items-baseline flex-wrap min-width-3">
          <div className="inline-block">
            {currencySymbol(this.props.contract.currency)}
          </div>
          {DEVICE_TYPES.map(dt => {
            const nodeName = `${node.id}.${dt}`;
            const error = errors[nodeName];

            return (
              <div key={dt}>
                <input
                  type="text"
                  step="0.01"
                  name={nodeName}
                  placeholder={this.getEffectivePrice(node.id, dt, currentInput)}
                  value={value(dt) || ''}
                  onChange={onChange}
                  disabled={this.props.categoryBidImportEnabled}
                  className={
                    classname(
                      'mx1 field is-small is-inline',
                      { 'is-error': error, 'is-success': !isNaN(parseMonetaryValue(value(dt))) }
                    )
                  } />
                {error && <div className="h6 center color-danger">{error}</div>}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  save = (data) => {
    const bids = omitEmptyBids(data);
    const payload = Object.assign(bids, { currency: this.props.contract.currency });
    const tagsMap = this.tagsMap();

    // add the vertical information to payload
    Object.keys(payload).forEach(key => {
      if (!isNaN(key) && typeof payload[key] === 'object' && key in tagsMap) {
        payload[key] = {
          device_types_with_prices: payload[key],
          vertical: tagsMap[key].path.slice().pop(),
        };
      }
    });

    return this.props.submitAction(payload);
  };

  validator = () => {
    const contractPrices = pricesPerVertical(this.props.contract);
    return state => {
      const errors = {};
      Object.keys(state).forEach(tagId => {
        Object.keys(state[tagId]).forEach(deviceName => {
          const verticalId = this.tagsMap()[tagId].path.slice().pop();
          const verticalName = firstCategoryLevel[verticalId];
          const rawBidAmount = state[tagId][deviceName];
          const bidAmount = parseMonetaryValue(rawBidAmount);
          const fieldName = `${tagId}.${deviceName}`;
          const minPrice = contractPrices[verticalName][deviceName].value;
          const maxPrice = contractPrices[verticalName][deviceName].maxValue;

          if (isNaN(bidAmount) && rawBidAmount !== '') {
            errors[fieldName] = 'Invalid monetary value';
          } else if (bidAmount < minPrice) {
            errors[fieldName] = `Must be at least ${minPrice}`;
          } else if (bidAmount > maxPrice) {
            errors[fieldName] = `Cannot be higher than ${maxPrice}`;
          }
        });
      });
      return errors;
    };
  };

  actions = () => {
    const hasBids = (
      this.props.saved && this.props.saved.prices && this.props.saved.prices.length);
    const consentMsg = 'By clicking "Save" I hereby activate the applied Cost Per Clicks';

    if (!hasBids) {
      return (
        <div className="my1">{consentMsg}</div>
      );
    }

    return (
      <div>
        <div>{consentMsg}</div>
        <ActionButton
          label="Remove all category CPCs (effective from midnight UTC)"
          level="danger"
          ghost
          action={this.props.stopBidding}
        />
      </div>

    );
  }

  render() {
    return (
      <Forms.Wrapper
        categoryBidImportEnabled={this.props.categoryBidImportEnabled}
        defaultData={this.defaultData()}
        onSubmit={this.save}
        validateOnChange={true}
        validate={!this.props.categoryBidImportEnabled ? this.validator() : null}
        stateEqual={fuzzyEqual}
        disableReset={true}
        renderCustomActions={this.actions}
        replaceCommas={true}
        validateOnLoad={true}
      >
        {({ errors, getValue, onChange, data }) => (
          <div className="scroll-y mb2">
            <TreeView
              tree={this.allowedCategories().map(flattenCategoryFolder)}
              renderHeader={this.renderHeader}
              renderNode={this.row}
              renderLeaf={this.row}
              expanded={this.isExpanded}
              getValue={getValue}
              onChange={onChange}
              errors={errors}
              currentInput={data}
            />
          </div>
        )}
      </Forms.Wrapper>
    );
  }
}

CategoryCpcsForm.propTypes = {
  saved: schema.CategoryCPCCollection,
  contract: schema.CPCContract.isRequired,
  categories: PropTypes.arrayOf(schema.CategoryFolder).isRequired,
  submitAction: PropTypes.func.isRequired,
  stopBidding: PropTypes.func.isRequired,
  categoryBidImportEnabled: PropTypes.bool.isRequired,
};
