/**
 * Serialization utilities.
 */

import queryString from 'query-string';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';

/**
 * Serialize a state object as a string.
 *
 * @param  {Object} state
 *  The state object to serialize.
 * @return {string}
 *  The serialized state.
 */
export function serialize(state) {

  if (state === null || typeof state !== 'object') {
    throw new TypeError(
      `Expected provided \`state\` to be \`Object\` but received ${state}`
    );
  }

  // We need to manually serialize the selectedFilters, as it is an Object.
  const selectedFilters = Object.keys(state.selectedFilters)
    .reduce(
      (data, key) => ({
        ...data,
        [`selectedFilters[${key}]`]:
          state.selectedFilters[key],
      }),
      {}
  );

  return queryString.stringify({
    ..._omit(state, 'selectedFilters'),
    ...selectedFilters,
    fromDate: state.fromDate.toISOString(),
    toDate: state.toDate.toISOString(),
  }, {
    arrayFormat: 'index',
  });
}

const CAST = {
  shops: Number,
};

/**
 * Deserialize a string to a state object.
 *
 * @param  {Object} initialState
 *  The initial state object.
 * @param  {string} serializedState
 *  The state string to deserialize.
 * @return {Object}
 *  The deserialized state object.
 */
export function deserialize(serializedState, initialState) {
  const qs = queryString.parse(
    serializedState,
    {arrayFormat: 'index'}
  );

  if (qs.fromDate) {
    qs.fromDate = new Date(qs.fromDate);
    if (Number.isNaN(qs.fromDate.getTime())) {
      throw new Error('Failed to parse `fromDate`');
    }
  }

  if (qs.toDate) {
    qs.toDate = new Date(qs.toDate);
    if (Number.isNaN(qs.toDate.getTime())) {
      throw new Error('Failed to parse `toDate`');
    }
  }

  // Deserialize the selectedFilters.
  qs.selectedFilters = Object.keys(
    initialState.selectedFilters
  ).reduce(
    (m, key) => {
      return {...m, [key]: []};
    },
    {}
  );

  Object.keys(initialState.selectedFilters).forEach((filterKey) => {
    const qsFilterKey = `selectedFilters[${filterKey}]`;
    if (qs[qsFilterKey]) {
      qs.selectedFilters[filterKey] = qs[qsFilterKey].map(
        CAST[filterKey] || (x => x)
      );
      delete qs[qsFilterKey];
    }
  });

  if (_isEqual(initialState.selectedFilters, qs.selectedFilters)) {
    delete qs.selectedFilters;
  }

  return {
      ...initialState,
      ...qs,
  };
}
