/**
 * Thin wrapper around `superagent` to work with our JSON-API like structure
 * and add efficient caching / safe deduplication for GET requests.
 *
 * All requests will be assumed to be JSON and present a structure with a
 * `data` key (success) or an `error` key (failure). (Valid for JSON-API spec
 * and GraphQL spec). For anything else, use `superagent` directly.
 */
import request from 'superagent';

import { hasNestedProperty, getNestedProperty } from './objects';
import {logout} from '../auth';

const debug = (error, ...args) => {
  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    const func = error ? console.warn.bind(console) : console.info.bind(console);
    func(...args);
  }
};

/**
 * @class HttpError
 * Custom error class for JSON API Entries,
 * represent an API received error for which we have information in the
 * resoonse.
 */
export function HttpError(message, response) {
  this.name = 'HttpError';
  this.response = response;
  this.stack = (new Error()).stack;

  this.message = message || `API responded with status ${response.status}`;
  const error = getNestedProperty(response.body, 'error');

  if (typeof (error) === 'string') {
    this.message = error;
  }

  if (hasNestedProperty(response, 'body.errors')) {
    if (response.body.errors && response.body.errors.length === 1) {
      this.message += `: ${response.body.errors[0].title}`;
    } else if (response.body.errors) {
      this.message += `:\n${response.body.errors.map(err => `- ${err.title}`).join('\n')}`;
    }
  }

  const status = getNestedProperty(response, 'status');
  const location = getNestedProperty(response, 'header.location');

  if (status === 503 && location === '/maintenance') {
    logout().then(() => window.location.assign('/'));
  }
}

HttpError.prototype = Object.create(Error.prototype);
HttpError.prototype.constructor = HttpError;

/**
 * Start XHTML Request and resolve / rejects according to the json api spec
 *
 * @param {"GET"|"POST"|"PUT"|"PATCH"|"DELETE"} method
 * @param {!String} url
 * @param {?Object} query
 * @param {?Object} data
 * @param {?Object} opts
 * @return Promise<Object | Array>
 */
export function call(method, url, query, data, opts = {}) {

  if (['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].indexOf(method.toUpperCase()) === -1) {
    throw new Error('Wrapper only support GET, POST, PUT and PATCH requests.');
  }

  const { cache, json = true } = opts;

  if (cache && method.toUpperCase() !== 'GET') {
    throw new Error('Cannot cache non GET requests.');
  }

  const hash = JSON.stringify({ url, query });
  if (cache && cache[hash]) {
    if (cache[hash].timestamp > Date.now() - (cache.__timeout__ || 500)) {
      return cache[hash].future;
    } else {
      // eslint-disable-next-line no-param-reassign
      delete cache[hash];
    }
  }

  let req = request(method.toUpperCase(), url).set('X-Requested-With', 'xmlhttprequest');
  if (json) {
    req = req.type('application/json').accept('application/json');
  }

  if (query) {
    req = req.query(query);
  }

  if (data && method === 'GET') {
    throw new Error('`data` argument incompatible with GET requests.');
  } else if (data) {
    req = req.send(JSON.stringify(data));
  }


  // Manual promise creation so we can abort without rejecting or resolving
  const future = new Promise((resolve, reject) => {
    const startTs = performance.now();
    return req.end((err, response) => {
      const endTs = performance.now();

      if (err && !response) {
        return reject(err);
      }

      const isError = (err || response.statusType === 4 || response.statusType === 5);
      debug(isError, `(${response.status}) ${method.toUpperCase()} Request to ${url} took ${endTs - startTs} ms.`);

      if (isError) {
        if (cache) delete cache[hash];
        return reject(new HttpError(null, response));
      }

      return resolve(response);
    });
  });

  if (cache) {
    // eslint-disable-next-line no-param-reassign
    cache[hash] = {
      timestamp: Date.now(),
      future: future,
    };
  }

  return future;
}

const GLOBAL_GET_REQUEST_CACHE = {};

// Actively disable caching of get requests with `opts.cache = null`.
export const get = (url, _query, opts = {}) => {
  const _cache = (
    opts.cache === undefined
      ? GLOBAL_GET_REQUEST_CACHE
      : opts.cache
  );
  return call('GET', url, _query, null, { ...opts, cache: _cache });
};

export const post = (...args) => call('POST', ...args);
export const put = (...args) => call('PUT', ...args);
export const patch = (...args) => call('PATCH', ...args);
export const del = (...args) => call('DELETE', ...args);


/**
 *
 * Retry an http call on failure.
 * Will react to `HttpError` and resend the request.
 *
 * @param {() => Promise<any, HttpError>)} func
 *        Function that returns a promise of the same type as `call`.
 * @param {{
 *   statusCodes: number[],
 *   maxCalls: number
 * }} {
 *   statusCodes: list of status codes to retry on,
 *   maxCalls: retry at most `maxCalls`
 * }
 * @returns Promise<any, any>
 */
export function retryOnFailure(func, { statusCodes, maxCalls = 2 } = {}) {
  return new Promise((resolve, reject) => {
    let calls = 0, onFailure;

    const shouldRetry = response => (
      calls < maxCalls
      && (statusCodes == null || statusCodes.indexOf(response.status) !== -1)
    );

    const _call = () => (calls += 1) && func().then(resolve, onFailure);

    onFailure = err => {
      if (err instanceof HttpError && err.response && shouldRetry(err.response)) {
        return _call();
      } else {
        return reject(err);
      }
    };

    _call();
  });
}

export function handle401(err, loginEndpoint) {
  const status = err.status || (err.response && err.response.status);
  if (status === 401) {
    window.location = `${loginEndpoint}?error=Your%20session%20expired.%20Please%20log%20in%20again&redirect=%2F`;
  } else {
    throw err;
  }
}

export function parseJSONAPI(response) {

  const body = response.body;

  // Add any relevant HTTP Status Codes which doesn't have a body to the below array so
  // that we do not raise an Error for not containing body in the response
  const codesWithoutBody = [202];
  if (!codesWithoutBody.includes(response.status) && !body) {  // Body couldn't be parsed
    throw new HttpError('API response was not JSON.', response);
  }

  // Not the same as null which is explicit...
  if (body && body.data === undefined) {
    throw new HttpError('Response body missing `data` key.', response);
  }

  return {
    data: body && body.data ? body.data : undefined,
    meta: body && body.meta ? body.meta : undefined,
    response: response,
  };
}
