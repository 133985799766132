import React from 'react';
import StylightLogo from './StylightLogo';

export default function MaintenanceState() {
  return (
      <div className="flex items-center justify-around flex-1">
        <div className="flex-none fill" style={{maxWidth: '470px'}}>
          <StylightLogo small={false}/>
          <div className="p2 fill">
            <p className="bold h1 bold">Temporarily Down for Maintenance</p>
            <p className="h3">We are performing scheduled maintenance. We should be back shortly.</p>
          </div>
      </div>
    </div>
  );
}
