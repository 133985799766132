import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';

import {Collapse, Icon} from '../../..';
import classname from '../../../../utils/classname';

export default class FilterCard extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      open: this.props.keepOpen || this.props.startOpen,
    };
  }

  toggle = () => this.setState({
    open: this.props.keepOpen || !this.state.open,
  });

  render() {
    const {keepOpen, children, title, message} = this.props;
    const {open} = this.state;

    return (
      <div className="rounded m1 border-color-gray-lighter border">

        <div className={classname('px1 pb1 pt2', {'cursor-pointer': !keepOpen})} onClick={this.toggle}>
          <div className="flex justify-between">
            <div className="medium">
              {title}
            </div>
            <div>
              { !keepOpen &&
                <Icon
                  size="small"
                  text={this.state.open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                /> }
              </div>
          </div>
          {message && <div className="flex-auto">
            {message}
          </div>}
        </div>

        <Collapse open={open}>
          <div className="p1">
            {children}
          </div>
        </Collapse>
      </div>
    );
  }
}

FilterCard.propTypes = {
  title: PropTypes.string.isRequired,
  startOpen: PropTypes.bool,
  keepOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  message: PropTypes.node,
};
