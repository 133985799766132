import * as Datagrid from '../../../../components/datagrid';
import * as _date from '../../../../utils/date';
import * as _sentry from '../../../../sentry';
import * as _config from '../../../../config';
import * as gql from '../../../../utils/graphql';
import * as http from '../../../../utils/http';

const QUERY = `
query ProductReportEntriesQuery(
  $fromDate: ISODateTime!,
  $toDate: ISODateTime!,
  $offset: Int!,
  $limit: Int!,
  $shopId: Int!,
  $sortBy: String!,
  $reportTypes: [ProductClickReportType!],
) {
  report: CustomerProductClickReports(
    offset: $offset,
    limit: $limit,
    from_date: $fromDate,
    to_date: $toDate,
    sort_by: $sortBy,
    filters: {
      shop_ids: [$shopId],
      report_types: $reportTypes,
    },
  ) {
    items: entries {
      report_type
      start_day
      end_day
      report_url
    },
    pagination: page_info {
      count
    }
  }
}
`;

export const makeProductReportQuery = (variables) => {
  return http.retryOnFailure(
    () => gql.request(_config.apiServiceUrl(), QUERY, variables),
    {
      maxCalls: 2,
      statusCodes: [502, 504],
    }
  ).then(data => data.report).catch(err => {
    http.handle401(err, '/login');
    _sentry.captureException(err, {level: 'error', extra: {variables}});
  });
};

export const queryVariables = opts => {
  return {
    offset: opts.offset,
    limit: opts.limit,
    fromDate: _date.toNaiveISOString(opts.fromDate),
    toDate: _date.toNaiveISOString(opts.toDate),
    sortBy: Datagrid.utils.buildSortString(opts.sortList),
    shopId: opts.shopId,
    reportTypes: opts.selectedFilters.reportTypes,
  };
};
