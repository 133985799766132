import React, {PureComponent} from 'react';
import * as _dateFns from 'date-fns';
import Conveyor from 'react-conveyor';

import * as schema from '../../../../components/prop-types/schema';
import * as staticData from '../../../../static-data';
import StateBuffer from '../../../../components/StateBuffer';
import QueryStringSync from '../../../../components/QueryStringSync';
import {serialize, deserialize} from '../../../../components/views/configuration-serializer';
import RedScreenOfDeath from '../../../../components/RedScreenOfDeath';
import LoadingState from '../../../../components/LoadingState';

import Banner from './Banner';
import Report from './Report';
import Filters from './Filters';
import Summary from './PerformanceSummary';

const DEFAULT_CONFIGURATION = {
  toDate: _dateFns.startOfYesterday(),
  fromDate: _dateFns.addDays(_dateFns.startOfYesterday(), -7),
  resolution: 'day',
  selectedGroupBy: ['date', 'device_type'],
  targetCurrency: 'EUR',
  selectedFilters: {
    verticals: [],
    deviceTypes: [],
    categories: [],
  },
};

const CONVEYOR_CONF = staticData.conveyorConfiguration('enums', 'categories', 'categoryFolders');
const BANNER_DATA = {
  src: '/images/maximize-your-roi-with-one-simple-solution.jpg',
  link: 'https://partner.stylight.net/help-center/uncategorized/maximize-your-roi-with-one-simple-solution/?utm_source=spc&utm_medium=spcbanner&utm_campaign=salestracking',
  alt: 'Your competitors now know something you don\'t know! Here is the secret to maximizing their ROI',
  startDate: new Date('2022-8-30'),
  endDate: new Date('2022-10-1'),
};

export default class ReportSection extends PureComponent {
  render() {
    const shopId = this.props.user.meta.shop_id;
    return (
      <Conveyor {...CONVEYOR_CONF}>
        { ({inFlight, missing, errors, enums, categories, categoryFolders}) => {
          if (inFlight || missing) {
            return <LoadingState/>;
          } else if (errors) {
            return <RedScreenOfDeath className="flex-auto scroll-y" errors={errors}/>;
          } else {
            return (
              <QueryStringSync
                defaultState={DEFAULT_CONFIGURATION}
                queryString={this.props.location.search}
                serialize={serialize}
                deserialize={deserialize}
              >
                { ({update: updateConfiguration, reset: _, ...currentConfiguration}) => (  // eslint-disable-line  no-unused-vars
                  <div className="flex flex-1 flex-row fill">
                    <div className="p1 flex-1 scroll-y" style={{minWidth: 242}}>
                      <StateBuffer
                        defaultState={currentConfiguration}
                        onApply={updateConfiguration}
                      >
                          { ({state: configuration, update, apply}) => (
                          <Filters
                            onChange={update}
                            onApply={apply}
                            {...configuration}
                            availableFilters={enums}
                            resolutions={enums.partnerReportResolutions}
                            availableGroupBy={enums.partnerReportGroupBy}
                            availableCurrencies={enums.currencies}
                            categoryFolders={categoryFolders}
                          />
                        ) }
                      </StateBuffer>
                      <div className="ad_survey px1">
                        <Banner {...BANNER_DATA} />
                      </div>
                    </div>
                    <div className="p1 flex flex-column flex-4 scroll-x">
                      <Summary {...currentConfiguration} shopId={shopId}/>
                      <Report
                        {...currentConfiguration}
                        shopId={shopId}
                        categories={categories}
                        availableGroupBy={enums.partnerReportGroupBy}
                      />
                    </div>
                  </div>
                ) }
              </QueryStringSync>
            );
          }
        } }
        </Conveyor>
    );
  }
}

ReportSection.propTypes = {
  user: schema.ExternalUser.isRequired,
  location: schema.RouterLocation.isRequired,
};
