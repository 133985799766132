/**
 * Utilities to help creating layouts based on flexbox inside the application.
 */
import React from 'react';
import PropTypes from 'prop-types';

import classname from '../utils/classname';
import {omit} from '../utils/objects';

const omitProps = (props, cls) => omit(props, Object.keys(cls.propTypes || {}));

export const VerticalViewport = (props) => (
<div className={classname('flex flex-column viewport', props.className)}
     {...omitProps(props, VerticalViewport)}/>
);

VerticalViewport.propTypes = {
  className: PropTypes.string,
};

export const HorizontalViewport = (props) => (
  <div className={classname('flex flex-row viewport', props.className)}
       {...omitProps(props, HorizontalViewport)}/>
);

HorizontalViewport.propTypes = {
  className: PropTypes.string,
};

export const Filler = (props) => (
  <div className={classname('flex flex-auto', {
    'scroll-x': props.scrollX,
    'scroll-y': props.scrollY,
    'flex-wrap': props.wrap,
    'flex-column': props.vertical,
    'flex-column-reverse': props.vertical && props.reverse,
    'flex-row-reverse': !props.vertical && props.reverse,
  }, props.className)} {...omitProps(props, Filler)}/>
);

Filler.propTypes = {
  className: PropTypes.string,
  scrollX: PropTypes.bool,
  scrollY: PropTypes.bool,
  wrap: PropTypes.bool,
  vertical: PropTypes.bool,
  reverse: PropTypes.bool,
};

export function Card (props) {
  return (
    <div className={classname('border border-color-gray-lighter rounded', props.className)}>
      { !!props.title &&
        <div className="p1 border-bottom bold h4 border-color-gray-lighter">
          { props.title }
        </div> }
      <div className="p1">{ props.children }</div>
    </div>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};
