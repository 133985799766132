import {chainableValidatorFactory} from './_utils';

/**
 * Validator factory to check strict equality of the prop with
 * a given constant.
 *
 * @param {any} constValue
 * @return {(Object, string, ...Array<any>) => Error|null}
 */
export default chainableValidatorFactory(
  function constantValidatorFactory (constValue) {
    return function constantValidator (props, propName, componentName) {
      const value = props[propName];
      if (value !== constValue) {
        return new Error(
          `Expected prop \`${propName}\` in \`${componentName || 'ANONYMOUS'}\` to be equal to \`${constValue}\` but got \`${value}\` instead.`
        );
      }
      return null;
    };
  }
);
