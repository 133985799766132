import React from 'react';
import PropTypes from 'prop-types';

import * as utils from '../utils';

import Icon from './Icon';

/**
 * Button wrapper.
 */
const Button = (props) => {

  const _props = Object.assign({},
    utils.objects.omit(props, Object.keys(Button.propTypes)),
    {
      disabled: props.disabled,
      className: utils.classname('btn', {
        'is-disabled': props.disabled,
        'is-small': props.small,
        'is-large': props.large,
        'is-ghost': props.ghost,
        'is-outline': props.outline,
        'block fill': props.block,
        'no-focus': props.noFocus,
        'pill': props.pill,
        'layer-2 hover-layer-8': props.shadow,
        [`is-${props.level}`]: props.level,
      }, props.className),
      type: props.type,
      onClick: !props.disabled ? props.onClick : null,
    },
  );

  const maybeIcon = props.icon ? (
    <Icon
      size={props.small ? 'mini' : 'small'}
      style={{verticalAlign: 'top'}}
      text={props.icon}
      className={utils.classname({
        mr1: props.children && props.iconPosition === 'left',
        ml1: props.children && props.iconPosition === 'right',
      })}
    />
    ) : null;

  const content = (
    <div className={utils.classname('flex items-center', {
      'justify-start': props.leftAlignContent,
      'justify-end': props.rightAlignContent,
      'justify-center': !(props.leftAlignContent || props.rightAlignContent),
    })}>
      { props.iconPosition === 'left' && maybeIcon}
      {props.children}
      { props.iconPosition === 'right' && maybeIcon}
    </div>
  );

  if (props.href) {
    return <a {..._props} href={props.href}>{content}</a>;
  }
  return <button {..._props}>{content}</button>;
};

Button.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  icon: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  pill: PropTypes.bool,
  block: PropTypes.bool,
  ghost: PropTypes.bool,
  outline: PropTypes.bool,
  shadow: PropTypes.bool,
  leftAlignContent: PropTypes.bool,
  rightAlignContent: PropTypes.bool,
  noFocus: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['right', 'left']),
  level: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success']),
};

Button.defaultProps = {
  disabled: false,
  iconPosition: 'left',
  type: 'button',
};


export default Button;
