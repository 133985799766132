/**
 * This is a stricter version of parseFloat. It imposes the following
 * additional restrictions:
 *  - Only digits and the decimal point are allowed characters
 *  - Numbers cannot start or end with a decimal point
 *  - Up to two digits after the decimal point
 */
export function parseMonetaryValue(value) {
  if (/^\d+(\.\d\d?)?$/.test(value)) {
    return Number(value);
  }
  return NaN;
}

/**
 * @description Formats a monetary value.
 * @param {String | Number} value An entry to be fromatted as Float.
 * @param {*} decimals Number of decimals to be used.
 * @returns {String} A monetary formatted value.
 */
export const formatMonetaryValue = (value, decimals = 2) => {
  return parseFloat(value).toFixed(decimals);
};
