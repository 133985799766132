import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { shallowEqual, omit } from '../../../utils/objects';

import DatagridPropTypes from '../prop-types';
import { updateSortList, sortListToMap, nextSortDirection } from '../utils';

import FixedTable from './FixedTable';
import Controls from './Controls';

/**
 * @component: Container
 * Orchestrate async flow for datagrid loading.
 *
 * Customisation of loading behaviour happens through the `fetch` prop,
 * which is an adapter function, see `./adapters` for implementations...
 *
 * Any prop that is not defined in the propTypes will be passed down to
 * `children` and up to `fetch`.
 */
export default class Container extends PureComponent {

  static propTypes = {
    children: PropTypes.func.isRequired,  // FaC pattern!

    // (Object, Object?) => Promise<{items, pagination}>
    fetch: PropTypes.func.isRequired,
    mapParameters: PropTypes.func,
    onFetchError: PropTypes.func, // (Error) => void

    defaultPageSize: PropTypes.number,
    defaultOffset: PropTypes.number,
    defaultSortList: DatagridPropTypes.SortList,
    defaultSearch: PropTypes.string,
    disableMultiSort: PropTypes.bool,
  };

  static defaultProps = {
    defaultPageSize: 25,
    defaultOffset: 0,
    defaultSortList: [],
    defaultSearch: '',
    disableMultiSort: false,
    children: defaultDatagridDisplay,
    mapParameters: x => x,
  };

  constructor(...args) {
    super(...args);

    const initParams = {
      offset: this.props.defaultOffset,
      limit: this.props.defaultPageSize,
      sortList: this.props.defaultSortList,
      search: this.props.defaultSearch,
    };

    this.state = {
      loading: true,
      error: null,
      prevParameters: initParams,
      parameters: initParams,
      items: [],
      pagination: null,
    };

    this._parameterKeys = Object.keys(this.state.parameters);
    this._ownPropNames = Object.keys(Container.propTypes);

    // Used to prevent slow request overriding a newer request if it resolves
    // afterwards, `_fetch` will check for that the only the request
    // corresponding to current count actually does anything.
    this._requestCount = -1;
    this._latestRequest = -1;
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this._isMounted) return;

    const { props } = this;
    const nextParameters = {};
    const currentParameters = this.state.parameters;

    // Update current parameters (state) if the default ones (props) change.
    this._parameterKeys.forEach((key) => {
      const defaultKey = `default${key.charAt(0).toUpperCase()}${key.slice(1)}`;
      const nextDefault = nextProps[defaultKey];

      if (
        // Requested default needs to have changed
        nextDefault !== props[defaultKey] &&
        // Requested default needs to differ from current, otherwise consider
        // the current data as truth.
        nextDefault !== currentParameters[key]
      ) {
        nextParameters[key] = nextDefault;
      } else {
        nextParameters[key] = currentParameters[key];
      }
    });

    if (
      nextProps.fetch !== this.props.fetch ||
      nextProps.mapParameters !== this.props.mapParameters
    ) {
      this._requestCount = -1;
    }

    if (!shallowEqual(nextParameters, currentParameters)) {
      this.setState({
        parameters: { ...currentParameters, ...nextParameters },
        prevParameters: currentParameters,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const fetchArgsDiffers = !shallowEqual(
      this._fetchArgs(prevProps, prevState),
      this._fetchArgs(this.props, this.state)
    );

    if (prevProps.fetch !== this.props.fetch || fetchArgsDiffers) {
      this._fetch();
    }
  }

  reload = () => {
    this._fetch();
  }

  // In case we want to feed props back up, this avoids that we leak internal
  // props, essentially coupling caller's ancestors with this component.
  _extraProps = (props) => {
    return omit(props, this._ownPropNames);
  }

  _fetchArgs = (props, state) => {
    return props.mapParameters({
      ...this._extraProps(props),
      ...state.parameters,
    });
  };

  _fetch() {
    if (!this._isMounted) return;

    this.setState({ loading: true, error: null });

    const currentRequest = this._requestCount += 1;

    const shouldDrop = () => (!this._isMounted || currentRequest < this._latestRequest);

    this.props.fetch(this._fetchArgs(this.props, this.state)).then(
      (data) => {
        if (shouldDrop()) return;
        this._latestRequest = currentRequest;

        if (data) {
          this.setState({
            loading: false,
            items: data && data.items ? data.items : [],
            pagination: {
              offset: this.state.parameters.offset,
              limit: this.state.parameters.limit,
              ...(data && data.pagination ? data.pagination : {} || {}),
            },
          });
        }
        else {
          this.setState({
            loading: false,
            items: this.state.items,
            pagination: this.state.pagination,
          });
        }

      },

      (err) => {
        if (shouldDrop()) return;
        if (this.props.onFetchError) this.props.onFetchError(err);
        this.setState({
          loading: false,
          error: err,
          parameters: this.state.prevParameters,
        });
      }
    );
  }

  onUpdateParameter = ({ name, value }) => {
    if (!this._isMounted) return;
    this.setState(state => {
      const next = {};
      if (name === 'sort') {
        const colname = value;
        const sortMap = sortListToMap(state.parameters.sortList);
        const nextDirection = nextSortDirection(sortMap[colname] || 0);
        next.sortList = updateSortList(
          state.parameters.sortList,
          colname,
          nextDirection,
          !this.props.disableMultiSort
        );
      } else if (name === 'pagination') {
        const { limit, offset } = value;
        Object.assign(next, {
          limit: limit == null ? state.parameters.limit : limit,
          offset: offset == null ? state.parameters.offset : offset,
        });
      } else if (name === 'search') {
        next.search = value;
      } else {
        throw Error(`Unrecognized parameter key ${name}`);
      }

      if (Object.keys(next).length > 0) {
        return {
          ...state,
          prevParameters: state.parameters,
          parameters: {
            ...state.parameters,
            ...next,
          },
        };
      } else {
        return state;
      }
    });
  }

  render() {
    return this.props.children({
      loading: this.state.loading,
      error: this.state.error,
      reload: this.reload,
      onUpdateParameter: this.onUpdateParameter,
      items: this.state.items,
      pagination: this.state.pagination,
      parameters: this.state.parameters,
      ...this._extraProps(this.props),
    });
  }
}

function defaultDatagridDisplay(props) {
  return (
    <div>
      <Controls {...props} />
      <FixedTable {...props} />
    </div>
  );
}
