import React from 'react';
import * as ReactRouter from 'react-router-dom';
import PropTypes from 'prop-types';

import CustomPropTypes from './prop-types';
import classname from '../utils/classname';


/**
 * Simple material like tab component.
 *
 * In order of priority, you can either specify `onClick` to react
 * programmatically, or `path` to create a `ReactRouter.Link` component
 * (need to be inside a `ReactRouter.Router` context) or `href` to create a
 * regular `a.href` link.
 */
export function Tab(props) {
  const {text, large, onClick, path, href, active, className, ...rest} = props;

  const cls = classname(
    'inline-block text-decoration-none transition-colors hover-color-primary',
    'border-bottom border-width-2',
    'color-current transition-colors',
    {
      'border-color-primary': active,
      'border-color-transparent hover-border-color-gray-lightest': !active,
      'py2': large,
      'py1': !large,
    },
    className,
  );

  if (onClick) {
    return <div {...rest} className={cls} onClick={onClick}>{text}</div>;
  } else if (path) {
    return (
      <ReactRouter.Link {...rest} className={cls} to={path}>
        {text}
      </ReactRouter.Link>
    );
  } else if (href) {
    return <a {...rest} className={cls} href={href}>{text}</a>;
  }
}

Tab.propTypes = {
  text: PropTypes.string.isRequired,
  large: PropTypes.bool,
  active: PropTypes.bool,
  onClick: CustomPropTypes.requiredUnless(
    PropTypes.func,
    props => !!(props.path || props.href)
  ),
  path: CustomPropTypes.requiredUnless(
    PropTypes.string,
    props => !!(props.onClick || props.href)
  ),
  href: CustomPropTypes.requiredUnless(
    PropTypes.string,
    props => !!(props.onClick || props.path)
  ),
  className: PropTypes.string,
};

/**
 * Simple rows of `Tab` components.
 */
export function Tabs(props) {
  const {tabs, className, large, ...rest} = props;
  return (
    <div className={classname(className)} {...rest}>
      <div className="mxn1">
        {tabs.map((tab, index) =>
          <Tab large={large} key={index} {...tab} className={classname('mx1', tab.className)}/>
        )}
      </div>
    </div>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape(Tab.PropTypes)).isRequired,
  className: PropTypes.string,
  large: PropTypes.bool,
};
