/**
 * Generate a single, deduplicated classname string from a variable list
 * of argument compatible with `classnames` (strings, arrays, etc.).
 *
 * @param  {...(string | Array<string> | Object)} entries
 * @return {string}
 */
export default function classname(...entries) {
  return entries.reduce(
    (seen, cls) => {
      classnames(cls).forEach((c) => {
        if (seen.indexOf(c) === -1) {
          seen.push(c);
        }
      });
      return seen;
    },
    []
  ).join(' ');
}


/**
 * Filter classnames from arrays, objects or strings.
 *
 * @param  {string | Array<string> | Object} [cls]
 * @return {Array<string>}
 */
function classnames(cls) {
  if (!cls) {
    return [];
  } else if (typeof cls === 'string') {
    return cls.split(' ').filter(x => !!x);
  } else if (Array.isArray(cls)) {
    return (
      cls
      .map(classnames)
      .reduce((a, b) => a.concat(b), [])
    );
  } else if (typeof cls === 'object') {
    const src = cls;
    return (
      Object.getOwnPropertyNames(src)
      .filter(prop => prop && src[prop])
      .map(classnames)
      .reduce((flat, arr) => [...flat, ...arr], [])
    );
  } else {
    throw new Error(`classname: invalid value ${cls} of type ${typeof cls}`);
  }
}
