import React from 'react';
import PropTypes from 'prop-types';
import Conveyor from 'react-conveyor';
import * as Recharts from 'recharts';

import * as _date from '../../../../utils/date';
import * as _strings from '../../../../utils/strings';

import * as schema from '../../../../components/prop-types/schema';
import * as colors from '../../../../components/charting/colors';

import Spinner from '../../../../components/Spinner';

import {makeCustomerReportQuery} from './query';

export class PeformanceSummary extends React.Component {

  comissionTitle = commission => `Total: ${_strings.currencySymbol(this.props.targetCurrency)} ${commission.toFixed(2)} spent`;
  clicksTitle = clicks => `Total: ${clicks.toFixed(0)} clicks`;

  shouldComponentUpdate(nextProps) {
    return ['items', 'errors', 'inFlight', 'missing'].some(key => nextProps[key] !== this.props[key]);
  }

  content = () => {
    if (this.props.inFlight || this.props.missing) {
      return <div className="p1">Loading <Spinner/></div>;
    } else if (this.props.errors) {
      return <div className="p1 color-red">Error</div>;
    } else if (this.props.items && this.props.items.length) {
      return (
        <div className="flex">
          <GraphWidget
            items={this.props.items}
            keyName="ppc_clickout_count"
            label="Clicks"
            color={colors.TEAL}
            height={128}
            titleText={this.clicksTitle}
          />
          <GraphWidget
            items={this.props.items}
            keyName="ppc_commission"
            label="Commission"
            color={colors.PURPLE}
            height={128}
            titleText={this.comissionTitle}
          />
        </div>
      );
    } else {
      return <div className="p1">No traffic for the selected filters.</div>;
    }
  }

  render() {
    return (
      <div className="border border-color-gray-lighter my1">
        <div className="bg-gray-muted border-bottom border-color-gray-lighter p1 medium">
          Summary
        </div>
        {this.content()}
      </div>
    );
  }
}

PeformanceSummary.propTypes = {
  inFlight: PropTypes.arrayOf(PropTypes.string),
  missing: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.objectOf(PropTypes.instanceOf(Error)),
  shopId: schema.Int.isRequired,
  fromDate: schema.MaybeDate.isRequired,
  toDate: schema.MaybeDate.isRequired,
  targetCurrency: schema.Currency.isRequired,
  selectedFilters: PropTypes.shape({
    verticals: PropTypes.arrayOf(schema.Vertical).isRequired,
    deviceTypes: PropTypes.arrayOf(schema.DeviceType).isRequired,
    categories: PropTypes.arrayOf(schema.Int).isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(Object),
};

function fetchItems (opts) {
  return makeCustomerReportQuery(opts).then(response => response.items);
}

function propsToGQLVariables (opts) {
  return {
    from_date: _date.toNaiveISOString(opts.fromDate).slice(0, 10),
    to_date: _date.toNaiveISOString(opts.toDate).slice(0, 10),
    sort_by: 'date',
    category_tag_ids: opts.selectedFilters.categories,
    group_by: ['date'],
    verticals: opts.selectedFilters.verticals,
    device_types: opts.selectedFilters.deviceTypes,
    currency: opts.targetCurrency,
  };
}

function sumKey(key, items) {
  return items.reduce((sum, item) => sum + item[key], 0);
}

function prepareItems (items, keyName, label = keyName) {
  return items.map(item => ({date: item.date, [label]: item[keyName]}));
}

function GraphWidget(props) {
  if (!props.items || !props.items.length) {
    return <div className="flex-1 flex-column p1 flex"></div>;
  }

  return (
    <div className="flex-1 flex-column p1 flex">
      <div className="color-gray-dark h5 right-align p1">
        {props.titleText(sumKey(props.keyName, props.items))}
      </div>
      <div className="flex-1">
        <Recharts.ResponsiveContainer width="99%" aspect={1 / 0.3}>
          <Recharts.AreaChart data={prepareItems(props.items, props.keyName, props.label)}>
            <Recharts.CartesianGrid stroke="#ccc" strokeDasharray="3 3"/>
            <Recharts.XAxis dataKey="date" tickFormatter={x => new Date(x).toLocaleDateString()}/>
            <Recharts.YAxis/>
            <Recharts.Tooltip/>
            <Recharts.Area isAnimationActive={false}
                            // animationDuration={100}
                           type="monotone"
                           dataKey={props.label}
                           stroke={props.color} fill={props.color}/>
          </Recharts.AreaChart>
        </Recharts.ResponsiveContainer>
      </div>
    </div>
  );
}

GraphWidget.propTypes = {
  color: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  titleText: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(Object).isRequired,
};

export default Conveyor.wrapComponent({
  fields: {items: fetchItems},
  mapPropsToArgs: {items: propsToGQLVariables},
}, PeformanceSummary);
