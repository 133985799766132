import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import * as _dateFns from 'date-fns';

import {DateRangePickerInput} from '../../../date-pickers';

window.__d = _dateFns;

const MIN_DATE = new Date(2015, 12, 1);

const DATE_RANGES = {
  'Yesterday': () => ({
    startDate: _dateFns.startOfYesterday(),
    endDate: _dateFns.startOfYesterday(),
  }),
  'Last 7 Days': () => ({
    startDate: _dateFns.addDays(_dateFns.startOfDay(new Date()), -7),
    endDate: _dateFns.startOfYesterday(),
  }),
  'Last 30 Days': () => ({
    startDate: _dateFns.addDays(_dateFns.startOfDay(new Date()), -30),
    endDate: _dateFns.startOfYesterday(),
  }),
  'Current Week': !_dateFns.isMonday(new Date()) ? () => ({
    endDate: _dateFns.startOfYesterday(),
    startDate: _dateFns.startOfWeek(new Date(), { weekStartsOn: 1 }),
  }) : null,
  'Last Week': () => ({
    endDate: _dateFns.endOfWeek(
      _dateFns.addWeeks(new Date(), -1),
      { weekStartsOn: 1 }
    ),
    startDate: _dateFns.startOfWeek(
      _dateFns.addWeeks(new Date(), -1),
      { weekStartsOn: 1 }
    ),
  }),
  'Current Month': !_dateFns.isFirstDayOfMonth(new Date()) ? () => ({
    endDate: _dateFns.startOfYesterday(),
    startDate: _dateFns.startOfMonth(_dateFns.startOfYesterday()),
  }) : null,
  'Last Month': () => ({
    endDate: _dateFns.addDays(_dateFns.startOfMonth(new Date()), -1),
    startDate: _dateFns.addMonths(_dateFns.startOfMonth(new Date()), -1),
  }),
};

export default class DateRangeSelector extends PureComponent {

  static propTypes = {
    fromDate: PropTypes.instanceOf(Date).isRequired,
    toDate: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    return <DateRangePickerInput
      startDate={this.props.fromDate}
      endDate={this.props.toDate}
      onChange={this.props.onChange}
      namedRanges={DATE_RANGES}
      fromMonth={MIN_DATE}
      toMonth={_dateFns.addDays(new Date(), 1)}
      disabledDays={[{
        after: _dateFns.startOfYesterday(),
      }]}
      initialMonth={_dateFns.addMonths(new Date(), -2)}
      numberOfMonths={3}
    />;
  }
}
