import React from 'react';
import PropTypes from 'prop-types';
import {titleCase} from '../utils/strings';
import Button from './Button';
import * as _sentry from '../sentry';

/**
 * Overlay screen for when things go bad.
 */
export default function RedScreenOfDeath (props) {

  const reloadBtn = (
    <Button ghost icon="refresh" onClick={() => window.location.reload()}>
      Click here to reload
    </Button>
  );

  const {errors} = props;

  if (process.env.NODE_ENV === 'production') {
    _sentry.captureException(errors, {level: 'error'});
    return (
      <div className="p2 fill">
        <p className="bold h1 bold">Uh oh...</p>
        <p className="h3">Something went wrong.</p>
        <p className="h4">
          Please try reloading the page first.
          If the problem persists, you have yourself a bug!<br/>
          <br/><br/>
          {reloadBtn}
        </p>
      </div>
    );
  } else {
    return (
      <div className={`${props.className || ''} fill bg-red color-white p2`}>
        <p className="h2">
          Unexpected error.
        </p>
        <p className="border-bottom border-color-white pb1">
          Try and reload the application. If the problem persists contact BDF.
        </p>
        {Object.keys(errors).map(fragment => (errors[fragment] ? (
          <div key={fragment}>
            {titleCase(fragment)}:
            {
              errors[fragment] instanceof Error ? (
                <pre>
                  {errors[fragment].message}
                  <br/>
                  {errors[fragment].stack}
                </pre>
              ) : (
                <p>{errors[fragment]}</p>
              )
            }
          </div>
        ) : null))}
        <div className="py2">
          {reloadBtn}
        </div>
      </div>
    );
  }
}

RedScreenOfDeath.propTypes = {
  errors: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Error),
    ])
  ).isRequired,
  className: PropTypes.string,
};
