import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import _uniq from 'lodash/uniq';

import * as _func from '../../../../utils/func';
import * as _strings from '../../../../utils/strings';
import CustomPropTypes from '../../../prop-types';
import * as schema from '../../../prop-types/schema';
import {Collapse, Icon, Checkbox} from '../../../../components';

const ItemShape = {
  id: schema.Int.isRequired,
};

const FolderShape = {
  id: schema.Int.isRequired,
  subfolders: PropTypes.arrayOf(
    CustomPropTypes.lazy(() => PropTypes.shape(FolderShape))
  ).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(ItemShape)).isRequired,
};

const SelectionStatus = { SOME: 0, NONE: -1, ALL: 1 };


/**
 * Extract all item ids from a FodlerShape.
 *
 * @param {FolderShape} folder
 * @return {Int[]}
 */
const getAllItemIds = _func.memoize(function getAllItemIds({subfolders = [], items = []} = {}) {
  return subfolders.reduce(
    (acc, _folder) => [...acc, ...getAllItemIds(_folder)],
    items.map(({id}) => id)
  );
});

/**
 *
 */
function getSelectionStatus(folder, selection = []) {
  if (!selection.length) return SelectionStatus.NONE;
  const ids = getAllItemIds(folder);
  const selectedCount = ids.filter(id => selection.indexOf(id) > -1).length;
  if (selectedCount === 0) return SelectionStatus.NONE;
  if (selectedCount === ids.length) return SelectionStatus.ALL;
  return SelectionStatus.SOME;
}

/**
 *
 */
export class TreeSelector extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
  }

  handleSelect = (additions = [], deletions = []) => {
    let next = this.props.selected;
    if (deletions && deletions.length) next = next.filter(id => deletions.indexOf(id) === -1);
    if (additions && additions.length) next = [...next, ...additions];
    if (next !== this.props.selected) {
      this.props.onUpdateSelection(_uniq(next));
    }
  }

  filter = _func.debounce(value => this.setState({filter: value}), 50);
  handleSearchInputChange = evt => this.filter(evt.currentTarget.value);

  render() {
    const {folders, selected, placeholder, noEntryPlaceholder} = this.props;
    const {filter} = this.state;
    const consideredFolders = this.props.filterItems(filter, folders, selected);
    return (
      <div>
        <input type="text" className="field"
               onChange={this.handleSearchInputChange} placeholder={placeholder}/>
        <div className="py1">
          { consideredFolders.length > 0 ? (
            <div style={{maxHeight: 320, overflow: 'auto'}}>
              <Tree
                folders={consideredFolders}
                selected={selected}
                onSelect={this.handleSelect}
                renderItemLabel={this.props.renderItemLabel}
                renderFolderLabel={this.props.renderFolderLabel}
              />
            </div>
          ) : (
            <div>{noEntryPlaceholder}</div>
          ) }
        </div>
      </div>
    );
  }
}

TreeSelector.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape(FolderShape)).isRequired,
  selected: PropTypes.arrayOf(schema.Int).isRequired,
  onUpdateSelection: PropTypes.func.isRequired,
  renderItemLabel: PropTypes.func.isRequired,
  renderFolderLabel: PropTypes.func.isRequired,
  filterItems: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  noEntryPlaceholder: PropTypes.string.isRequired,
};

/**
 * Renders a Tree of selectable folders and items.
 */
export class Tree extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openFolders: {},
    };
  }

  isOpen = (folder) => this.state.openFolders[folder.id];

  toggleFolder = evt => {
    if (!(evt.target.matches('.__js-checkbox__') || evt.target.matches('.__js-checkbox__ *'))) {
      evt.stopPropagation();
      evt.preventDefault();

      const id = evt.currentTarget.getAttribute('data-id');

      this.setState(state => ({
        ...state,
        openFolders: {
          ...state.openFolders,
          [id]: !state.openFolders[id],
        },
      }));
    }
  }

  handleItemCheck = evt => {
    evt.stopPropagation();
    const node = evt.currentTarget;
    const checked = node.checked;
    const id = parseInt(node.getAttribute('data-id'), 10);

    if (checked) {
      this.props.onSelect([id], []);
    } else {
      this.props.onSelect([], [id]);
    }
  }

  render() {
    if (!this.props.folders) return null;
    return (
      <div>
        {this.props.folders.map(folder => (
          <FolderNode
            folder={folder}
            key={folder.id}
            open={this.isOpen(folder)}
            onToggle={this.toggleFolder}
            onItemCheck={this.handleItemCheck}
            onSelect={this.props.onSelect}
            selected={this.props.selected}
            renderItemLabel={this.props.renderItemLabel}
            renderLabel={this.props.renderFolderLabel}
          />
        ))}
      </div>
    );
  }
}

Tree.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape(FolderShape)).isRequired,
  selected: PropTypes.arrayOf(schema.Int).isRequired,
  onSelect: PropTypes.func.isRequired,
  renderItemLabel: PropTypes.func.isRequired,
  renderFolderLabel: PropTypes.func.isRequired,
};

/**
 *
 */
class FolderNode extends PureComponent {

  onSelectAll = () => {
    const selectionStatus = getSelectionStatus(this.props.folder, this.props.selected);
    const ids = getAllItemIds(this.props.folder);
    if (selectionStatus === SelectionStatus.NONE) {
      this.props.onSelect(ids, []);
    } else {
      this.props.onSelect([], ids);
    }
  };

  isItemSelected = id => this.props.selected.indexOf(id) > -1;

  render() {
    const {props} = this;
    const selectionStatus = getSelectionStatus(this.props.folder, this.props.selected);
    return (
      <div>
      <div data-id={props.folder.id} onClick={props.onToggle} className="cursor-pointer">
        <Icon text={props.open ? 'folder_open' : 'folder'} size="small"/>
        <Checkbox
          checked={selectionStatus === SelectionStatus.ALL}
          indeterminate={selectionStatus === SelectionStatus.SOME}
          inline
          style={{marginLeft: '0.35em', marginRight: '0.35em'}}
          onChange={this.onSelectAll}
        />
        <div className="inline-block">{props.renderLabel(props.folder, props)}</div>
      </div>
      <Collapse open={props.open} style={{marginLeft: '1.45em'}}>
        <Tree
          folders={props.folder.subfolders}
          selected={props.selected}
          onSelect={props.onSelect}
          renderItemLabel={props.renderItemLabel}
          renderFolderLabel={props.renderLabel}
        />
        <div>
          {props.folder.items.map(item => (
            <LeafNode
              item={item}
              key={item.id}
              selected={this.isItemSelected(item.id)}
              onSelect={props.onItemCheck}
              renderLabel={props.renderItemLabel}
            />
          ))}
        </div>
      </Collapse>
    </div>
    );
  }
}

FolderNode.propTypes = {
  folder: PropTypes.shape(FolderShape).isRequired,
  renderLabel: PropTypes.func.isRequired,
  renderItemLabel: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(schema.Int).isRequired,
  onSelect: PropTypes.func.isRequired,
  onItemCheck: PropTypes.func.isRequired,
};

/**
 *
 */
class LeafNode extends PureComponent {
  render() {
    return (
      <Checkbox
        data-id={this.props.item.id}
        checked={this.props.selected}
        label={this.props.renderLabel(this.props.item)}
        onChange={this.props.onSelect}
      />
    );
  }
}

LeafNode.propTypes = {
  item: PropTypes.shape(ItemShape).isRequired,
  renderLabel: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export function filterByName (search, folders = []) {
  if (folders == null || folders.length === 0) return folders;

  return folders.map((folder) => {
    if (folder == null) return {};
    const res = {...folder};
    const predicate = item => !search || _strings.fuzzyMatch(search, item.name);
    res.items = res.items && res.items.filter(predicate);
    res.subfolders = res.subfolders && filterByName(search, res.subfolders);
    return res;
  }).filter(folder => (
    folder &&
    (
      (folder.items && folder.items.length) ||
      (folder.subfolders && folder.subfolders.length)
    )
  ));
}

export default TreeSelector;
