import _capitalize from 'lodash/capitalize';

import stripAccents from './strip-accents';

/**
 * Convert a string to Title Case
 * @param  {?string} str
 * @return {?string} converted string
 */
export function titleCase(str) {
  if (!str) {
    return str;
  }
  return (
    str
    .replace(/[_-](\d)/i, ' $1')
    .split(/[\s_-]|(?=[A-Z])/)
    .map(_capitalize)
    .join(' ')
  );
}

/**
 * Generate a random looking string id with low risk of collision,
 * stolen from some OSS repo under MIT but I can't remember where...
 *
 * @param {number} [length = 16]
 * @return {string}
 */
export function randomHEX(length = 16) {
  const max = Math.pow(16, length) - 1;
  const min = Math.pow(16, length - 1);
  return (Math.floor(Math.random() * (max - min + 1)) + min).toString(16);
}

export const randomId = randomHEX;

/**
 * Generate a semi-compliant uuid4 (PRNG instead of true random).
 * @return {string}
 */
export function uuid4 () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/x+|y/g, (s) => {
    if (s !== 'y') {
      return randomHEX(s.length);
    } else {
      return ((Math.floor(Math.random() * 16) & 0x3) | 0x8).toString(16);
    }
  });
}

/**
 * Fuzzy string matching for nice client side search.
 * Does not do distance search or anything, just strips down strings to
 * simpler versions and test inclusion.
 *
 * @param {?string} searchString - string to match
 * @param {?string} inputString - string to search in
 * @param {{
 *    ignoreCase: bool,
 *    ignorAccents: bool,
 *    ignoreWhitespace: bool,
 *    ignoreSymbols: bool,
 *    matchType: 'exact'|'start'|'fuzzy'
 * }} opts
 * @return {bool}
 */
export function fuzzyMatch(searchString, inputString, {
  ignoreAccents = false,
  ignoreCase = true,
  ignoreWhitespace = true,
  ignoreSymbols = true,
  matchType = 'fuzzy',
} = {}) {
  let _search = searchString;
  let _input = inputString;

  if (_search == null) return true;
  if (_input == null) return false;

  const _transform = (func) => {
    _search = func(_search);
    _input = func(_input);
  };

  if (ignoreAccents) _transform(stripAccents);
  if (ignoreCase) _transform(s => s.toLowerCase());
  if (ignoreWhitespace) _transform(s => s.replace(/\s/g, ''));
  if (ignoreSymbols) _transform(s => s.replace(/_|-|\/|\\/g, ''));

  if (_search === _input) return true;

  if (matchType === 'exact') {
    return _search === _input;
  } else if (matchType === 'start') {
    return _input.substr(0, _search.length) === _search;
  } else {
    return _input.indexOf(_search) > -1;
  }
}

/** Cross browser way to get the user current language
 *  setting.
 *  @return {string}
 */
export function getUserLocale () {
  let lang;
  if (navigator.languages && navigator.languages.length) {
   // latest versions of Chrome and Firefox set this correctly
   lang = navigator.languages[0];
 } else if (navigator.userLanguage) {
   // IE only
   lang = navigator.userLanguage;
 } else {
   // latest versions of Chrome, Firefox, and Safari set this correctly
   lang = navigator.language;
 }
 return lang;
}

export const SITE_ENUM_RE = /^stylight_\w{2,3}(?:_\w{2,3})?$/i;
export const LOCALE_RE = /^[a-z]{2}_[A-Z]{2}$/;

export const SITE_TO_LOCALE = {
  stylight_de: 'DE',
  stylight_nl: 'NL',
  stylight_com: 'US',
  stylight_at: 'AT',
  stylight_ch: 'CH',
  stylight_fr: 'FR',
  stylight_co_uk: 'GB',
  stylight_it: 'IT',
  stylight_se: 'SE',
  stylight_es: 'ES',
  stylight_be: 'BE',
  stylight_com_br: 'BR',
  stylight_com_mx: 'MX',
  stylight_ca: 'CA',
  stylight_com_au: 'AU',
  stylight_no: 'NO',
  stylight_ie: 'IE',
};

/** Convert site enum value to 2-characters locale
 * @param {string} site
 * @return {string?}
 */
export function locale(site) {
  return SITE_TO_LOCALE[site];
}

export const CURRENCY_SYMBOLS = {
  EUR: '€',
  USD: '$',
  GBP: '₤',
  CHF: 'CHF',
  SEK: 'kr.',
  RUB: '₽',
  AUD: '$',
  INR: 'Rs.',
  BRL: 'R$',
  DKK: 'kr.',
  NOK: 'kr.',
  CAD: '$',
  MXN: '$',
};

/** Convert currency shorthand to symbol if applicable.
 * @param {string} currency
 * @return {string}
*/
export const currencySymbol = currency => (
  CURRENCY_SYMBOLS[currency] || currency
);
