// [WARN] To understand why we pass `..rest` all over the place, see
// https://github.com/reactjs/prop-types/blob/master/README.md#difference-from-reactproptypes-dont-call-validator-functions
// and
// https://facebook.github.io/react/warnings/dont-call-proptypes.html#fixing-the-false-positive-in-third-party-proptypes

/**
 * Make a validator which doesn't check the presence of the propName.
 *
 * @param  {(Object, string, ...Array<any>) => Error|null} validator
 * @return {(Object, string, ...Array<any>) => Error|null}
 */
export function optionalValidator (validator) {
  // eslint-disable-next-line func-names
  return function (props, propName, ...rest) {
    return (
      // Catch both null and undefined
      props[propName] == null
        ? null
        : validator(props, propName, ...rest)
    );
  };
}

/**
 * Make a factory to create a validator which does't check the presence
 * of the propName.
 *
 * @param  {(...Array<any>) => (Object, string, ...Array<any>) => Error|null} validator
 * @return {(...Array<any>) => (Object, string, ...Array<any>) => Error|null}
 */
export function optionalValidatorFactory (factory) {
  // eslint-disable-next-line func-names
  return function (...args) {
    return optionalValidator(factory(...args));
  };
}

/**
 * Make a validator which does check the presence of the propName.
 *
 * @param  {(Object, string, ...Array<any>) => Error|null} validator
 * @return {(Object, string, ...Array<any>) => Error|null}
 */
export function requiredValidator (validator) {
  // eslint-disable-next-line func-names
 return function (props, propName, componentName, ...rest) {
   return (
     // Catch both null and undefined
     props[propName] == null
       ? new Error(
         `Required prop \`${propName}\` was not specified in \`${componentName || 'ANONYMOUS'}\``
       )
       : validator(props, propName, componentName, ...rest)
   );
 };
}

/**
 * Make a factory to create a validator which does check the presence
 * of the propName.
 *
 * @param  {(...Array<any>) => (Object, string, ...Array<any>) => Error|null} validator
 * @return {(...Array<any>) => (Object, string, ...Array<any>) => Error|null}
 */
export function requiredValidatorFactory (factory) {
  // eslint-disable-next-line func-names
  return function (...args) {
    return requiredValidator(factory(...args));
  };
}

/**
 * Makes an optional validator which exposes the required version
 * under `isRequired`.
 *
 * @param  {(Object, string, ...Array<any>) => Error|null} validator
 * @return {(Object, string, ...Array<any>) => Error|null}
 */
export function chainableValidator (validator) {
  const _optionalValidator = optionalValidator(validator);
  _optionalValidator.isRequired = requiredValidator(validator);
  return _optionalValidator;
}

/**
 * Makes a validator factory to create an optional validator
 * which exposes the required version under `isRequired`.
 *
 * @param  {(...Array<any>) => (Object, string, ...Array<any>) => Error|null} validator
 * @return {(...Array<any>) => (Object, string, ...Array<any>) => Error|null}
 */
export function chainableValidatorFactory (factory) {
  // eslint-disable-next-line func-names
  return function (...args) {
    return chainableValidator(factory(...args));
  };
}

/**
 * Return a validator which calls a series of validators and returns the
 * first failure if applicable.
 *
 * @param  {Array<(Object, string, ...Array<any>) => Error|null>} validators
 * @return {(Object, string, ...Array<any>) => Error|null}
 */
export function chainValidators (...validators) {
  // eslint-disable-next-line func-names
  return function (...args) {
    let nullOrError = null;
    // eslint-disable-next-line no-return-assign
    validators.some(validator => nullOrError = validator(...args));
    return nullOrError;
  };
}

/**
 * Return a validator which calls lazily creates the validator function
 * by first calling the provided validator factory.
 * Useful for recursive definition or env dependent definitions.
 *
 * @param  {() => (Object, string, ...Array<any>) => Error|null} validatorFactory
 * @return {(Object, string, ...Array<any>) => Error|null}
 */
export function lazyValidator (factory) {
  let validator;
  // eslint-disable-next-line func-names
  return chainableValidator(function (...args) {
    if (!validator) validator = factory();
    return validator(...args);
  });
}
