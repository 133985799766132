import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import _uniq from 'lodash/uniq';

import * as utils from '../../../../utils';
import * as schema from '../../../../components/prop-types/schema';

import {Button, Spinner} from '../../../../components';
import * as Datagrid from '../../../../components/datagrid';

import ErrorRenderer from '../../../../components/views/partner-report/Error';
import * as renderers from '../../../../components/views/partner-report/renderers';
import ExportButton from '../../../../components/views/partner-report/ExportButton';

import {makeCustomerReportQuery, customerReportQueryVariables} from './query';


const DEFAULT_HIDDEN_COLUMNS = [];

export default class Report extends PureComponent {

  static propTypes = {
    shopId: schema.Int.isRequired,
    selectedGroupBy: PropTypes.arrayOf(schema.ReportGroupingDimension).isRequired,
    availableGroupBy: PropTypes.arrayOf(schema.ReportGroupingDimension).isRequired,
    fromDate: schema.MaybeDate.isRequired,
    toDate: schema.MaybeDate.isRequired,
    resolution: schema.ReportResolution.isRequired,
    targetCurrency: schema.Currency.isRequired,
    categories: PropTypes.objectOf(schema.Category).isRequired,
    selectedFilters: PropTypes.shape({
      verticals: PropTypes.arrayOf(schema.Vertical).isRequired,
      deviceTypes: PropTypes.arrayOf(schema.DeviceType).isRequired,
      categories: PropTypes.arrayOf(schema.Int).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      hiddenColumns: DEFAULT_HIDDEN_COLUMNS,
    };
  }

  toggleColumns = (ops) => {
    let next = this.state.hiddenColumns;
    ops.forEach(([key, visible]) => {
      if (visible) {
        next = next.filter(k => k !== key);
      } else {
        next = [...next, key];
      }
    });
    this.setState({hiddenColumns: _uniq(next)});
  }

  resetColumns = () => this.setState({hiddenColumns: DEFAULT_HIDDEN_COLUMNS});

  currencySymbol = () => (
    utils.strings.CURRENCY_SYMBOLS[this.props.targetCurrency] ||
    this.props.targetCurrency
  );

  isoCurrency = () => this.props.targetCurrency;

  _columns = [{
    key: 'date',
    label: () => `${utils.strings.titleCase(this.props.resolution)}`,
    renderer: row => renderers.renderDate(row.date, this.props.resolution),
  }, {
    key: 'vertical',
    label: 'Vertical',
    description: 'One of the Stylight Verticals (Fashion/Home & Living/Beauty)',
    renderer: renderers.titleCaseRenderer('vertical'),
  }, {
    key: 'device_type',
    label: 'Device',
    description: 'The device type the click was made from. (Desktop/Tablet/Mobile)',
    exportRenderer: renderers.titleCaseRenderer('device_type'),
    renderer: row => renderers.renderDeviceType(row.device_type),
  }, {
    key: 'category_tag_id',
    label: 'Category',
    description: 'The Stylight category the product belongs to where the click was made',
    renderer: entry => renderers.renderCategory(
      this.props.categories,
      entry.category_tag_id,
    ),
  }, {
    key: 'ppc_clickout_count',
    label: 'Clicks',
    description: 'The number of clicks that were made to your website',
    renderer: renderers.numeralRenderer(),
  }, {
    key: 'ppc_commission',
    label: 'Commission',
    description: 'Your current spend on Stylight',
    renderer: renderers.numeralRenderer(this.currencySymbol),
    exportRenderer: renderers.numeralRenderer(this.isoCurrency),
  }, {
    key: 'commission_per_click',
    label: 'Commission / click',
    description: 'This gives you your average cpc',
    renderer: renderers.numeralRenderer(this.currencySymbol),
    exportRenderer: renderers.numeralRenderer(this.isoCurrency),
  }, {
    key: 'tracked_sales',
    label: 'Attributed Sales',
    description: 'The number of sales we tracked on your page and attribute to our clicks. (only applies if you implement our tracking pixel)',
    renderer: renderers.numeralRenderer(),
  }, {
    key: 'tracked_sales_revenue',
    label: 'Attributed Sales Revenue',
    description: 'The revenue made from sales that we were able to match to a Stylight session',
    renderer: renderers.numeralRenderer(this.currencySymbol),
    exportRenderer: renderers.numeralRenderer(this.isoCurrency),
  }, {
    key: 'tracked_sales_revenue_per_click',
    label: 'Attributed Sales Revenue Per Click',
    description: 'The average session sales revenue per click',
    renderer: renderers.numeralRenderer(this.currencySymbol),
    exportRenderer: renderers.numeralRenderer(this.isoCurrency),
  },
].map(
    c => ({
      ...c,
      label: c.label || utils.strings.titleCase(c.key),
      renderer: c.renderer || renderers.identity,
      exportRenderer: c.exportRenderer || c.renderer || renderers.identity,
      sortable: true,
    })
  );

  isColumnMeaningful = (colKey) => (
    this.props.availableGroupBy.indexOf(colKey) === -1 ||
    this.props.selectedGroupBy.length === 0 ||
    this.props.selectedGroupBy.indexOf(colKey) > -1
  );

  // All available columns (only removes unselected dimensions)
  columns = () => this._columns.filter(
    col => {
      if (col.key.slice(0, 2) === '__') {
        return this.isColumnMeaningful(col.key.slice(2));
      } else {
        return this.isColumnMeaningful(col.key);
      }
    }
  );

  filteredColumns = () => {
    return this.columns().filter(
      col => this.state.hiddenColumns.indexOf(col.key) === -1
    );
  }

  fixedColumns = () => {
    return this.columns().filter(
      col =>
        this.props.availableGroupBy.indexOf(col.key) > -1 &&
        this.props.selectedGroupBy.indexOf(col.key) > -1
    ).map(c => c.key);
  }

  renderActions = (props) => {
    return (
      <div className="mxn1">
          <ExportButton
            {...props.parameters}
            {...props}
            loading={props.loading}
            limit={null}
            offset={null}
            columns={
              this.columns().map(
                ({key, label, exportRenderer: render}) => ({key, label, render})
              )
            }
            className="mx1"
            mapPropsToVariables={customerReportQueryVariables}
            fetch={makeCustomerReportQuery}
            filename="stylight_partner_center_clicks_and_commission.csv"
          />
          { props.loading &&
            <Button disabled className="mx1">
            <span>Fetching data <Spinner/></span>
            </Button> }
      </div>
    );
  }

  render() {
    return (
      <Datagrid.Container
        columns={this.filteredColumns()}
        fetch={makeCustomerReportQuery}
        defaultPageSize={50}
        disableSearch
        hideContentOnError
        mapParameters={customerReportQueryVariables}
        fixedColumnCount={this.fixedColumns().length}
        actions={this.renderActions}
        {...this.props}
        cellMinWidth={100}
      >
        {(props) => (
          <div className="flex flex-column flex-auto">
            <ErrorRenderer
              error={props.error}
              loading={props.loading}
              reload={props.reload}
            />
            <Datagrid.Controls {...props}/>
            <Datagrid.FixedTable {...props}/>
          </div>
        )}
      </Datagrid.Container>
    );
  }
}
