import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';
import DayPicker from 'react-day-picker';
import * as _dateFn from 'date-fns';

import classname from '../../utils/classname';
import CustomPropTypes from '../prop-types';

import {Button} from '..';

export default class DateRangePicker extends Component {

  static propTypes = {
    namedRanges: PropTypes.objectOf(PropTypes.func),
    defaultStartDate: PropTypes.instanceOf(Date).isRequired,
    defaultEndDate: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired,
    numberOfMonths: CustomPropTypes.integer.isRequired,
    applyOnRangeClick: PropTypes.bool,
    onClose: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    namedRanges: {},
    numberOfMonths: 2,
  };

  constructor(props) {
    super(props);
    this.dayPicker = null;
    this.state = {
      startDate: _dateFn.startOfDay(this.props.defaultStartDate),
      endDate: _dateFn.startOfDay(this.props.defaultEndDate),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const next = {};
    if (!_dateFn.isSameDay(this.state.startDate, nextProps.defaultStartDate)) {
      next.startDate = nextProps.defaultStartDate;
    }
    if (!_dateFn.isSameDay(this.state.endDate, nextProps.defaultEndDate)) {
      next.endDate = nextProps.defaultEndDate;
    }
    this.setState(next);
  }

  isRangeActive = (rangeName) => {
    const range = this.props.namedRanges[rangeName]();
    return (
      _dateFn.isSameDay(range.startDate, this.state.startDate) &&
      _dateFn.isSameDay(range.endDate, this.state.endDate)
    );
  }

  hasChanged = () => !(
    _dateFn.isSameDay(this.state.startDate, this.props.defaultStartDate) &&
    _dateFn.isSameDay(this.state.endDate, this.props.defaultEndDate)
  );

  modifiers = () => {
    return {
      innerRange: {
        from: _dateFn.addDays(this.state.startDate, 1),
        to: _dateFn.addDays(this.state.endDate, -1),
      },
    };
  }

  goToToday = () => {
    if (this.dayPicker) {
      this.dayPicker.showMonth(
        _dateFn.addMonths(new Date(), - (this.props.numberOfMonths - 1))
      );
    }
  }

  onClickRange = (evt) => {
    const rangeName = evt.currentTarget.dataset.rangeName;
    const next = this.props.namedRanges[rangeName]();
    this.setState({
      startDate: _dateFn.startOfDay(next.startDate),
      endDate: _dateFn.startOfDay(next.endDate),
    }, () => {
      if (this.props.applyOnRangeClick) this.onApply();
    });
  }

  onClickCalendarDay = (day, {disabled}) => {
    if (disabled) return;
    const range = {
      from: this.state.startDate,
      to: this.state.endDate,
    };
    const nextRange = DayPicker.DateUtils.addDayToRange(day, range);
    this.setState({
      startDate: _dateFn.startOfDay(nextRange.from),
      endDate: _dateFn.startOfDay(nextRange.to),
    });
  }

  onApply = () => {
    const {startDate, endDate} = this.state;
    this.props.onChange({startDate, endDate});
  }

  onClear = () => {
    this.setState({
      startDate: _dateFn.startOfDay(this.props.defaultStartDate),
      endDate: _dateFn.startOfDay(this.props.defaultEndDate),
    });
  }

  canApply = () => (
    this.hasChanged() &&
    this.state.startDate &&
    this.state.endDate
  );

  render() {
    const {startDate, endDate} = this.state;

    return (
      <div className={classname('drp bg-white rounded border border-color-gray-lighter', this.props.className)}>
        <div className="drp-header p1 border-bottom border-color-gray-lighter flex justify-between">
          <div>
            <div className="p1 inline-block px1 mxn1">
              <div className="bold inline-block color-gray-dark mx1">
                Start date
              </div>
              <div className="inline-block mx1">
                {(startDate && startDate.toDateString())
                 || 'Please select start date.'}
              </div>
            </div>
            <div className="p1 inline-block px1 mxn1">
              <div className="bold inline-block color-gray-dark mx1">
                End date
              </div>
              <div className="inline-block mx1">
                {(endDate && endDate.toDateString())
                 || 'Please select end date.'}
              </div>
            </div>
          </div>
          { !!this.props.onClose &&
            <Button onClick={this.props.onClose} className="color-gray-dark" small pill icon="close"/> }
        </div>
        <div className="drp-body flex justify-between">
          <div className="drp-body-calendar p1">
            <DayPicker
              ref={el => { this.dayPicker = el; }}
              {..._omit(this.props, Object.keys(DateRangePicker.propTypes))}
              numberOfMonths={this.props.numberOfMonths}
              selectedDays={[startDate, { from: startDate, to: endDate }]}
              onDayClick={this.onClickCalendarDay}
              fixedWeeks
              firstDayOfWeek={1}
              modifiers={this.modifiers()}
              navbarElement={Navbar}
              todayButton={null} // Force disable it as we render our own
            />
          </div>
          { Object.keys(this.props.namedRanges).length > 0 && (
            <div className="drp-ranges border-left border-color-gray-lighter mx1 my2">
              {Object.keys(this.props.namedRanges).map(targetRange =>
                (this.props.namedRanges[targetRange] != null) && (
                  <Button
                    key={targetRange}
                    data-range-name={targetRange}
                    small
                    className="mx1 block"
                    onClick={this.onClickRange}
                    disabled={this.isRangeActive(targetRange)}
                  >
                    {targetRange}
                  </Button>
                )
              )}
            </div>
          )}
        </div>
        <div className="drp-footer p1 border-top border-color-gray-lighter flex justify-between">
          <div className="drp-actions mxn1">
            <Button small onClick={this.goToToday} className="mx1">
              Show today
            </Button>
          </div>
          <div className="drp-actions mxn1">
            <Button
              small outline level="danger" className="mx1"
              disabled={!this.hasChanged()}
              onClick={this.onClear}
            >
              Reset selection
            </Button>

            <Button
              small level="primary" className="mx1"
              disabled={!this.canApply()}
              onClick={this.onApply}
            >
              Select range
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function Navbar(props) {
  return (
    <div className={props.className}>
      {props.showPreviousButton &&
        <Button title={props.labels.previousMonth}
                pill icon="chevron_left" className="left p0"
                onClick={() => props.onPreviousClick()}/> }
      { props.showNextButton &&
        <Button title={props.labels.nextMonth}
                pill icon="chevron_right" className="right p0"
                onClick={() => props.onNextClick()}/> }
    </div>
  );
}

Navbar.propTypes = {
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    previousMonth: PropTypes.string.isRequired,
    nextMonth: PropTypes.string.isRequired,
  }).isRequired,
  showNextButton: PropTypes.bool,
  showPreviousButton: PropTypes.bool,
  className: PropTypes.string,
};
