import React from 'react';
import StylightLogo from './StylightLogo';

export default function LoadingState() {
  return (
    <div className="flex items-center justify-around flex-1">
      <div className="flex-none fill" style={{maxWidth: '512px'}}>
        <StylightLogo className="svgPulsate"/>
      </div>
    </div>
  );
}
