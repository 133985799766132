import { isObject } from '../../../../utils/objects';

/**
 * This function tests for deep equality between objects extended with the following special cases:
 *  - Strings and numbers are compared using non-strict equality.
 *  - `undefined` is considered equal to the empty string.
 *  - `undefined` is considered equal to an empty object.
 *  - `undefined` is considered equal to an object if each of its properties is considered equal to
 *    `undefined`.
 */
export const fuzzyEqual = function fuzzyEqual(obj1, obj2) {
  if (!isObject(obj1) && !isObject(obj2)) { // None of the arguments is an object
    // Consider empty string properties non-existant
    const noneValues = new Set([undefined, '']);
    if (noneValues.has(obj1) && noneValues.has(obj2)) {
      return true;
    }

    // Allow type conversion when comparing numbers and strings
    const convertibleTypes = new Set(['number', 'string']);
    if (convertibleTypes.has(typeof(obj1))
      && convertibleTypes.has(typeof(obj2))) {
      return obj1 == obj2;  // eslint-disable-line eqeqeq
    } else {
      return obj1 === obj2;
    }
  } else if (isObject(obj1) && isObject(obj2)) {  // Both arguments are objects
    const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
    const diff = Array.from(keys, key => fuzzyEqual(obj1[key], obj2[key])).filter(equal => !equal);
    return !diff.length;
  } else if (obj1 === undefined) { // One argument is an object, the other is undefined
    const diff = Object.keys(obj2).map(key => fuzzyEqual(obj1, obj2[key])).filter(equal => !equal);
    return !diff.length;
  } else if (obj2 === undefined) { // Symmetric to the previous case
    const diff = Object.keys(obj1).map(key => fuzzyEqual(obj1[key], obj2)).filter(equal => !equal);
    return !diff.length;
  } else { // One argument is a scalar and the other is an object
    return false;
  }
};


/**
 * Filter out bids which are just the empty string. Also filter out any resulting empty categories.
 *
 * Expects the format:
 *  {
 *    365: { Desktop: 0.35, Mobile: '', Tablet: '0.35'},
 *    42: { Desktop: '' },
 *    1337: { Tablet: 3.50, Mobile: '4.20' }
 *    ...
 *  }
 */
export const omitEmptyBids = (categoryCpcs, forbiddenIds = []) => {
    return Object.entries(categoryCpcs).map(([tagId, deviceBids]) => [
      tagId,
      Object.entries(deviceBids).filter(([device, bid]) => bid !== '') // eslint-disable-line no-unused-vars
        .reduce((acc, [device, bid]) => Object.assign(acc, {[device]: bid}), {}),
    ]).filter(
      ([tagId, categoryBids]) => {
        return (
          Object.keys(categoryBids).length !== 0 &&
          forbiddenIds.indexOf(tagId) === -1
        );
      }
    ).reduce((acc, [tagId, bids]) => Object.assign(acc, {[tagId]: bids}), {});
};
