import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

import classname from '../../../utils/classname';
import CustomPropTypes from '../../prop-types';
import {Button, Icon} from '../..';

const _currentPage = (offset, pageSize) => Math.floor(offset / pageSize);
const _pageCount = (count, pageSize) => Math.ceil(count / pageSize);

export default class Pager extends PureComponent {

  static propTypes = {
    // function (pageSize: number, offset: number)
    onChange: PropTypes.func.isRequired,
    count: CustomPropTypes.integer,
    offset: CustomPropTypes.integer.isRequired,
    pageSize: CustomPropTypes.integer.isRequired,
    availablePageSizes: PropTypes.arrayOf(CustomPropTypes.integer).isRequired,
    disableKeyboardEvents: PropTypes.bool,
  };

  componentDidMount() {
    if (!this.props.disableKeyboardEvents) {
      document.addEventListener('keydown', this.handleKeypress, false);
    }
  }

  componentWillUnmount() {
    if (!this.props.disableKeyboardEvents) {
      document.removeEventListener('keydown', this.handleKeypress, false);
    }
  }

  handleKeypress = (evt) => {

    const keyCode = evt.key;
    const currentPage = _currentPage(this.props.offset, this.props.pageSize);
    const pageCount = (
      Number.isInteger(this.props.count) ?
      _pageCount(this.props.count, this.props.pageSize) :
      -1
    );

    // Ignore key presses from forms...
    const isInForm = (
      evt.target &&
      evt.target !== document &&
      evt.target !== window &&
      evt.target.matches('input, select, input *, select *')
    );

    const hasModifier = (
      evt.shiftKey ||
      evt.ctrlKey ||
      evt.metaKey ||
      evt.altKey
    );

    if (isInForm || hasModifier) {
      return;
    }

    if (keyCode === 'ArrowRight') { // Right
      evt.preventDefault();
      if (pageCount === -1 || currentPage < pageCount - 1) {
        this.props.onChange(
          this.props.pageSize,
          this.props.pageSize * (currentPage + 1),
        );
      }
    } else if (keyCode === 'ArrowLeft') { // Left
      evt.preventDefault();
      if (currentPage > 0) {
        this.props.onChange(
          this.props.pageSize,
          this.props.pageSize * (currentPage - 1),
        );
      }
    }
  }

  onPageChange = (evt) => {
    const targetPage = evt.currentTarget.getAttribute('data-page');
    const nextOffset = parseInt(targetPage, 10) * this.props.pageSize;
    if (nextOffset !== this.props.offset) {
      this.props.onChange(this.props.pageSize, nextOffset);
    }
  };

  onSizeChange = (evt) => {
    this.props.onChange(parseInt(evt.target.value, 10), this.props.offset);
  };

  render() {
    const pagerProps = _omit(this.props, [
      'onChange',
      'disableKeyboardEvents',
      'availablePageSizes',
    ]);

    return (
      <div className="flex justify-between flex-wrap">
        <label htmlFor="pageSize" className="inline-block mr2">
          Rows per page
          <select
            defaultValue={this.props.pageSize}
            name="pageSize"
            onChange={this.onSizeChange}
            className="field is-inline ml2"
          >
            {this.props.availablePageSizes.map(val => (
              <option
                key={val}
                disabled={val === this.props.pageSize}
                value={val}>{val}</option>
            ))}
          </select>
        </label>
        {
          Number.isInteger(this.props.count) ?
          <PaginatedPager {...pagerProps} onClick={this.onPageChange}/> :
          <SimplePager {...pagerProps} onClick={this.onPageChange}/>
        }
      </div>
    );
  }
}

export function SimplePager (props) {
  const currentPage = _currentPage(props.offset, props.pageSize);
  return (
    <div className={classname('btn-group', props.className)}>
      <Button
        small
        data-page={currentPage - 1}
        onClick={props.onClick}
        disabled={currentPage <= 0}
      >
        <Icon size="small" text="navigate_before"/>
      </Button>

      <Button small data-page={currentPage + 1} onClick={props.onClick}>
        <Icon size="small" text="navigate_next"/>
      </Button>
    </div>
  );
}

SimplePager.propTypes = {
  onClick: PropTypes.func.isRequired,
  offset: CustomPropTypes.integer.isRequired,
  pageSize: CustomPropTypes.integer.isRequired,
  className: PropTypes.string,
};

export function PaginatedPager (props) {

  const currentPage = _currentPage(props.offset, props.pageSize);
  const pageCount = _pageCount(props.count, props.pageSize);
  const first = 0;
  const last = pageCount - 1;

  const pages = [
    {content: <Icon size="small" text="first_page"/>,
     target: first,
     disabled: currentPage <= first},
    {content: <Icon size="small" text="chevron_left"/>,
     target: currentPage - 1,
     disabled: currentPage <= first},
    {content: `${currentPage + 1} / ${pageCount}`,
     target: null,
     disabled: true},
    {content: <Icon size="small" text="chevron_right"/>,
     target: currentPage + 1,
     disabled: currentPage >= last},
    {content: <Icon size="small" text="last_page"/>,
     target: last,
     disabled: currentPage >= last},
  ];

  return (
    <div className={classname('btn-group', props.className)}>
      { pages.map(({content, target, disabled}, index) =>
        <Button
          key={index}
          small
          data-page={target}
          disabled={disabled}
          onClick={props.onClick}
        >
          {content}
        </Button> )}
    </div>
  );
}

PaginatedPager.propTypes = {
  onClick: PropTypes.func.isRequired,
  count: CustomPropTypes.integer.isRequired,
  offset: CustomPropTypes.integer.isRequired,
  pageSize: CustomPropTypes.integer.isRequired,
  className: PropTypes.string,
};
