/**
 * Memoize for caching.
 */
import _debounce from 'lodash/debounce';
import _memoize from 'lodash/memoize';
import _throttle from 'lodash/throttle';

const DEFAULT_FUNC_STATICS = Object.getOwnPropertyNames(function noop () {});

/**
 * Assign all static own properties from one function to the other.
 * Useful for decorators.
 *
 * @param  {Function} func
 * @param  {Function} targetFunc
 */
export function forwardStatics(func, targetFunc) {
  Object.getOwnPropertyNames(func).forEach((prop) => {
    if (DEFAULT_FUNC_STATICS.indexOf(prop) === -1) {
      // eslint-disable-next-line no-param-reassign, Duh!
      targetFunc[prop] = func[prop];
    }
  });
}

const decoratorWithStaticForwarding = decorator => (func, ...args) => {
  const wrapped = decorator(func, ...args);
  forwardStatics(func, wrapped);
  return wrapped;
};

export const debounce = decoratorWithStaticForwarding(_debounce);
export const throttle = decoratorWithStaticForwarding(_throttle);
export const memoize = decoratorWithStaticForwarding(_memoize);

export const apply = (maybeFunc, ...args) => (
  typeof maybeFunc === 'function'
    ? maybeFunc(...args)
    : maybeFunc
);
