import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from '../../prop-types';
import * as _func from '../../../utils/func';

import DatagridPropTypes from '../prop-types';

import Pager from './Pager';
import Search from './Search';

export default class Controls extends PureComponent {

  onRequestSearch = searchString => this.props.onUpdateParameter({
    name: 'search',
    value: searchString,
  });

  onRequestPagination = (pageSize, offset) => this.props.onUpdateParameter({
    name: 'pagination',
    value: {limit: pageSize, offset},
  });

  render() {
    const {
      actions,
      availablePageSizes,
      disableSearch,
      pagination,
    } = this.props;

    return (
      <div className="datagrid-controls">
        <div className="flex justify-between flex-wrap my2">
          <div className="flex-auto">
            {_func.apply(actions, this.props)}
          </div>
          <div className="flex-auto">
            { disableSearch ||
              <Search onChange={this.onRequestSearch}/> }
          </div>
          <div className="flex-auto flex justify-end">
            { pagination && <Pager
              count={pagination.count}
              offset={pagination.offset}
              pageSize={pagination.limit}
              availablePageSizes={availablePageSizes}
              onChange={this.onRequestPagination}
              className="px1 inline-flex items-center"
            /> }
          </div>
        </div>

      </div>
    );
  }
}

Controls.propTypes = {
  availablePageSizes: PropTypes.arrayOf(CustomPropTypes.integer).isRequired,
  disableSearch: PropTypes.bool,
  pagination: DatagridPropTypes.Pagination,
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onUpdateParameter: PropTypes.func.isRequired,
};

Controls.defaultProps = {
  availablePageSizes: [5, 10, 20, 25, 50, 100],
};
