/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import withPolyfills from '../withPolyfills';
import {load as fetchConfig} from '../config';
import * as _sentry from '../sentry';
import * as _dom from '../utils/dom';

import {AuthProvider} from '../auth';
import ErrorBoundary from '../components/ErrorBoundary';
import RedScreenOfDeath from '../components/RedScreenOfDeath';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

/**
 * Boot one of the React applications.
 *
 * @param {string} nodeId
 * @param {internal|external} appMode
 * @param {ReactComponent} AppComponent
 */
export default function boot(nodeId, appMode, AppComponent) {
  withPolyfills(() =>
    fetchConfig()
    .then(config => {
      _sentry.setup(config, appMode);
      if (config.googleAnalyticsId) {
        const debug = config.environment !== 'production' && process.env.NODE_ENV !== 'production';
        ReactGA.initialize(config.googleAnalyticsId, {debug: debug});
        ReactGA.set({anonymizeIp: true, appMode: appMode});
      }
      return Promise.resolve();
    })
    .then(_dom.onReady)
    .then(startApp)
    // [TODO] How do we handle this kind of not trackable failures
    // (outside of raven context) ?
    .catch(err => console.error(err))  // eslint-disable-line no-console
  );

  function startApp() {
    const logRenderError = (err) => _sentry.captureException(err);
    const renderOnError = (opts) => (
      <RedScreenOfDeath
        className="fixed top-0 bottom-0 left-0 right-0"
        errors={{render: opts.error}}
      />
    );

  const theme = createMuiTheme({
    palette: {
      primary: { main: '#00cfca', contrastText: '#fff' },
      secondary: { main: 'rgba(108, 62, 180, 1)' },
    },
  });

    ReactDOM.render((
      <ErrorBoundary renderOnError={renderOnError} onError={logRenderError}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <AppComponent/>
          </ThemeProvider>
        </AuthProvider>
      </ErrorBoundary>
    ), document.getElementById(nodeId));
  }
}

