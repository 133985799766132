import React from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';
import queryString from 'query-string';

const SUPPORTS_HISTORY = 'history' in window && 'pushState' in window.history;

/**
 * Manage data and sync in/out with the page url query string on change.
 *
 * [WARN] This will take over the query string and any other code that uses it
 * will conflict. A better long term solution would be some global handling of
 * the query string through the router or a state singleton.
 *
 * @export
 * @class QueryStringSync
 */
export default class QueryStringSync extends React.PureComponent {

  constructor(...args) {
    super(...args);

    this._defaultState = this.props.deserialize(
      this.props.queryString,
      this.props.defaultState
    );
    this.state = this._defaultState;
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidUpdate() {
    this.pushStateToWindowLocation();
  }

  componentDidMount() {
    this.pushStateToWindowLocation(true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.serialize(this.state) !== nextProps.queryString) {
      this.setState(state => this.props.deserialize(nextProps.queryString, state));
    }
  }

  pushStateToWindowLocation(replace = false) {
    const nextUrl = (
      this.props.serialize(this.state)
      .split('&')
      .map(s => s.split('='))
      .reduce(
        (_url, [key, value]) => updateUrlQueryString(key, value, _url),
        clearSearchFromUrl(window.location.href)
      )
    );

    if (SUPPORTS_HISTORY && window.location.href !== nextUrl) {
      if (replace) {
        window.history.replaceState({}, null, nextUrl);
      } else {
        // Push state so the back button beahviour remains the same.
        // When the back button is clicked, the router detects a `popstate`
        // event and re-renders which triggers this component
        // `componentWillReceiveProps` or `constructor` and /
        // `componentDidMount`. Same on forward.
        window.history.pushState({}, null, nextUrl);
      }
    }
  }

  /**
   * Generic state updater that accepts a function or a partial state.
   * @param {function|object} payload
   */
  update(payload, cb) {
    this.setState(payload, cb);
  }

  /** Reset component to it's original state */
  reset() {
    this.setState(this._defaultState);
  }

  render() {
    return this.props.children({
      ..._omit(this.props, Object.keys(QueryStringSync.propTypes)),
      ...this.state,
      reset: this.reset,
      update: this.update,
    });
  }
}


QueryStringSync.propTypes = {
  // Rendering function, receives all extra props, the state and all event
  // handlers (reset, update, etc.)
  // ({reset: () => void,
  //   update: (payload: function|object) => void,
  //   ...rest: object}) => ReactElement
  children: PropTypes.func.isRequired,
  // Querystring for initialisation and to inject changes (this does not poll)
  queryString: PropTypes.string,
  // State for initialisation and reset
  defaultState: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  // Serialisation function to go from state => querystring
  // (object: state) => string
  serialize: PropTypes.func.isRequired,
  // De-serialisation function to go from querystring => state
  // (qs: string, initialState: object) => object
  deserialize: PropTypes.func.isRequired,
};

export const serialize = data => queryString.stringify(data, {arrayFormat: 'bracket'});
export const deserialize = (qs, init = {}) => ({ ...init, ...queryString.parse(qs, {arrayFormat: 'bracket'}) });

QueryStringSync.defaultProps = { serialize, deserialize };


/**
 * Add / Remove / Update key value pairs in url query strings.
 * I would use the `URL` and `URLSearchParams` built-ins,
 * but IE-11 has no support...
 *
 * @param {string} key
 * @param {string} value
 * @param {string} url
 * @returns {string}
 */
function updateUrlQueryString(key, value, url) {
  const re = new RegExp(`([?&])${key}=.*?(&|#|$)(.*)`, 'gi');
  if (re.test(url)) {
    if (value == null) {
      return url.replace(re, '');
    } else {
      const hash = url.split('#');
      let updated = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
      if (hash[1]) updated += `#${hash[1]}`;
      return updated;
    }
  } else if (value != null) {
    const separator = url.indexOf('?') !== -1 ? '&' : '?';
    const hash = url.split('#');
    let updated = `${hash[0]}${separator}${key}=${value}`;
    if (hash[1]) updated += `#${hash[1]}`;
    return updated;
  } else {
    return url;
  }
}

/**
 *  Remove search and hash from a url
 *
 * @param {string} url
 * @returns {string}
 */
function clearSearchFromUrl(url) {
  return url.split(/[?#]/)[0];
}
