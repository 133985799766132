import * as _http from '../../utils/http';
import * as _config from '../../config';
import * as _sentry from '../../sentry';


const get = (path, query = null) => (
  _http.get(`${_config.url('customer', path)}`, query)
    .then(response => response.body)
    .catch(err => {
      _sentry.captureException(err, {level: 'error'});
      _http.handle401(err, '/login');
    })
);

const post = (path, query = null, data = null) => (
  _http.post(`${_config.url('customer', path)}`, query, data)
    .then(response => response.body)
    .catch(err => {
      _sentry.captureException(err, {level: 'error'});
      _http.handle401(err, '/login');
    })
);

const del = (path, query = null) => (
  _http.del(`${_config.url('customer', path)}`, query)
    .then(response => response.body)
    .catch(err => {
      _sentry.captureException(err, {level: 'error'});
      _http.handle401(err, '/login');
    })
);


export const self = () => get('account-info');
export const contracts = () => get('contracts');
export const currentContract = () => get('contract/current');
export const nextContract = () => get('contract/next');
export const categoryCpcs = () => get('category-cpcs');
export const saveCategoryCpcs = (data) => post('category-cpcs', null, data);
export const stopCategoryCpcs = () => del('category-cpcs', null);
