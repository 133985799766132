import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import * as schema from '../../../../components/prop-types/schema';
import * as Datagrid from '../../../../components/datagrid';
import * as utils from '../../../../utils';

import {Button, Spinner} from '../.././../../components';

import ErrorRenderer from '../../../../components/views/partner-report/Error';
import * as renderers from '../../../../components/views/partner-report/renderers';

import {makeProductReportQuery, queryVariables} from './query';

const DEFAULT_SORT = [['report_type', 1], ['start_day', 1], ['end_day', 1]];

export default class ProductReport extends PureComponent {

  static propTypes = {
    shopId: schema.Int.isRequired,
    fromDate: schema.MaybeDate.isRequired,
    toDate: schema.MaybeDate.isRequired,
    selectedFilters: PropTypes.shape({
      reportTypes: PropTypes.arrayOf(schema.ProductReportType).isRequired,
    }).isRequired,
  }

  _columns = [{
      key: 'start_day',
      label: 'Start Day',
      renderer: entry => renderers.renderDate(entry.start_day, 'day'),
    }, {
      key: 'end_day',
      label: 'End Day',
      renderer: entry => renderers.renderDate(entry.end_day, 'day'),
    }, {
      key: 'report_type',
      label: 'Report Type',
      renderer: renderers.reportTypeRenderer('report_type'),
    }, {
      key: 'report_url',
      label: 'Report Link',
      renderer: entry => renderers.renderDownloadButton(entry),
    },
    ].map(
      c => ({
        ...c,
        label: c.label || utils.strings.titleCase(c.key),
        renderer: c.renderer || renderers.identity,
        exportRenderer: c.exportRenderer || c.renderer || renderers.identity,
        sortable: true,
      })
    );

  // All available columns (only removes unselected dimensions)
  columns = () => this._columns;

  renderActions = (props) => {
    return (
      <div className="mxn1">
          { props.loading &&
            <Button disabled className="mx1">
            <span>Fetching data <Spinner/></span>
            </Button> }
      </div>
    );
  }

  render() {
    return (
      <Datagrid.Container
        columns={this.columns()}
        defaultSortList={DEFAULT_SORT}
        fetch={makeProductReportQuery}
        defaultPageSize={25}
        disableSearch
        hideContentOnError
        mapParameters={queryVariables}
        actions={this.renderActions}
        {...this.props}
        cellMinWidth={100}
      >
        {(props) => (
          <div className="flex flex-column flex-auto">
            <ErrorRenderer
              error={props.error}
              loading={props.loading}
              reload={props.reload}
            />
            <Datagrid.Controls {...props}/>
            <Datagrid.FixedTable {...props}/>
          </div>
        )}
      </Datagrid.Container>
    );
  }
}
