import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import * as _strings from '../../../../utils/strings';
import * as schema from '../../../../components/prop-types/schema';
import {Radio, Button} from '../../../../components';

import {
  FilterCard, CheckboxSelector, DateRangeSelector, filterSummary,
  CategorySelector,
} from '../../../../components/views/partner-report/filters';

export default class Filters extends PureComponent {

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    fromDate: schema.MaybeDate.isRequired,
    toDate: schema.MaybeDate.isRequired,
    selectedGroupBy: PropTypes.arrayOf(schema.ReportGroupingDimension).isRequired,
    availableGroupBy: PropTypes.arrayOf(schema.ReportGroupingDimension).isRequired,
    availableCurrencies: PropTypes.arrayOf(schema.Currency).isRequired,
    targetCurrency: schema.Currency.isRequired,
    resolution: schema.ReportResolution.isRequired,
    resolutions: PropTypes.arrayOf(schema.ReportResolution).isRequired,
    categoryFolders: PropTypes.arrayOf(schema.CategoryFolder).isRequired,
    selectedFilters: PropTypes.shape({
      verticals: PropTypes.arrayOf(schema.Vertical).isRequired,
      deviceTypes: PropTypes.arrayOf(schema.DeviceType).isRequired,
      categories: PropTypes.arrayOf(schema.Int).isRequired,
    }).isRequired,
    availableFilters: PropTypes.shape({
      verticals: PropTypes.arrayOf(schema.Vertical).isRequired,
      deviceTypes: PropTypes.arrayOf(schema.DeviceType).isRequired,
    }).isRequired,
  }

  onUpdateDateRange = ({startDate: fromDate, endDate: toDate}) => {
    this.props.onChange({fromDate, toDate}, () => this.props.onApply());
  };
  onUpdateResolution = (evt) => this.props.onChange(
    {resolution: evt.currentTarget.value},
    () => this.props.onApply()
  );
  onUpdateCurrency = (evt) => this.props.onChange(
    {targetCurrency: evt.currentTarget.value},
    () => this.props.onApply()
  );
  onUpdateGroupBy = nextGroupBy => this.props.onChange(
    {selectedGroupBy: nextGroupBy},
    () => this.props.onApply()
  );

  filterUpdater = key => value => this.props.onChange(configuration => {
    return {
      ...configuration,
      selectedFilters: {
        ...configuration.selectedFilters,
        [key]: value,
      },
    };
  }, () => this.props.onApply());


  updateFilters = {
    verticals: this.filterUpdater('verticals'),
    deviceTypes: this.filterUpdater('deviceTypes'),
    categories: this.filterUpdater('categories'),
  };

  resetAllFilters = () => {
    this.props.onChange(configuration => {
      return {
        ...configuration,
        selectedFilters: Object.keys(configuration.selectedFilters).reduce(
          (map, key) => ({...map, [key]: []}),
          {}
        ),
      };
    }, () => this.props.onApply());
  }

  render() {
    const {fromDate, toDate, selectedGroupBy, availableGroupBy, selectedFilters,
           availableFilters, categoryFolders} = this.props;

    const hasActiveFilters = (
      Object
      .keys(this.props.selectedFilters)
      .some(key => this.props.selectedFilters[key].length > 0)
    );

    return (
      <div className="flex flex-column">

        <div className="flex justify-between">
          { hasActiveFilters &&
            <Button className="mx1" level="danger" outline block
                    disabled={!hasActiveFilters}
                    onClick={this.resetAllFilters}>
              Reset All Filters
            </Button> }
        </div>

        <div className="m1 p1 border rounded border-color-gray-lighter">
          <div className="medium my1">
            Date range
          </div>
          <DateRangeSelector
            fromDate={fromDate}
            toDate={toDate}
            onChange={this.onUpdateDateRange}
          />
          <div className="mt2">
            <div className="medium py1">Group report</div>
            <CheckboxSelector
              selected={selectedGroupBy}
              options={availableGroupBy}
              onUpdateSelection={this.onUpdateGroupBy}
              preventEmpty
            />

            { selectedGroupBy.indexOf('date') !== -1 && (
              <div className="mt2">
                <div className="mxn1">
                  <div className="m1 medium">Resolution</div>
                  {this.props.resolutions.map(resolution =>
                    <Radio
                      key={resolution}
                      inline
                      className="mx1"
                      checked={this.props.resolution === resolution}
                      name="reportResolution"
                      id={`reportResolution-${resolution}`}
                      value={resolution}
                      label={_strings.titleCase(resolution)}
                      onChange={this.onUpdateResolution}
                    />
                  )}
                </div>
              </div>
            )}

            <div className="mt2 flex justify-between items-center">
              <div className="inline-block medium">Currency</div>
              <select
                className="field is-inline"
                onChange={this.onUpdateCurrency}
                value={this.props.targetCurrency}
              >
                {this.props.availableCurrencies.map(cur => {
                  const symbol = _strings.currencySymbol(cur);
                  return (
                    <option key={cur} value={cur}>
                      {cur}{symbol !== cur && ` ${symbol}`}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <FilterCard key="Verticals" title="Verticals"
          startOpen={selectedFilters.verticals.length > 0}
          message={filterSummary(
            selectedFilters.verticals,
            this.updateFilters.verticals,
            'vertical', 'verticals'
          )}
        >
          <CheckboxSelector
            selected={selectedFilters.verticals}
            options={availableFilters.verticals}
            onUpdateSelection={this.updateFilters.verticals}
          />
        </FilterCard>

        <FilterCard key="Category" title="Categories"
          startOpen={selectedFilters.categories.length > 0}
          message={filterSummary(
            selectedFilters.categories,
            this.updateFilters.categories,
            'category', 'categories'
          )}
        >
          <CategorySelector
            hideId
            categoryFolders={categoryFolders}
            onUpdateSelection={this.updateFilters.categories}
            selected={selectedFilters.categories}
          />
        </FilterCard>

        <FilterCard key="Device Types" title="Device Types"
          startOpen={selectedFilters.deviceTypes.length > 0}
          message={filterSummary(
            selectedFilters.deviceTypes,
            this.updateFilters.deviceTypes,
            'device type', 'device types'
          )}
        >
          <CheckboxSelector
            selected={selectedFilters.deviceTypes}
            options={availableFilters.deviceTypes.filter(x => x.toLowerCase() !== 'other')}
            onUpdateSelection={this.updateFilters.deviceTypes}
          />
        </FilterCard>

      </div>
    );
  }
}
