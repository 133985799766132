import React from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

import {shallowEqual} from '../utils/objects';

/**
 * Keep track of a piece of state internally and flush it upwards on request.
 * Use this when you want to shield an upstream state provider from downstream
 * changes which would be immediatly applied by exposing the `apply` and
 * `reset` helpers.
 *
 * The only requirement is that upstream and downstream communicate with a
 * `setState` like function.
 *
 * [WARN] All equality checks are shallow.
 */
export default class StateBuffer extends React.PureComponent {

  constructor(...args) {
    super(...args);
    this.reset = this.reset.bind(this);
    this.update = this.update.bind(this);
    this.dirty = this.dirty.bind(this);
    this.apply = this.apply.bind(this);
    this.state = {...this.props.defaultState};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Make sure we only update the internal state if upstream state changed
    // and the internal state hasn't
    const shouldReset = (!shallowEqual(nextProps.defaultState, this.props.defaultState)
                         && !this.dirty());

    if (shouldReset) {
      this.setState(nextProps.defaultState);
    }
  }

  /** Update internal state */
  update(payload, cb) {
    this.setState(payload, cb);
  }

  /** Flush internal state upstream */
  apply() {
    if (this.dirty()) this.props.onApply(this.state);
  }

  /** Reset back to initial provided state */
  reset() {
    this.setState(this.props.defaultState);
  }

  /** Reset back to initial provided state */
  dirty() {
    return !shallowEqual(this.props.defaultState, this.state);
  }

  render() {
    return this.props.children({
      props: _omit(this.props, Object.keys(StateBuffer.propTypes)),
      state: this.state,
      defaultState: this.props.defaultState,
      reset: this.reset,
      update: this.update,
      apply: this.apply,
      dirty: this.dirty(),
    });
  }
}


StateBuffer.propTypes = {
  /** Rendering function, receives all extra props, the state and all event
   *  handlers (reset, update, etc.)
   *
   *    ({reset: () => void,
   *      apply: () => void,
   *      update: (payload: function|object, cb: function|null) => void,
   *      props: object,
   *      state: object,
   *      defaultState: object,
   *      dirty: boolean}) => ReactElement
   */
  children: PropTypes.func.isRequired,
  /** State for initialisation and reset.
  */
  defaultState: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  /** Event handler to flush the state upwards.
   *
   *    (state: object) => void
   */
  onApply: PropTypes.func.isRequired,
};
