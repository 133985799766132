import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function Banner({ src, link, alt = '', startDate, endDate }) {
    const [isLoading, setIsLoading] = useState(true);
    const handleImageLoaded = () => setIsLoading(false);
    const handleImageLoadingError = () => setIsLoading(false);
    const shouldDisplay = useMemo(() => {
      const today = new Date(Date.now());
      return today >= startDate && today <= endDate;
    }, [startDate, endDate]);

    return (
      shouldDisplay ? <Box mt={1}>
        <a href={link} target="_blank" rel="noreferrer">
          <img
            src={src}
            alt={alt}
            className={isLoading ? 'hide' : ''}
            onLoad={handleImageLoaded}
            onError={handleImageLoadingError}
          />
          { isLoading && <p>Loading image...</p> }
        </a>
      </Box> : null
    );
}

Banner.propTypes = {
  src: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  alt: PropTypes.string,
};

export default Banner;
