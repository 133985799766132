import React from 'react';
import PropTypes from 'prop-types';

import {dropSudo, employeeLogout, hasRole, partnerLogout, requestSudo} from '../../auth';

import * as schema from '../prop-types/schema';
import DropdownMenu from '../DropdownMenu';

const userLabel = user => {
  if (user.__sudo__) {
    return `${user.features.name} (${user.features.locale.slice(-2)})`;
  }
  return 'My Account';
};

function MenuWrapper(props) {

  const onLogout = evt => {
    evt.preventDefault();
    props.onLogout();
  };

  const onDropSudo = evt => {
    evt.preventDefault();
    dropSudo();
  };

  return (
    <DropdownMenu className="inline-flex" dismissOnEscape right showChevron
                  buttonProps={{noFocus: true}} text={userLabel(props.user)}>
      <div className="bg-white layer-2 m2 rounded border border-color-gray-lighter" style={{minWidth: 196}}>
        {props.children}
        <a href="#" onClick={onLogout} className="px2 py1 fill flex justify-between items-center text-decoration-none">
          <div className="inline-block">Logout</div>
        </a>
        { !!props.user.__sudo__ && (
          <a href="#" onClick={onDropSudo} className="px2 py1 color-danger fill flex justify-between items-center text-decoration-none">
            <div className="inline-block">Drop Sudo</div>
          </a>
        )}
      </div>
    </DropdownMenu>
  );
}

MenuWrapper.propTypes = {
  user: schema.User.isRequired,
  onLogout: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export function InternalUserMenu(props) {

  function sudo(evt) {
    evt.preventDefault();
    requestSudo();
  }

  return (
    <MenuWrapper onLogout={employeeLogout} user={props.user}>
      {hasRole(props.user, 'sudo') && (
        <div>
          <div className="p1 medium caps h5 color-gray-darker">Admin</div>
          <a href="#" onClick={sudo} className="px2 py1 fill flex justify-between items-center text-decoration-none">
            <div className="inline-block">Request SUDO access</div>
          </a>
          <div className="border-bottom border-color-gray-lightest my1"/>
        </div>
      )}
    </MenuWrapper>
  );
}

InternalUserMenu.propTypes = {
  user: schema.InternalUser.isRequired,
};

export function ExternalUserMenu(props) {
  return (
    <MenuWrapper onLogout={partnerLogout} user={props.user}>
    </MenuWrapper>
  );
}

ExternalUserMenu.propTypes = {
  user: schema.ExternalUser.isRequired,
};
