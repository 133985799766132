import React from 'react';
import PropTypes from 'prop-types';
import * as dfns from 'date-fns';

import {naturalTimeDiff} from '../utils/date';

/**
 * Display a date / date string in local time
 * [NOTE] should be extended to support more options like natural time,
 * timezone, etc...
 */
export default function DateView(props) {

  let value, dateString;
  const date = new Date(props.value);
  if (props.stripTime) {
    dateString = dfns.startOfDay(date).toDateString();
  } else {
    dateString = value.toDateString();
  }

  if (props.naturalTime) {
    const opts = typeof props.naturalTime === 'object' ? props.naturalTime : {
      keepOnly: 2,
    };
    value = `${dateString} (${naturalTimeDiff(date, null, opts)})`;
  } else {
    value = dateString;
  }

  return <span>{value}</span>;
}

DateView.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  naturalTime: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  stripTime: PropTypes.bool,
};
