import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

import {classname} from '../utils';
import Button from './Button';
import Icon from './Icon';
import KeyboardListener from './KeyboardListener';

export default class DropdownMenu extends PureComponent {

  static propTypes = {
    visible: PropTypes.bool,
    showChevron: PropTypes.bool,
    icon: PropTypes.string,
    text: PropTypes.node,
    buttonProps: PropTypes.shape(_omit(Button.propTypes, ['onClick'])),
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    buttonProps: {
      icon: 'more_vert',
    },
    text: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible,
    };
  }

  toggle = () => {
    this.setState({visible: !this.state.visible});
  }

  render() {
    const {visible} = this.state;

    return (
      <Dropdown
        {..._omit(this.props, Object.keys(DropdownMenu.propTypes))}
        visibleContent={
          <Button onClick={this.toggle} {...this.props.buttonProps}>
            {this.props.text}
            <Icon size="small" text="supervisor_account" className="pl1"/>
          </Button>
        }
        onDismiss={this.toggle}
        visible={visible}
        dismissOnContent
      >
        {this.props.children}
      </Dropdown>
    );
  }
}


export class Dropdown extends PureComponent {

  static propTypes = {
    visible: PropTypes.bool,
    top: PropTypes.bool,
    right: PropTypes.bool,
    cover: PropTypes.bool,
    children: PropTypes.node.isRequired,
    disableOverlayClick: PropTypes.bool,
    dismissOnEscape: PropTypes.bool,
    dismissOnContent: PropTypes.bool,
    visibleContent: PropTypes.node,
    onDismiss: PropTypes.func,
    disableOverlay: PropTypes.bool,
    className: PropTypes.string,
  }

  onDismiss = () => this.props.onDismiss && this.props.onDismiss();
  onEscape = () => this.props.visible && this.onDismiss();

  render() {
    const {visible, className, right, top, cover,
           disableOverlayClick, disableOverlay} = this.props;

    const cls = {
      root: classname('relative', className),

      overlay: classname('fixed top-0 right-0 bottom-0 left-0 z3', {
        'display-none': disableOverlay || !visible,
      }),

      content: classname('absolute transition-all z4', {
        'transition-scale-start transition-fade-start': !visible,
        'transition-scale-end transition-fade-end': visible,
        'left-auto right-0': right,
        'left-0 right-auto': !right,
        'top-auto bottom-100': top && !cover,
        'top-auto bottom-0': top && cover,
        'top-100 bottom-auto': !top && !cover,
        'top-0 bottom-auto': !top && cover,
        'transform-origin-top-right': !top && right,
        'transform-origin-top-left': !top && !right,
        'transform-origin-bottom-right': top && right,
        'transform-origin-bottom-left': top && !right,
      }),
    };

    return (
      <div className={cls.root}>
        {this.props.dismissOnEscape &&
         <KeyboardListener keyName="Escape" handler={this.onEscape}/>}
        {this.props.visibleContent}

        { !disableOverlayClick ?
          <div onClick={this.onDismiss} className={cls.overlay}></div> :
          null }

        <div
          className={cls.content}
          onClick={this.props.dismissOnContent ? this.onDismiss : () => null}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
