import React from 'react';
import PropTypes from 'prop-types';

const isInput = node => (
  node instanceof window.HTMLElement &&
  node.target !== document &&
  node.target !== window &&
  node.matches('input, select, input *, select *, textarea, textarea *')
);

const ROOT = window.document;

/**
 * Declarative keyboard event handler.
 * Just drop this anywhere and it will react on the defined keyboard events.
 *
 * [WARN] This ignores any event coming from an input, select or textarea
 *
 * [WARN] This is still limited to event hierarchy and your use of
 * interruption and bubbling.
 * */
export default class KeyboardListener extends React.Component {

  static propTypes = {
    // See https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
    keyName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    eventName: PropTypes.oneOf(['keydown', 'keypress', 'keyup']),
    handler: PropTypes.func.isRequired,
  };

  static defaultProps = {
    eventName: 'keyup',
  };

  shouldComponentUpdate() {
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.eventName !== this.props.eventName) {
      ROOT.removeEventListener(this.props.eventName, this.handleKeyboardEvent);
      ROOT.addEventListener(nextProps.eventName, this.handleKeyboardEvent);
    }
  }

  componentDidMount() {
    ROOT.addEventListener(this.props.eventName, this.handleKeyboardEvent);
  }

  componentWillUnmount() {
    ROOT.removeEventListener(this.props.eventName, this.handleKeyboardEvent);
  }

  matches = evt => {
    const {keyName: key} = this.props;
    return (key == null ||
            (Array.isArray(key) && key.indexOf(evt.key) !== -1) ||
            key.toLowerCase() === evt.key.toLowerCase());
  }

  handleKeyboardEvent = evt => {
    const {handler} = this.props;
    const {target} = evt;
    if (!handler || isInput(target) || !this.matches(evt)) return;
    handler(evt);
  };

  render() {
    return null;
  }
}
