import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {titleCase} from '../../../../utils/strings';
import {Checkbox} from '../../..';

const ERROR_MSG = 'You need to select at least one value.';

const nextErrorMessage = (props) => (
  (props.preventEmpty && props.selected.length === 0)
  ? ERROR_MSG
  : ''
);

export default class CheckboxSelector extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      error: nextErrorMessage(props),
    };
  }

  handleCheckboxChange = (evt) => {
    const {selected, preventEmpty} = this.props;
    const key = evt.target.getAttribute('data-key');
    const checked = evt.target.checked;

    if (checked && selected.indexOf(key) === -1) {
      this.setState({error: ''});
      this.props.onUpdateSelection([...selected, key]);
    } else if (!checked) {
      if (preventEmpty && this.props.selected.length === 1) {
        this.setState({error: ERROR_MSG});
      } else {
        this.setState({error: ''});
        this.props.onUpdateSelection(selected.filter(x => `${x}` !== key));
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextError = nextErrorMessage(nextProps);
    if (nextError !== this.state.error) {
      this.setState({error: nextError});
    }
  }

  render() {

    const {selected, options, label, itemStyle} = this.props;

    return (
      <div>
        { !!this.state.error && (
          <div className="error color-red my1">{this.state.error}</div>
        )}
        <div className="mxn1 flex flex-wrap">
          { options.slice().map(option => {
            const isSelected = selected.indexOf(option) > -1;
            return (
              <div className="inline-block mx1" key={option}>
                <Checkbox
                  inline
                  data-key={option}
                  checked={isSelected}
                  onChange={this.handleCheckboxChange}
                  label={label ? label(option) : option}
                  style={{whiteSpace: 'nowrap', ...itemStyle}}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}


CheckboxSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdateSelection: PropTypes.func.isRequired,
  preventEmpty: PropTypes.bool,
  label: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  itemStyle: PropTypes.object,
};

CheckboxSelector.defaultProps = {
  label: label => titleCase(label.replace(/_id$/, '')),
  itemStyle: {},
};
