/**
 * Minimal wrappers around DOM APIs.
 */

const BROWSER_PREFIXES = ['', 'ms', 'webkit', 'moz', 'o'];

/**
 * Get the prefixed supported property name.
 *
 * @param {string} propName (camelCase)
 * @return {?string} prefixed property (camelCase)
 */
export default function getSupportedPropertyName(propName) {
  for (let i = 0; i < BROWSER_PREFIXES.length; i++) {
    const prefix = BROWSER_PREFIXES[i];
    const prefixed = prefix === '' ? propName : (
      prefix + propName.charAt(0).toUpperCase() + propName.slice(1));
    if (typeof window.document.body.style[prefixed] !== 'undefined') {
      return prefixed;
    }
  }
  return null;
}

/**
 * Extract css property or mapping from a node.
 *
 * @param  {Node} node
 * @param  {?string} propName to extract
 * @return {string | CSSStyleDeclaration}
 *         Returns the mapping if propName was not passed. Otherwise
 *         returns the computed css value.
 */
export function getComputedStyle(node, propName, ...args) {
  const css = window.getComputedStyle(node, ...args);
  if (propName) {
    return css[propName];
  } else {
    return css;
  }
}

/**
 * Figure out if an element is fixed on screen by walking up the tree.
 *
 * @param  {Node} node
 * @return {boolean}
 */
export function isFixed(node) {
  if (node === window.document.body) {
    return false;
  } else if (getComputedStyle(node, 'position') === 'fixed') {
    return true;
  } else {
    return node.parentNode ? isFixed(node.parentNode) : !!node;
  }
}

/**
 * `getClientRect` but relative to a given node rather than the viewport
 *
 * @param  {Element} node
 * @param  {Element} parent
 * @return {{
 *  left: number,
 *  top: number,
 *  right: number,
 *  bottom: number,
 *  width: number,
 *  height: number,
 * }}
 */
export function getRelativeClientRect(el, parent) {
  const elBounds = el.getBoundingClientRect();
  const parentBounds = parent.getBoundingClientRect();

  return {
    top: elBounds.top - parentBounds.top,
    left: elBounds.left - parentBounds.left,
    bottom: elBounds.bottom - parentBounds.bottom + elBounds.height,
    right: elBounds.right - parentBounds.right + elBounds.width,
    height: elBounds.height,
    width: elBounds.width,
  };
}

/**
 * Return the outer dimensions of an HTML element on screen, accounting for
 * padding + borders (offset{Width,Height}) and margins (computedStyle).
 * Theoretically faster then `getBoundingRect` due to avoiding repaint, though
 * I haven't seen much difference in practice.
 *
 * @param  {HtmlElement} el
 * @return {{width: number, height: number}} dimensions
 */
export function outerSize (el) {
  const css = window.getComputedStyle(el, null);
  return {
    width: (
      el.offsetWidth +
      (parseFloat(css.marginLeft) || 0) +
      (parseFloat(css.marginRight) || 0)
    ),
    height: (
      el.offsetHeight +
      (parseFloat(css.marginTop) || 0) +
      (parseFloat(css.marginBottom) || 0)
    ),
  };
}

/**
 * Return the dimensions of an HTML element on screen, accounting for
 * padding + borders (offset{Width,Height}).
 * Theoretically faster then `getBoundingRect` due to avoiding repaint, though
 * I haven't seen much difference in practice.
 *
 * @param  {HtmlElement} el
 * @return {{width: number, height: number}} dimensions
 */
export function innerSize (el) {
  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  };
}

/**
 * Detects if a node has a given class
 *
 * @param  {Element}  el
 * @param {string} className
 * @return {boolean} - wether or not el has className
 */
export function hasClass(el, className) {
  if (el.classList) {
    return el.classList.contains(className);
  } else {
    return new RegExp(`(^| )${className}( |$)`, 'gi').test(el.className);
  }
}

/**
 * Add a class if not there, remove otherwise
 *
 * @param  {Element}  el
 * @param  {string}  className
 */
export function toggleClass(el, className) {
  if (el.classList) {
    el.classList.toggle(className);
  } else {
    const classes = el.className.split(' ');
    const index = classes.indexOf(className);
    if (index >= 0) {
      classes.splice(index, 1);
    } else {
      classes.push(className);
    }
    // eslint-disable-next-line no-param-reassign
    el.className = classes.join(' ');
  }
}

/**
 * Add className to element
 *
 * @param  {Element}  el
 * @param  {string}  className
 */
export function addClass(el, className) {
  if (el.classList) {
    el.classList.add(className);
  } else {
    // eslint-disable-next-line no-param-reassign
    el.className += ` ${className}`;
  }
}

/**
 * Remove className from element & fails silently.
 *
 * @param  {Element}  elem
 * @param  {string}  className
 */
export function removeClass(el, className) {
  if (hasClass(el, className)) {
    if (el.classList) {
      el.classList.remove(className);
    } else {
      // eslint-disable-next-line no-param-reassign
      el.className = el.className.replace(
        new RegExp(
          `(^|\\b)${className.split(' ').join('|')}(\\b|$)`,
          'gi'
        ),
        ' '
      );
    }
  }
}


/**
 * Promise-based version of $().ready().
 *
 * @return {Promise}
 */
export function onReady() {
  return new Promise((resolve) => {
    if (['complete', 'interactive'].indexOf(document.readyState) > -1) {
      resolve();
    } else {
      document.addEventListener('DOMContentLoaded', resolve);
    }
  });
}
