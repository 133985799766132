import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as schema from '../../../../components/prop-types/schema';
import * as _strings from '../../../../utils/strings';

import { Button, Radio } from '../../../../components';

import {
    DateRangeSelector, CheckboxSelector,
} from '../../../../components/views/partner-report/filters';

export default class Filters extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onApply: PropTypes.func.isRequired,
        dirty: PropTypes.bool,
        fromDate: schema.MaybeDate.isRequired,
        toDate: schema.MaybeDate.isRequired,
        selectedGroupBy: PropTypes.arrayOf(schema.ReportGroupingDimension).isRequired,
        availableGroupBy: PropTypes.arrayOf(schema.ReportGroupingDimension).isRequired,
        selectedFilters: PropTypes.shape({
            shops: PropTypes.arrayOf(schema.Int).isRequired,
        }),
        availableCurrencies: PropTypes.arrayOf(schema.Currency).isRequired,
        targetCurrency: schema.Currency.isRequired,
        resolution: schema.ReportResolution.isRequired,
        resolutions: PropTypes.arrayOf(schema.ReportResolution).isRequired,
    }

    onUpdateDateRange = ({ startDate: fromDate, endDate: toDate }) => {
        this.props.onChange({ fromDate, toDate });
    };
    onUpdateResolution = (evt) => this.props.onChange({ resolution: evt.currentTarget.value });
    onUpdateGroupBy = nextGroupBy => this.props.onChange({ selectedGroupBy: nextGroupBy });
    onUpdateCurrency = (evt) => this.props.onChange({ targetCurrency: evt.currentTarget.value });

    render() {
        const { fromDate, toDate, selectedGroupBy, availableGroupBy } = this.props;

        return (
            <div className="flex flex-column">

                <div className="flex justify-between">
                    <Button className="mx1" level="primary" block
                        disabled={!this.props.dirty}
                        onClick={this.props.onApply}>
                        Apply settings
                    </Button>
                </div>

                <div className="m1 p1 border rounded border-color-gray-lighter">
                    <div className="medium my1">
                        Date Range
                    </div>
                    <DateRangeSelector fromDate={fromDate} toDate={toDate}
                        onChange={this.onUpdateDateRange} />
                    <div className="flex justify-between items-baseline flex-wrap my1">
                        <div className="medium inline-block">Currency</div>
                        <select className="field is-inline"
                            onChange={this.onUpdateCurrency}
                            value={this.props.targetCurrency}
                        >
                            {this.props.availableCurrencies.map(cur => {
                                const symbol = _strings.currencySymbol(cur);
                                return (
                                    <option key={cur} value={cur}>
                                        {cur}{symbol !== cur && ` ${symbol}`}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="flex justify-between items-center flex-wrap my1">
                        <div>
                          <div className="medium py1"> Group report </div>
                          <CheckboxSelector
                            selected={selectedGroupBy}
                            options={availableGroupBy}
                            onUpdateSelection={this.onUpdateGroupBy}
                            preventEmpty
                          />

                          {selectedGroupBy.indexOf('date') !== -1 && (
                            <div>
                              <div className="mxn1">
                                <div className="m1 medium">Resolution</div>
                                {this.props.resolutions.map(resolution =>
                                  <Radio
                                    key={resolution}
                                    inline
                                    className="mx1"
                                    checked={this.props.resolution === resolution}
                                    name="reportResolution"
                                    id={`reportResolution-${resolution}`}
                                    value={resolution}
                                    label={_strings.titleCase(resolution)}
                                    onChange={this.onUpdateResolution}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}
