import React from 'react';
import PropTypes from 'prop-types';


const AlertList = (props) => {
  return (
    <React.Fragment>
      {
        props.alerts.map(
          (alert, i) => {
            const levelClasses = (alert.level === 'error'
              ? 'color-danger border-color-danger'
              : 'color-success border-color-success');
            return (
              <div key={i} className={[props.baseClasses, levelClasses].join(' ')}>
                {alert.message}
              </div>
            );
          }
        )
      }
    </React.Fragment>
  );
};

AlertList.defaultProps = {
  baseClasses: 'p1 my1 border',
};

AlertList.propTypes = {
  alerts: PropTypes.arrayOf(Object).isRequired,
  baseClasses: PropTypes.string,
  levelClasses: PropTypes.string,
};


export default AlertList;
