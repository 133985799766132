import {Redirect, Switch} from 'react-router-dom';
import React, {PureComponent} from 'react';

import * as schema from '../../../components/prop-types/schema';
import {withData} from '../../../components/helpers';
import {
  ExternalPrivateRoute as PrivateRoute,
  Route,
} from '../../../components/routing';
import NoMatchRoute from '../../../components/views/NotFound';
import Icon from '../../../components/Icon';
import {Filler, VerticalViewport} from '../../../components/layout';

import * as customerAPI from '../customer-api';
import CategoryCpcsSection from './category-cpcs';

export default class CpcSettingsApp extends PureComponent {

  render() {
    return (
      <VerticalViewport>
        <div className={'my2 mx2 left-align h2 bold color-st-blue'}>
          Category-level CPC bidding
        </div>
        <div className={'mx2 left-align'}>
          Give more exposure to specific product categories by increasing their
          CPCs here. To do so, simply look for the category you would like to
          boost below, and enter the new CPCs for each device. Press save, and
          your new CPCs will be effective at midnight.
        </div>
        <div className={'mx2 left-align'}>
           For more information, check out our online tutorial <a
            href={'https://partner.stylight.net/help-center/tutorials/cpc-bidding-video-tutorial/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </div>
        <RoutesWithData {...this.props}/>
      </VerticalViewport>
    );
  }
}

CpcSettingsApp.propTypes = {
  user: schema.ExternalUser,
  location: schema.RouterLocation.isRequired,
};

const Routes = props => {
  if (
    props.customerData.affiliate !== 'stylight_click' &&
    props.customerData.affiliate !== 'cpc_without_invoice' &&
    !props.customerData.category_bid_import_enabled
  ) {
    return (
      <Filler className="p2 justify-center items-center">
        <div className="p1 h1 bold center border rounded border-color-warning color-warning">
          <div>
            <Icon size="xlarge" text="block"/>
          </div>
          Feature not available.
        </div>
      </Filler>
    );
  }

  return (
    <Switch>
      <PrivateRoute user={props.user} path="/cpc-bidding" exact
                    component={() => <Redirect from="/" to="/cpc-bidding/category-pricing"/>}/>
      <PrivateRoute user={props.user} path="/cpc-bidding/category-pricing"
                    categoryBidImportEnabled={props.customerData.category_bid_import_enabled}
                    component={CategoryCpcsSection}/>
      <Route component={NoMatchRoute} homeRoute="/"/>
    </Switch>
  );
};

Routes.propTypes = {
  user: schema.ExternalUser.isRequired,
  customerData: schema.CustomerInfo.isRequired,
};

const RoutesWithData = withData({
  fields: {
    customerData: customerAPI.self,
  },
  mapPropsToArgs: {
    customerData: () => null,
  },
})(Routes);
