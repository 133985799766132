import * as Datagrid from '../../../../components/datagrid';
import * as _date from '../../../../utils/date';
import * as _sentry from '../../../../sentry';
import * as _config from '../../../../config';
import * as http from '../../../../utils/http';

export const makeReportQuery = (opts) => {
  return http.retryOnFailure(() => http.get(
    _config.url('reports/external/sales-performance'), opts
  ), {
    maxCalls: 2,
    statusCodes: [502, 504],
  }).then(http.parseJSONAPI).then(({data}) => data).catch(err => {
    http.handle401(err, '/external/login');
    _sentry.captureException(err, {level: 'error', extra: {opts}});
  });
};


export const queryVariables = opts => {
  const variables = {
    from_date: _date.toNaiveISOString(opts.fromDate).slice(0, 10),
    to_date: _date.toNaiveISOString(opts.toDate).slice(0, 10),
    sort_by: Datagrid.utils.buildSortString(opts.sortList || []),
    offset: opts.offset,
    limit: opts.limit,
    group_by: opts.selectedGroupBy,
    resolution: opts.resolution,
    currency: opts.targetCurrency,
  };

  if (opts.selectedGroupBy && opts.selectedGroupBy.length > 0) {
    opts.availableGroupBy
      .filter(key => opts.selectedGroupBy.indexOf(key) === -1)
      .forEach(key => {
        // Make sure we do not sort by such columns as well
        variables.sort_by = (
          (variables.sort_by || '')
          .replace(new RegExp(`,?[+-]?${key},?`, 'g'), '')
          .replace(/,$/g, '')
          .replace(/^,/g, '')
        );
      });
  }

  return variables;
};
