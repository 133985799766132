import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import * as schema from '../../../prop-types/schema';
import * as _func from '../../../../utils/func';

import {TreeSelector, filterByName} from './TreeSelector';

const formatCategoryFolders = _func.memoize(folders => (folders || []).map(
  folder => ({
    ...folder,
    items: folder.categories || [],
    subfolders: formatCategoryFolders(folder.subfolders || []),
  })
));

const nameLabel = item => item.name;
const itemLabel = category => `${category.name} (${category.id})`;
const folderLabel = folder => `${folder.name} (${folder.id})`;

/**
 * Full campaign filter.
 */
export default class CategorySelector extends PureComponent {

  render() {
    return (
      <TreeSelector
        folders={formatCategoryFolders(this.props.categoryFolders)}
        selected={this.props.selected}
        onUpdateSelection={this.props.onUpdateSelection}
        renderItemLabel={this.props.hideId ? nameLabel : itemLabel}
        renderFolderLabel={this.props.hideId ? nameLabel : folderLabel}
        filterItems={filterByName}
        placeholder="Filter categories and tags by name..."
        noEntryPlaceholder="There is no category matching your search."
      />
    );
  }
}

CategorySelector.propTypes = {
  categoryFolders: PropTypes.arrayOf(schema.CategoryFolder).isRequired,
  onUpdateSelection: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(schema.Int).isRequired,
  hideId: PropTypes.bool,
};
