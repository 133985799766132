import React from 'react';

/**
 * Summarize a list selection.
 *
 * @param {any[]} selection
 * @param {(any[]) => void} update
 * @param {string} singularTerm
 * @param {string} pluralTerm
 * @return React.Node
 */
export const filterSummary = (selection, update, singularTerm, pluralTerm) => {

  const onClick = evt => {
    evt.stopPropagation();
    evt.preventDefault();
    update([]);
  };

  if (selection.length === 0) {
    return (
      <div className="color-gray-light">No filter enabled.</div>
    );
  } else {
    const itemType = selection.length === 1 ? singularTerm : pluralTerm;
    return (
      <div className="color-gray-light">
        {selection.length} {itemType} selected. <a href="#" onClick={onClick}>
          clear
        </a>
      </div>
    );
  }
};
