import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {classname} from '../../../utils';

import DatagridPropTypes from '../prop-types';
import {sortListToMap} from '../utils';

import * as _cells from './Cell';

export default class Table extends PureComponent {

  static propTypes = {
    columns: PropTypes.arrayOf(DatagridPropTypes.Column).isRequired,
    items: PropTypes.arrayOf(Object).isRequired,
    onUpdateParameter: PropTypes.func,
    parameters: DatagridPropTypes.Parameters,

    // (row: Object) => React.Element
    subcomponent: PropTypes.func,

    loading: PropTypes.bool,

    className: PropTypes.string,
    bodyClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    rowClassName: PropTypes.string,
    cellClassName: PropTypes.string,
    subcomponentClassName: PropTypes.string,
  };

  static defaultProps = {
    className: 'datagrid',
    bodyClassName: 'datagrid-body',
    headerClassName: 'datagrid-header',
    rowClassName: 'datagrid-row',
    cellClassName: 'datagrid-cell',
    subcomponentClassName: 'datagrid-subcomponent',
    parameters: {
      sortList: [],
      search: '',
      limit: null,
      offset: null,
    },
  };

  constructor(...args) {
    super(...args);
    this.state = {
      openSubcomponents: {},
    };
  }

  toggleSubcomponent = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const index = evt.target.getAttribute('data-index');
    this.setState({
      openSubcomponents: {
        ...this.state.openSubcomponents,
        [index]: !this.state.openSubcomponents[index],
      },
    });
  }

  colspan = () => this.props.columns.length + (this.props.subcomponent ? 1 : 0);

  onRequestSort = colname => this.props.onUpdateParameter({
    name: 'sort',
    value: colname,
  });

  renderBodyRows = () => {

    const {
      items, subcomponent, cellClassName, rowClassName, subcomponentClassName,
    } = this.props;

    const rows = [];
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      const showSubcomponent = subcomponent && this.state.openSubcomponents[index];

      rows.push(
        <_Row className={rowClassName} key={index}>
        {
          !!subcomponent && (
            <td key="toggle" data-index={index} className={classname(
              this.props.cellClassName,
              `${this.props.subcomponentClassName}-trigger`,
              {
                'is-open': showSubcomponent,
              }
            )} onClick={this.toggleSubcomponent} />
          )
        }
        {
          this.props.columns.map((column) =>
            <_Cell
              key={column.key}
              column={column}
              item={item}
              className={cellClassName}
            />
          )
        }
        </_Row>
      );

      if (showSubcomponent) {
        rows.push(
          <_Row className={rowClassName} key={`${index}:subcomponent`}>
            <td
              className={classname(cellClassName, subcomponentClassName)}
              colSpan={this.colspan()}
            >
              {this.props.subcomponent(item)}
            </td>
          </_Row>
        );
      }
    }

    return rows;
  }

  renderHeaderRow() {
    const {
      columns, subcomponent, parameters, cellClassName, rowClassName,
    } = this.props;

    const sortMap = sortListToMap(parameters.sortList);

    return (
      <_Row className={rowClassName}>
        {
          !!subcomponent
          && <td className={cellClassName}></td>
        }
        {
          columns.map((column) => (
            <_HeaderCell
              key={column.key}
              column={column}
              className={cellClassName}
              onSort={this.props.onUpdateParameter && this.onRequestSort}
              sortDirection={sortMap[column.key]}
            />
          ))
        }
      </_Row>
    );
  }

  render() {
    const {loading, className, headerClassName, bodyClassName} = this.props;

    return (
      <_Table className={classname(className, {'is-loading': loading})}>
        <_Header className={headerClassName}>
          {this.renderHeaderRow()}
        </_Header>
        <_Body className={bodyClassName}>
          {this.renderBodyRows()}
        </_Body>
      </_Table>
    );
  }
}

const wrapperComponent = (name, Comp) => {
  class Wrapper extends PureComponent {

    static displayName = name;

    static propTypes = {
      children: PropTypes.node.isRequired,
      className: PropTypes.string,
      style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    };

    render() {
      return (
        <Comp className={this.props.className} style={this.props.style}>
          {this.props.children}
        </Comp>
      );
    }
  }

  return Wrapper;
};

const _Table = wrapperComponent('_Table', props => <table {...props}/>);
const _Row = wrapperComponent('_Row', props => <tr {...props}/>);
const _Header = wrapperComponent('_Header', props => <thead {...props}/>);
const _Body = wrapperComponent('_Body', props => <tbody {...props}/>);

// The default Cell / HeaderCell are generic (<div>) but to get the colspan
// behaviour for subcomponents we need actual `td` elements.
const _td = props => <td {...props}></td>;
const _HeaderCell = props => <_cells.HeaderCell {...props} _wrapper={_td}/>;
const _Cell = props => <_cells.Cell {...props} _wrapper={_td}/>;
