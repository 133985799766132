/**
 * Fake a download on the client side by generating a Blob and forcing a
 * click on a download link.
 *
 * @param  {string | Array<string>} data - file content
 * @param  {string} filename
 * @param  {string} filetype
 * @return {string}  url to the created Blob.
 */
export default function exportData (data, filename, filetype) {
  let input;
  if (Array.isArray(data)) {
    input = [data.join('\n')];
  } else if (typeof data === 'string') {
    input = [data];
  } else {
    throw new TypeError('Invalid data for export, expecting [string] or string.');
  }

  // We add UTF-8 BOM to make sure software like Excel can open the file.
  const blob = new Blob(['\ufeff', ...input], { type: filetype });
  const blobUrl = URL.createObjectURL(blob);

  const link = document.createElement('a');

  [
    ['download', filename],
    ['href', blobUrl],
    ['target', '_blank'],
    ['hidden', 'true'],
  ].forEach(
    (kv) => link.setAttribute(...kv)
  );

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
  });
  link.dispatchEvent(clickEvent);
  return blobUrl;
}

const addExtension = ext => value => {
  if (value.slice(-1 * (ext.length + 1)) !== `.${ext}`) {
    return `${value}.${ext}`;
  } else {
    return value;
  }
};

/**
 * Wrapper around exportData for CSV data.
 *
 * @param  {Array<string>} columns
 * @param  {Array<Object>} items
 * @param  {string} filename
 * @return {string}
 */
export function exportCSV (columns, items, filename) {
  const header = columns.map((c) => `"${c}"`).join(',');
  const rows = items.map(
    (item) => columns.map((c) => `"${item[c] || ''}"`).join(',')
  );

  const data = [header, ...rows];
  return exportData(data, addExtension('csv')(filename), 'text/csv');
}
