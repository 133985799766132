import React from 'react';
import PropTypes from 'prop-types';

import {Button} from '..';

export default function NoMatchRoute(props) {
  return (
    <div className="flex-1 p2 flex flex-column justify-center items-center">
      <p className="bold h1 bolder">Uh oh...</p>
      <p className="h3">
        It looks like we can&apos;t find the page you are looking for.
        <br/>
        It may have been moved or does not exist anymore.
      </p>
      <div className="mxn1">
        { 'history' in window &&
          <Button onClick={() => window.history.back()}
                  icon="arrow_back"
                  className="mx1">Go back</Button> }
        <Button href={props.homeRoute} ghost icon="home"
                level="primary" className="mx1">Home Page</Button>
      </div>
    </div>
  );
}

NoMatchRoute.propTypes = {
  homeRoute: PropTypes.string.isRequired,
};
