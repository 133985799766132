import {string} from 'prop-types';
import {chainableValidatorFactory, chainValidators} from './_utils';

/**
 * (Factory) Validator to check that value matches a Regex.
 *
 * @param {any} constValue
 * @return {(Object, string, ...Array<any>) => Error|null}
 */
export default chainableValidatorFactory(
  function regexValidatorFactory (re) {

    let _regexp;
    if (re instanceof RegExp) {
      _regexp = re;
    } else if (typeof re === 'string') {
      _regexp = new RegExp(re, 'i');
    } else {
      throw new TypeError(`Invalid type for regex validator: ${typeof re}`);
    }

    function regexValidator (props, propName, componentName) {
      const value = props[propName];
      if (!_regexp.test(value)) {
        return new Error(
          `Expected prop \`${propName}\` in \`${componentName || 'ANONYMOUS'}\` to match (\`${_regexp.toString()}\`) but got \`${value}\``
        );
      }
      return null;
    }

    return chainValidators(string, regexValidator);
  }
);
