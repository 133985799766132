/** All renderers relevant to CPC category cpcs. */
import React from 'react';
import PropTypes from 'prop-types';
import _groupBy from 'lodash/groupBy';

import classname from '../../../../utils/classname';
import * as _strings from '../../../../utils/strings';
import * as schema from '../../../../components/prop-types/schema';
import { TreeViewWithState } from '../../../../components/TreeView';

import {
  flattenCategoryFolder,
  isActiveContract,
  ActiveLabel,
  renderValidFrom,
  renderValidTo,
  isPendingContract,
  Label,
} from '../utils';

const LABELS = {
  expired: <Label color={Label.grey} text={'Expired'}/>,
  active: <Label color={Label.green} text={'Active'}/>,
  pending: <Label color={Label.blue} text={'Pending'}/>,
};

const countBids = (node, prices) => node.children.reduce((s, c) =>
  s + (prices[c.id] && prices[c.id].length ? 1 : 0) + countBids(c, prices), 0);

const calculateEffectiveCpcs = (path, prices) => {
  const effectiveCpcs = (path
    .map(nodeId => prices[nodeId])
    .filter(e => e != null)
    .reduce((acc, cur) => (Object.assign(acc, cur)), {})
  );
  const effectiveDeviceCpcs = (Object.values(effectiveCpcs)
    .reduce((acc, {device_type: dt, value: cpc}) => Object.assign(acc, {[dt]: cpc}), {})
  );

  return effectiveDeviceCpcs;
};

const renderCpcs = (cpcs, currency) => Object.entries(cpcs).map(
  ([dt, value]) => (
    `${_strings.currencySymbol(currency)} ${value} (${dt})`)
).join(', ');

function CategoryFolder(props) {
  const toggle = () => props.toggle(props.id);
  const effectiveCpcs = calculateEffectiveCpcs(props.node.path, props.prices);
  const childBids = countBids(props.node, props.prices);
  const hasChildBids = childBids > 0;
  return (
    <div onClick={toggle} className="cursor-pointer flex justify-between hover-bg-gray-muted"
      style={{marginLeft: `${1 * props.level}em`}}>
      <div>
        <span className={
          classname('mr1 bold', {'color-primary': props.expanded, 'color-gray-dark': !props.expanded})
        }>{props.expanded ? '-' : '+'}</span>
        <span className={classname({'color-gray-dark': !hasChildBids})}>{props.node.name}</span>
      </div>
      <div>{renderCpcs(effectiveCpcs, props.currency)}</div>
      {hasChildBids && !props.expanded && <div>{childBids} bids on subcategories</div>}
    </div>
  );
}

CategoryFolder.propTypes = {
  toggle: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  level: PropTypes.number.isRequired,
  node: PropTypes.shape({
    name: PropTypes.string,
    children: PropTypes.instanceOf(Array),
    path: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  prices: PropTypes.objectOf(
    PropTypes.shape({
      tag_id: PropTypes.number,
      device_type: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  currency: PropTypes.string.isRequired,
};

function LeafCategory(props) {
  const effectiveCpcs = calculateEffectiveCpcs(props.node.path, props.prices);
  const hasEffectiveCpcs = Object.keys(effectiveCpcs).length > 0;
  return (
    <div className="cursor-pointer hover-bg-gray-muted" style={{marginLeft: `${1 * props.level}em`}}>
      <div className={classname('flex justify-between flex-wrap',
        {'color-gray-dark': !hasEffectiveCpcs}
      )}>
        <div className="inline-block medium">{props.node.name}</div>
        { hasEffectiveCpcs &&
          <div className="inline-block">{renderCpcs(effectiveCpcs, props.currency)}</div>
        }
      </div>
    </div>
  );
}

LeafCategory.propTypes = {
  level: PropTypes.number.isRequired,
  node: PropTypes.shape({
    name: PropTypes.string,
    children: PropTypes.instanceOf(Array),
    path: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  prices: PropTypes.objectOf(
    PropTypes.shape({
      tag_id: PropTypes.number,
      device_type: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  currency: PropTypes.string.isRequired,
};


export default class CategoryCPCSDetail extends React.PureComponent {

  render() {
    const {props} = this;
    const prices = _groupBy(props.entry.prices, 'tag_id');
    const active = isActiveContract(props.entry);
    const pending = isPendingContract(props.entry);

    return (
      <div className="">
        <div className="p2 border-bottom border-color-gray-lighter">
          <div className="flex flex-wrap justify-between">
            <div className="h3 bold">Category CPCs</div>
            <div>
            {(active && LABELS.active) || (pending && LABELS.pending) || LABELS.expired}
            </div>
          </div>
          <div className="">
            <span className="color-gray-dark">Active from</span> {renderValidFrom(props.entry)}
            {!(active || pending) && <span> <span className="color-gray-dark">to</span> {renderValidTo(props.entry)}</span>}
          </div>
          <div className="mx1">
            {(isActiveContract(props.entry) && <ActiveLabel active/>) || <div></div>}
          </div>
        </div>
        <div className="scroll-y p2 min-width-3" style={{maxHeight: 512}}>
          <TreeViewWithState
            tree={props.categories.map(flattenCategoryFolder)}
            renderNode={CategoryFolder}
            renderLeaf={LeafCategory}
            currency={props.entry.currency}
            prices={prices}
          />
        </div>
      </div>
    );
  }
}

CategoryCPCSDetail.propTypes = {
  entry: schema.CategoryCPCCollection.isRequired,
  categories: PropTypes.arrayOf(schema.CategoryFolder).isRequired,
};

