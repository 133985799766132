import _pick from 'lodash/pick';
import * as _func from './func';
import * as _http from './http';

const compress = _func.memoize(query => query.split(/\s+/).join(' ').trim());

const GQL_CACHE = {};

if (process.env.NODE_ENV === 'production') {
  GQL_CACHE.__timeout__ = 5 * 60 * 1000;
} else {
  GQL_CACHE.__timeout__ = -1;
}

/**
 * @param {string} query
 * @return {string[]}
 */
const extractVariableNames = _func.memoize(
  function extractVariableNames (query) {
    const matches = query.match(/\$[a-zA-Z_\-0-9]+/g);
    if (!matches) return [];
    return Object.keys(
      matches.reduce(
        (set, value) => Object.assign(set, {[value.slice(1)]: true}),
        {}
    ));
  }
);

/**
 * Make a GQL request.
 *
 * @param  {string} [url="/"]
 * @param  {string} query
 * @param  {Object} [variables={}]
 * @param {boolean} [usePost=false]
 * @return {Promise<any>}
 */
export const request = function request(
  url = '/',
  query,
  variables = {},
  usePost = false
) {
  const compressedQuery = compress(query);
  const endpoint = `${url}/graphql`.replace(/\/\//g, '/');
  const isMutation = query.indexOf('mutation') !== -1;
  const validVariables = _pick(variables, extractVariableNames(query));

  const future = (
    isMutation || usePost
      ? _http.post(endpoint, null, {
        query: compressedQuery,
        variables: validVariables,
      })
      : _http.get(endpoint, {
        query: compressedQuery,
        variables: JSON.stringify(validVariables),
      }, {cache: GQL_CACHE})
  );

  return future.then(_http.parseJSONAPI).then(({data}) => data);
};
