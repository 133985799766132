import PropTypes from 'prop-types';

import CustomPropTypes from '../prop-types';

const Column = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: CustomPropTypes.requiredUnless(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]), props => props.key),
  sortable: PropTypes.bool,
  renderer: PropTypes.func,
});

const SortList = PropTypes.arrayOf(
  CustomPropTypes.tuple([
    PropTypes.string,
    PropTypes.oneOf([-1, 0, 1]),
  ])
);

const SortMap = PropTypes.objectOf(
  PropTypes.oneOf([-1, 0, 1])
);

const Parameters = PropTypes.shape({
  offset: CustomPropTypes.integer,
  limit: CustomPropTypes.integer,
  sortList: SortList.isRequired,
  search: PropTypes.string,
});

const Pagination = PropTypes.shape({
  offset: CustomPropTypes.integer.isRequired,
  count: CustomPropTypes.integer,
  limit: CustomPropTypes.integer.isRequired,
});


export default CustomPropTypes.__propTypesProductionMask({
  Column,
  SortList,
  SortMap,
  Parameters,
  Pagination,
});
