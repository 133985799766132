import request from 'superagent';

const DEFAULT_CONFIG = {
  apiUrl: process.env.API_URL || '/app',
  environment: 'development',
  sentryDsn: process.env.SENTRY_CLIENT_DSN || null,
  googleAnalyticsId: process.env.GOOGLE_ANALYTICS_ID || null,
};

const UNSET = Symbol('UNSET');
let _CONFIG = UNSET;

/**
 * Use this to get the configuration synchronously
 * under the assumption that load was called before.
 * @returns {object}
 */
export function get() {
  if (_CONFIG === UNSET) {
    throw new Error(
      'Call `fetchConfig` before you can access the configuration object.'
    );
  } else {
    return _CONFIG;
  }
}

/**
 * Use this to get the configuration asynchronously
 * potentially making a network request.
 *
 * @param {boolean} [reset=false]
 * @returns {Promise<object>}
 */
export function load(reset = false) {

  if (_CONFIG !== UNSET && !reset) {
    return Promise.resolve(_CONFIG);
  }

  let configFuture;
  if (process.env.NODE_ENV === 'development') {
    configFuture = Promise.resolve(DEFAULT_CONFIG);
  } else {
    configFuture = new Promise((resolve, reject) => {
      request.get('/app/client-config').end((err, response) => {
        if (err) {
          reject(err);
        } else {
          const config = JSON.parse(response.text);
          resolve({...DEFAULT_CONFIG, ...config});
        }
      });
    });
  }

  return configFuture.then(cfg => {
    _CONFIG = cfg;
    Object.freeze(_CONFIG);
    return _CONFIG;
  });
}

export const url = (...parts) => `${get().apiUrl}/${parts.join('/')}`.replace(/\/\//g, '/');
export const apiServiceUrl = () => url('apiservice');

export default get;
