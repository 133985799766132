import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {apply} from '../../../utils/func';
import {exportCSV} from '../../../utils/export';
import {Button, Icon, Spinner} from '../..';

export default class ExportButton extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      err: null,
    };
  }

  export = () => this.props.fetch({
    ...this.props.mapPropsToVariables(this.props),
  }).then(({items}) => {
    const {columns} = this.props;
    const labels = columns.map(col => apply(col.label, this.props) || col.key);
    let parsed = [];

    const extractFields = item => columns.reduce(
      (rendered, col, index) => {
        const colLabel = labels[index];
        const str = col.render ? col.render(item, col.key) : String(item[col.key]);
        return {
          ...rendered,
          [colLabel]: str == null ? '' : str,
        };
      }, {}
    );

    return new Promise((resolve, reject) => {
      // The operations on a large set of items can be time consuming and block the UI,
      // this simulate the async behaviour and makes sure the UI unlocks evey once
      // in a while ...
      // Ideally we change this to use WebWorker and offload all of the string
      // formatting.
      const next = () => {
        try {
          if (items.length) {
            parsed = parsed.concat(items.splice(0, 256).map(extractFields));
            setTimeout(next, 0);
          } else {
            resolve(this.props._export(labels, parsed, this.props.filename));
          }
        } catch (err) {
          reject(err);
        }
      };

      next();
    });
  });

  onClick = () => {
    this.setState({loading: true, err: null});
    this.export().then(
      () => this.setState({loading: false, err: null})
    ).catch(
      (err) => this.setState({loading: false, err: err}),
    );
  }

  label = () => {
    if (this.state.err) {
      return 'Something went wrong. Click to retry.';
    } else if (this.state.loading) {
      return <span>Downloading <Spinner/></span>;
    } else {
      return <span><Icon size="small" text="file_download"/> Download CSV</span>;
    }
  }

  render() {
    return (
      <Button
        level={this.state.err ? 'danger' : null}
        small
        onClick={this.onClick}
        disabled={this.state.loading}
        className={this.props.className}
      >
        {this.label()}
      </Button>
    );
  }
}

ExportButton.propTypes = {
  className: PropTypes.string,

  // Data fetcher.
  // By default will be called with `props.mapPropsToVariables(props)` as
  // single argument.
  //
  // (args: any) => Promise<{items: object[]}>
  fetch: PropTypes.func.isRequired,

  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    render: PropTypes.func,
  })).isRequired,

  filename: PropTypes.string.isRequired,

  // Use this if it is necessary to transform the props before passing them
  // to `fetch`.
  //
  // (args: object) => any
  mapPropsToVariables: PropTypes.func.isRequired,

  // Defaults to `exportCSV`.
  // (keys: string[], items: object[], filename: string)
  _export: PropTypes.func,
};

ExportButton.defaultProps = {
  _export: exportCSV,
  mapPropsToVariables: x => x,
};
