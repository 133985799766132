/**
 * Central repository for semantic prop types.
 */

import PropTypes from 'prop-types';
import * as ReactRouter from 'react-router-dom';
import * as _strings from '../../utils/strings';
import CustomPropTypes from './';

export const Int = CustomPropTypes.integer;
export const MaybeDate = PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]);

export const Currency = PropTypes.oneOf(Object.keys(_strings.CURRENCY_SYMBOLS));
export const ReportResolution = PropTypes.string;
export const ReportGroupingDimension = PropTypes.string;
export const ProductReportType = PropTypes.string;
export const Site = CustomPropTypes.regex(_strings.SITE_ENUM_RE);
export const Locale = CustomPropTypes.regex(_strings.LOCALE_RE);

export const _DeviceTypes = ['Desktop', 'Mobile', 'Tablet', 'Other'];
export const DeviceType = PropTypes.oneOf(_DeviceTypes);

export const _Verticals = ['Fashion', 'HomeAndLiving', 'Beauty'];
export const Vertical = PropTypes.oneOf(_Verticals);

export const _Affiliates = ['stylight_click', 'zalando_pn', 'amazon_pn',
                            'cpc_without_invoice', 'fake_cpo'];
export const Affiliate = PropTypes.oneOf(_Affiliates);

const _ID_AND_NAME_SHAPE = PropTypes.shape({
  id: CustomPropTypes.integer.isRequired,
  name: PropTypes.string.isRequired,
});

export const Shop = PropTypes.shape({
  id: Int.isRequired,
  name: PropTypes.string.isRequired,
  site: Site.isRequired,
});

export const Campaign = _ID_AND_NAME_SHAPE;
export const Company = _ID_AND_NAME_SHAPE;
export const Category = _ID_AND_NAME_SHAPE;

export const CampaignFolder = PropTypes.shape({
  id: Int.isRequired,
  name: PropTypes.string.isRequired,
  campaigns: PropTypes.arrayOf(Campaign).isRequired,
  subfolders: PropTypes.arrayOf(CustomPropTypes.lazy(() => CampaignFolder)),
});

export const CategoryFolder = PropTypes.shape({
  id: Int.isRequired,
  name: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(Category).isRequired,
});

export const RouterLocation = ReactRouter.Route.propTypes.location;

export const USER_TYPES = ['stylight_employee', 'stylight_customer'];

const BaseUserShape = {
  email: CustomPropTypes.email.isRequired,
  type: PropTypes.oneOf(USER_TYPES).isRequired,
  meta: PropTypes.object.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const User = PropTypes.shape({
  ...BaseUserShape,
  __sudo__: PropTypes.shape(BaseUserShape),
});

export const InternalUser = PropTypes.shape({
  ...BaseUserShape,
  type: CustomPropTypes.constant(USER_TYPES[0]),
});

export const ExternalUser = PropTypes.shape({
  ...BaseUserShape,
  type: CustomPropTypes.constant(USER_TYPES[1]),
  username: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    shop_id: Int.isRequired,
  }).isRequired,
});

export const CustomerInfo = PropTypes.shape({
  locale: Locale,
  online: PropTypes.bool,
  affiliate: Affiliate.isRequired,
  category_bid_import_enabled: PropTypes.bool,
});

export const ContractCPC = PropTypes.shape({
  value: PropTypes.number.isRequired,
  vertical: Vertical.isRequired,
  device_type: DeviceType,
  max_value: PropTypes.number,
});

export const _CPCContractShape = {
  id: Int.isRequired,
  valid_from: MaybeDate.isRequired,
  valid_to: MaybeDate.isRequired,
  prices: PropTypes.arrayOf(ContractCPC).isRequired,
  currency: Currency.isRequired,
  premium: PropTypes.bool,
  flexible_pricing: PropTypes.bool,
};

export const CurrentCPCContract = {
  current_contract: PropTypes.shape(_CPCContractShape),
};

export const CPCContract = PropTypes.shape(_CPCContractShape);

export const CategoryCPC = PropTypes.shape({
  value: PropTypes.number.isRequired,
  tag_id: Int.isRequired,
  device_type: DeviceType,
});

export const CategoryCPCCollection = PropTypes.shape({
  id: Int.isRequired,
  valid_from: MaybeDate.isRequired,
  valid_to: MaybeDate.isRequired,
  currency: Currency.isRequired,
  prices: PropTypes.arrayOf(CategoryCPC).isRequired,
});

export const PropertyIdTable = PropTypes.shape({
  locale: PropTypes.string.isRequired,
  property_id: PropTypes.string.isRequired,
});
