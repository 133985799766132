import React, {PureComponent} from 'react';
import { connect } from 'react-refetch';
import PropTypes from 'prop-types';

import config from '../../../config';

// COMPONENTS
import PropertyId from './PropertyIds';
import Notification from '../../../components/Notification';
import { Alert } from '../../../components/Alert';

class Tracking extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showNotification: false,
        };
    }

    showNotification = () => {
        this.setState({showNotification: true});
    };

    hideNotification = () => {
        this.setState({showNotification: false});
    };

    render() {
        const {idsFetch} = this.props;

        if (idsFetch.fulfilled) {
            const filteredTable = idsFetch.value.properties.filter((value) => value.locale !== 'DEFAULT');

            return (
                <div className="flex sm-flex fill bg-white">
                    <div className="p3">
                        <div className="h1 bold color-st-blue">
                        Sales Tracking
                        </div>
                        <PropertyId
                            tableData={filteredTable}
                            showNotification={this.showNotification}
                        />
                        <Notification
                            text="Copied!"
                            show={this.state.showNotification}
                            hideNotification={this.hideNotification}
                            interval="fast"
                        />
                    </div>
                </div>
            );
        }
        else if (idsFetch.rejected) {
            return(
                <div className="flex flex-1 flex-row fill">
                    <div className="p1 flex-none flex-column scroll-x mt2 max-width-4">
                        <Alert level="error" message="We were unable to find the proper data for your setup. This normally happens when your shop isn't expected to use our Tracking solution. If you think this is wrong, please contact your Stylight Account Manager" show={true}/>
                    </div>
                </div>
            );
        }

        return (<div></div>);
    }
}

Tracking.propTypes = {
    idsFetch: PropTypes.object, //eslint-disable-line react/forbid-prop-types
};

export default connect(() => ({
    idsFetch: `${config().apiUrl}/tracking`,
}))(Tracking);
