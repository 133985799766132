import React from 'react';
import PropTypes from 'prop-types';

import {deepEqual, getNestedProperty, setNestedProperty} from '../../utils/objects';
import Button from '../Button';
import AlertList from '../AlertList';

/** Better replacement for the feedUIForm interface.
 * -----------------------------------------------------------------------------
 *
 * Rationale -> Do one thing and one thing well in one way.
 * - This does NOT load the data upfront and expects it synchronously, similar
 *   to standard input field after which it manages its own internal state.
 *   THIS IS ON PURPOSE TO KEEP THE RESPONSIBILITY CONSTRAINED TO WHAT A FORM
 *   DOES. You can use it sagely in combination with Conveyor or any other
 *   loading strategy.
 * - It lets consumers specify validation and submission interfaces as Promise
 *   generating functions and does not prescribe anything else.
 * - It is used at render time and not at definition time (no static HoC).
 * - It takes care of displaying alerts in case of success and error without
 *   interacting with a global module.
 * - It can validate on the fly.
 * - Rendering of the fieldset(s) and errors is left to the consumer.
 * - Does not support cyclic datastructures as it will check deep equality in
 *   some situation (performance impact should be minimal as form structures
 *   are usually fairly constrained)
 * - This should be able to work with basic datastructures and does not require
 *   the use of ImmutableJS on purpose to keep things simple and easy to debug
 *   without adding an extra cognitive layer. ALL DATASTRUCTURES USED MUST BE
 *   SIMPLE OBJECTS.
 * - This does not replace the data in the form as it assumes the data is a
 *   mirror of the state and if submit is successful, the local data matches
 *   the remote data. This could be adapted, but I would suggest leveraging
 *   something like the conveyor for this in order to keep this as single
 *   purpose as possible.
 *
 * [TODO]
 * - Support other types of input fields as rendering helpers and in onChange
 **/
export default class FormWrapper extends React.PureComponent {

  static propTypes = {
    // Title for the form
    legend: PropTypes.string,

    // Render callback, see `render` and `rendrProps` for interface ...
    children: PropTypes.func.isRequired,

    // Hook to add custom buttons to the actions row
    renderCustomActions: PropTypes.func,

    // Original datastructure, think of it like defaultValue for input tags.
    // The data object passed to `validate` and `onSubmit` will have the
    // exact same shape as the one you pass as `defaultData`.
    // For best results, only pass the data that can be modified by the form,
    // data used for rendering should remain in the consumer component.
    defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    // Submit callback
    // (object) => void
    onSubmit: PropTypes.func.isRequired,

    // Validator : (object) => object|null
    validate: PropTypes.func,

    // Use this to customize how the form's initial state is compared to its current state.
    // If this is prop is not passed, the comparison defaults to a deepEqual.
    // (object, object) => bool
    stateEqual: PropTypes.func,

    validateOnChange: PropTypes.bool,

    hideTopActionRow: PropTypes.bool,

    disableReset: PropTypes.bool,

    // If `true` will prevent submitting the form but still display the content
    disabled: PropTypes.bool,

    // If `true` and validate is also `true` will do a validation round on
    // initialization.
    validateOnLoad: PropTypes.bool,

    // If `true` the input value will be updated with dots (eg. 1,47 -> 1.47)
    replaceCommas: PropTypes.bool,

    // Disable editing if Category Bidding Import enabled for the Shop
    categoryBidImportEnabled: PropTypes.bool,
  };

  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = this._defaultState(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If the defaultData changes and things were the same, replace and reset
    // the form. Should hanndle cases where the submission function triggers
    // a re-render of the parent.
    if (!this.isDirty() && !deepEqual(nextProps.defaultData, this.state.data)) {
      this.setState(this._defaultState(nextProps),
      () => {
        const {validateOnLoad, validate} = this.state || {};
        if (validateOnLoad && validate) {
          validate();
        }
      });
    }
  }

  componentDidMount() {
    const { validateOnLoad, validate, defaultData } = this.props;
    // If there is no previous bid, the `defaultData` will be passed
    // as an empty object, we should skip it.
    if (validateOnLoad && validate && Object.keys(defaultData).length > 0) {
      this.validate();
    }
  }

  _defaultState(props) { // eslint-disable-line class-methods-use-this
    return {
      loading: false,
      data: {...props.defaultData},
      initialData: {...props.defaultData},
      errors: {},
      submitError: null,
      successMessage: null,
    };
  }

  // Update validation state and returns `true` / `false` depending on result.
  // This does not transform the data at present but it could be implemented
  // though I would advise against it to keep things simple.
  validate = () => {
    const errors = (this.props.validate && this.props.validate(this.state.data)) || {};
    this.setState({errors});
    return Object.keys(errors).length === 0;
  }

  // onChange handler to be used for input fields. Expects a `change` event from
  // a DOM Node and should handle all types of inputs. If you need better
  // control / non evented changes, use the `setValue` helper directly which
  // is also exposed to the render prop.
  onFieldChange = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.props.disabled) return;

    // This only work for simple inputs and selects, checkboxes and radios as
    // well as more complex types need to be implemented ([TODO]).
    // Maybe add some transformation for types of values, like ints and boolean.
    // See about interacting with the validator.
    const {name, value} = evt.currentTarget;
    this.setValue(name, value);
  };

  // Change handler for when you need to update outside of standard DOM events.
  setValue = (path, value) => {
    const {replaceCommas} = this.props;
    const newValue = replaceCommas ? value.replace(',', '.') : value;

    this.setState(
      { data: setNestedProperty(
          {...this.state.data},
          path,
          newValue,
          // Make sure the object is copied first if it already exists.
          (val, key, obj) => (val ? {...val} : val) // eslint-disable-line no-unused-vars
        ),
      },
      () => { if (this.props.validateOnChange) this.validate(); }
    );
  }

  // Simple helper to get nested values
  getValue = (path) => getNestedProperty(this.state.data, path);

  onSubmit = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.props.disabled || this.state.loading) return;

    if (this.validate()) {
      const data = this.state.data;
      this.setState({loading: true});
      this.props.onSubmit(data).then(
        () => this.setState({
          loading: false,
          submitError: null,
          initialData: data,
          successMessage: 'Data saved successfully.',
        }, () => setTimeout(() => this.setState({successMessage: null}), 2000)),
      ).catch(
        err => this.setState({
          loading: false,
          submitError: err,
          successMessage: null,
        }, () => setTimeout(() => this.setState({submitError: null}), 5000))
      );
    } else {
      this.setState({submitError: 'Please fix any error below.', successMessage: null});
    }
  };

  onReset = () => this.setState({
    data: this.state.initialData,
    errors: {},
    submitError: null,
    successMessage: null,
  });

  isDirty = () => {
    if (this.props.stateEqual) {
      return !this.props.stateEqual(this.state.initialData, this.state.data);
    } else {
      return !deepEqual(this.state.data, this.state.initialData);
    }
  };

  hasErrors = () => this.state.errors && Object.keys(this.state.errors).length > 0;

  // Build the props that will be passed to `children` as well as
  // `renderCustomActions`.
  renderProps = () => {
    const {state, onFieldChange, setValue, getValue} = this;
    return {
      ...state,
      onChange: onFieldChange,
      setValue,
      getValue,
      dirty: this.isDirty(),
      invalid: this.hasErrors(),
    };
  }

  renderActions() {
    if (this.props.categoryBidImportEnabled) {
      return null;
    }

    const {props, state} = this;
    const dirty = this.isDirty();
    const invalid = this.hasErrors();
    return (
      <div className="p2 mb1 rounded bg-gray-muted flex justify-between">
        <div>
          { (state.loading &&
             <Button ghost level="primary" disabled className="mr1">
               Loading <span className="loader--square"/>
             </Button>)
            ||
            (<Button className="mr1" level="primary" icon="save"
                    disabled={props.disabled || !dirty || state.loading || invalid}
                    onClick={this.onSubmit}>
              Save
            </Button>) }
          { props.disableReset ||
            <Button className="mr1" outline level="danger" icon="cancel"
                    disabled={props.disabled || state.loading || !dirty}
                    onClick={this.onReset}>
              Reset
            </Button> }
        </div>
        <div>
          { !this.props.renderCustomActions ||
            this.props.renderCustomActions(this.renderProps()) }
        </div>
      </div>
    );
  }

  render() {
    const {props, state, onSubmit} = this;
    const actions = this.renderActions();
    const alerts = [];

    if (state.successMessage) {
      alerts.push({
        message: state.successMessage,
        level: 'success',
      });
    }

    if (state.submitError) {
      alerts.push({
        message: state.submitError.message,
        level: 'error',
      });
    }

    return (
      <form className="form fill" onSubmit={onSubmit}>
        { props.legend == null || <legend>{props.legend}</legend> }
        { props.hideTopActionRow || actions }
        <AlertList alerts={alerts}/>
        <React.Fragment>
          { props.children(this.renderProps()) }
        </React.Fragment>
        { actions }
      </form>
    );
  }
}
