import React from 'react';
import PropTypes from 'prop-types';


/* eslint-disable import/default */
export function Alert(props) {

    const cssClasses = [`bdf_alert bdf_alert--${props.level || 'info'}`];
    props.show ? cssClasses.push('show') : cssClasses;

    return (
        <div className={cssClasses.join(' ')}>
            {props.message}
        </div>
    );
}

Alert.propTypes = {
    level: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
    message: PropTypes.string,
    show: PropTypes.bool,
};


export function AlertList(props) {
    return (
        <div>
            {props.alerts.slice().map((alert, index) =>
                <Alert key={alert.id || index} {...alert} />
            )}
        </div>
    );
}


AlertList.propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        ...Alert.propTypes,
    })),
};

export default { Alert, AlertList };
